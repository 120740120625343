import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { RoastSchedule } from 'src/app/models/RoastSchedule';
import { RoastScheduledItem } from 'src/app/models/RoastScheduledItem';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SchedulerService {

    constructor(
        private http: HttpClient,
    ) {}

    getSchedule(date: string, prevDays = 0, nextDays = 0): Observable<{ success: boolean, result: RoastSchedule[], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/' + (date ?? '');
        let params = new HttpParams();
        if (prevDays) {
            params = params.set('prevDays', prevDays);
        }
        if (nextDays) {
            params = params.set('nextDays', nextDays);
        }
        return this.http.get<{ success: boolean, result: RoastSchedule[], error: string }>(url, { params });
    }

    // would need the "today" param
    // saveSchedule(schedule: RoastSchedule): Observable<{ success: boolean, result: RoastSchedule, error: string }> {
    //     const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule';
    //     return this.http.post<{ success: boolean, result: RoastSchedule, error: string }>(url, schedule);
    // }

    /**
     * Adds a RoastScheduledItem to the Schedule (with the date found in the item
     * - or today if none found) at the given index (appends if not given).
     * @param {RoastScheduledItem} item the new item
     * @param {number} [index] the index to insert the item
     * @returns {Observable<{ success: boolean, result: string, error: string }>} with result being the new _id of the created item
     */
    addItem(item: RoastScheduledItem, index: number): Observable<{ success: boolean, result: string, error: string }> {
        const today = DateTime.now().toISODate();
        const date = item.date ?? today;
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/item/' + date + '/' + today;
        return this.http.post<{ success: boolean, result: string, error: string }>(url, { item, index });
    }

    /**
     * Adds several RoastScheduledItems to the Schedule (with the date found
     * in the first item - or today if none found).
     * One cannot add items to multiple dates with this method.
     * @param {RoastScheduledItem[]} items the new items
     * @returns {Observable<{ success: boolean, result: string[], error: string }>} with result being the new _ids of the created items
     */
    addItems(items: RoastScheduledItem[]): Observable<{ success: boolean, result: string[], error: string }> {
        const today = DateTime.now().toISODate();
        const date = items[0]?.date ?? today;
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/items/' + date + '/' + today;
        return this.http.post<{ success: boolean, result: string[], error: string }>(url, { items });
    }

    updateItem(schedule: RoastSchedule, item: RoastScheduledItem): Observable<void | { success: boolean, result: RoastScheduledItem, error: string }> {
        const today = DateTime.now().toISODate();
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/item/' + schedule.date + '/' + today;
        return this.http.put<void | { success: boolean, result: RoastScheduledItem, error: string }>(url, item);
    }

    /**
     * Deletes one item from a schedule
     * @param {string} date the date of the schedule from which to delete the item
     * @param {number} itemIdx the index of the item to delete
     * @returns {Observable<{ success: boolean, result: number, error: string }>} with result being the number of items left after the delete
     */
    deleteItem(date: string, itemIdx: number): Observable<{ success: boolean, result: number, error: string }> {
        const today = DateTime.now().toISODate();
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/item/' + date + '/' + itemIdx + '/' + today;
        return this.http.delete<{ success: boolean, result: number, error: string }>(url);
    }

    /**
     * Deletes all items from a schedule
     * @param {string} date the date of the schedule from which to delete the item
     * @returns {Observable<{ success: boolean, result: number, error: string }>} with result being the number of items left after the delete
     */
    deleteSchedule(date: string): Observable<{ success: boolean, result: number, error: string }> {
        const today = DateTime.now().toISODate();
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/items/' + date + '/' + today;
        return this.http.delete<{ success: boolean, result: number, error: string }>(url);
    }

    /**
     * Stores a list of favorite items.
     * @param {number} line the number of the bucket to save
     * @returns {Observable<{ success: boolean, result: number, error: string }>} with result being the number of items stored
     */
    saveFavorites(items: string, line: number): Observable<{ success: boolean, result: number, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/favorites/' + line;
        return this.http.post<{ success: boolean, result: number, error: string }>(url, { items });
    }

    /**
     * Stores a list of favorite items.
     * @param {number} line the number of the bucket to save
     * @returns {Observable<{ success: boolean, result: string, error: string }>} with result being the JSON string of the array of items loaded
     */
    loadFavorites(line: number): Observable<{ success: boolean, result: string, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/favorites/' + line;
        return this.http.get<{ success: boolean, result: string, error: string }>(url);
    }

    moveItem(to: number, toDate: string, from: number, fromDate?: string, clone: boolean = false): Observable<{ success: boolean, result: '', error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/itemorder';
        let params = new HttpParams();
        params = params.set('today', DateTime.now().toISODate());
        params = params.set('toDate', toDate);
        params = params.set('from', from);
        params = params.set('to', to);
        if (fromDate) {
            params = params.set('fromDate', fromDate);
        }
        if (clone) {
            params = params.set('clone', '1');
        }
        return this.http.put<{ success: boolean, result: '', error: string }>(url, undefined, { params });
    }
}
