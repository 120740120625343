import { Component, OnInit, Input, OnDestroy, Directive, Inject, LOCALE_ID } from '@angular/core';
import { UserType, UserService } from 'src/app/modules/frame/services/user.service';
import { ReportService } from 'src/app/modules/report/report.service';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { Purchase } from 'src/app/models/Purchase';
import { Roast } from 'src/app/models/Roast';
import { Enumerations } from 'src/app/models/Enumerations';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { NGXLogger } from 'ngx-logger';
import { Sale } from 'src/app/models/Sale';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { StockChange } from 'src/app/models/StockChange';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Coffee } from 'src/app/models/Coffee';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Account } from 'src/app/models/Account';
import { DateTime, MonthNumbers } from 'luxon';

// interface FromTo { from: DateTime, to: DateTime }

// show only Month + Year for specific date input field
export const ONLY_MONTH_FORMAT = {
    parse: {
        dateInput: 'MMM YYYY',
    },
    display: {
        dateInput: 'MMM YYYY',
        monthYearLabel: 'MMM YYYY',
    },
};

@Directive({
    selector: '[onlymonthformat]',
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: ONLY_MONTH_FORMAT },
    ],
})
export class OnlyMonthDateFormatDirective { }


@Component({
    selector: 'app-customsreports',
    templateUrl: './customsreports.component.html',
    styleUrls: ['./customsreports.component.scss'],
})
export class CustomsreportsComponent implements OnInit, OnDestroy {

    constructor(
        private reportService: ReportService,
        private utils: Utils,
        private logger: NGXLogger,
        private userService: UserService,
        private alertService: AlertService,
        private tr: TranslatorService,
        private router: Router,
        private route: ActivatedRoute,
        private dateAdapter: DateAdapter<Date>,
        @Inject(LOCALE_ID) public locale: string,
    ) { }

    readonly NRDIGITS = 3;
    FIELDSEPARATOR = '\t';
    LINESEPARATOR = '\r\n';
    DECIMALSEPARATOR = '.';

    @Input() currentUser: UserType;
    readOnly = false;

    private ngUnsubscribe = new Subject();
    private loadSubscription = new Subject();

    hauptzollaemter = Object.values(Enumerations.Hauptzollaemter);

    roasts: Roast[];
    sales: Sale[];
    purchases: Purchase[];
    lastPurchases: { coffee: Coffee, date: DateTime, amount: number, price: number }[];

    startDate: DateTime;
    endDate: DateTime;
    month: number; // 1-12
    month1807 = DateTime.now().month; // 1-12
    year1807 = DateTime.now().year;

    mainUnit: UnitSystemType = 'kg';
    loadingFormDataTimer: ReturnType<typeof setTimeout>;
    loadingReportsTimer: ReturnType<typeof setTimeout>;
    loadingCustomsDataTimer: ReturnType<typeof setTimeout>;
    loadingReports = 0;
    loadingFormData = { '1807': 0, '1816': 0, '1830': 0 };
    loadingCustomsData = 0;
    isDarkmode = false;
    months: string[];

    // whether discarded roasts are treated autmatically as "destroyed"
    // i.e. are shown as end_weight=0 and destroyed is set to roast date
    // if true, end_weights are shown and the user can manually set to "destroyed"
    manualDiscarded = false;
    adChanging = false;

    // whether the input area for the general company data is shown or not
    companyDataOpened = true;

    company: Account['company'] = { contact: {}, bank: {} };
    customs: Account['customs'] = { customs_office: {}, tax_warehouse: {}, report_type: {}, settings: {} };

    form1830: {
        this_date?: DateTime,
        last_date?: DateTime,
        last_inventory_type?: string,
        stellungnahme_1830?: string,
        allOutTaxed?: boolean,
        roest1?: number,
        roest2?: number,
        roest3?: number,
        roest4?: number,
        roest5?: number,
        roest6?: number,
        roest7?: number,
        roest8?: number,
        roest9?: number,
        roest10?: number,
    } = {
            this_date: DateTime.now(), last_date: DateTime.now().minus({ year: 1 }), allOutTaxed: true,
            roest1: 0, roest2: 0, roest3: 0, roest4: 0, roest5: 0, roest6: 0, roest7: 0, roest8: 0, roest9: 0, roest10: 0,
        };

    form1807: {
        this_date?: DateTime,
        last_date?: DateTime,
        '1807_mon_steueranmeldung'?: string,
        '1807_mon_steueranmeldung_jahr'?: string,
        '1807_nr3'?: number,
        menge1?: number,
        MENGE1?: number, // for 1807 Zoll-Portal
    } = {
            last_date: DateTime.now().minus({ month: 1 }).startOf('month'),
            this_date: DateTime.now().startOf('month'),
        };

    form1816: {
        startDate?: DateTime,
        endDate?: DateTime,
        '1816_k20'?: boolean,
        '1816_datum1'?: DateTime,
        reason?: number,
        '1816_freitext'?: string,
        menge1?: number,
        steuerb1?: number,
        menge2?: number,
        steuerb2?: number,
        summe?: number,
        promonat?: boolean,
        zulassungsdatum_hza_1816?: DateTime,
        steueranmeldung_monat_1816?: DateTime,
    } = {
            '1816_datum1': DateTime.now(),
            reason: 5,
        };

    menge1Changed = false;
    menge1Hint: string;
    roest3Changed = false;
    roest3Hint: string;
    roest9Changed = false;
    roest9Hint: string;

    creatingXML = false;


    ngOnInit(): void {
        this.dateAdapter.setLocale('de');
        this.months = this.utils.getAllMonthsLocalized('de');

        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm));

        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }

        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
        if (this.currentUser.export) {
            this.FIELDSEPARATOR = this.currentUser.export.sep;
            this.LINESEPARATOR = this.currentUser.export.linesep;
            this.DECIMALSEPARATOR = this.currentUser.export.decsep;
        }
        if (this.currentUser.account?.company) {
            this.company.name = this.currentUser.account.company.name;
            this.company.number = this.currentUser.account.company.number;
        }
        if (this.currentUser.account?.customs) {
            Object.assign(this.customs, this.currentUser.account.customs);
            if (typeof this.currentUser.account.customs.settings?.manualDiscarded !== 'undefined') {
                this.manualDiscarded = this.currentUser.account.customs.settings.manualDiscarded
            }
        }

        this.startDate = DateTime.now().minus({ month: 1 }).startOf('month');
        this.endDate = this.startDate.endOf('month');

        this.form1816.startDate = this.startDate;
        this.form1816.endDate = this.endDate;
        this.form1807['1807_mon_steueranmeldung'] = this.months[this.startDate.month - 1];
        this.form1807['1807_mon_steueranmeldung_jahr'] = this.startDate.year.toString();

        this.loadSubscription
            .pipe(throttleTime(1000), takeUntil(this.ngUnsubscribe))
            .subscribe(
                () => {
                    this.readOnly = this.userService.isReadOnly();
                    this.loadCustomsInfo(() => {
                        this.getLastFormData();
                        this.datesChanged();
                    });
                }
            );

        // this.loadSubscription.next({ from: this.form1830.last_date, to: this.form1830.this_date });
        this.loadSubscription.next('');
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        clearTimeout(this.loadingReportsTimer);
        clearTimeout(this.loadingFormDataTimer);
        clearTimeout(this.loadingCustomsDataTimer);
    }

    getLastFormData() {
        this.getLastReportInfo('1830');
        this.getLastReportInfo('1816');
        this.getLastReportInfo('1807');
    }

    manualDiscardedChanged(change: MatCheckboxChange) {
        if (this.currentUser.account?.customs?.settings?.manualDiscarded !== !change.checked) {
            this.adChanging = true;
            this.manualDiscarded = !change.checked;
            if (!this.currentUser.account.customs.settings) {
                if (!this.currentUser.account.customs) {
                    this.currentUser.account.customs = {};
                }
                this.currentUser.account.customs.settings = {};
            }
            this.currentUser.account.customs.settings.manualDiscarded = this.manualDiscarded;
            this.userService.storeCurrentUser(this.currentUser);
            const userData = {
                _id: this.currentUser.user_id,
                account: {
                    customs: { settings: { manualDiscarded: this.manualDiscarded } },
                    _id: undefined,
                    customer_code: undefined,
                    active: undefined,
                }
            };
            this.userService.updateUser(userData).pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response?.success) {
                            this.alertService.success(this.tr.anslate('Successfully updated'));
                            this.getRoastSummary({ from: this.form1830.last_date, to: this.form1830.this_date }, '1830');
                            this.getRoastSummary({ from: this.form1816.startDate, to: this.form1816.endDate }, '1816');
                        } else {
                            this.utils.handleError('error retrieving information', response.error);
                        }
                        this.adChanging = false;
                    },
                    error: error => {
                        this.utils.handleError('error retrieving information', error);
                        this.adChanging = false;
                    }
                });
        }
    }

    startSpinnerTimer(reportType: string, loadingReports = false, loadingCustomsData = false): void {
        if (reportType) {
            if (this.loadingFormData[reportType]) {
                this.loadingFormData[reportType] += 1;
            } else if (!this.loadingFormDataTimer) {
                this.loadingFormDataTimer = setTimeout(() => {
                    this.loadingFormData[reportType] = 1;
                }, 600);
            }
        }
        if (loadingReports) {
            if (this.loadingReports) {
                this.loadingReports += 1;
            } else if (!this.loadingReportsTimer) {
                this.loadingReportsTimer = setTimeout(() => {
                    this.loadingReports = 1;
                }, 600);
            }
        }
        if (loadingCustomsData) {
            if (this.loadingCustomsData) {
                this.loadingCustomsData += 1;
            } else if (!this.loadingCustomsDataTimer) {
                this.loadingCustomsDataTimer = setTimeout(() => {
                    this.loadingCustomsData = 1;
                }, 600);
            }
        }
    }

    stopSpinnerTimer(reportType: string, loadingReports = false, loadingCustomsData = false): void {
        if (reportType) {
            this.loadingFormData[reportType] -= 1;
            if (this.loadingFormData[reportType] <= 0) {
                this.loadingFormData[reportType] = 0;
                clearTimeout(this.loadingFormDataTimer);
            }
        }
        if (loadingReports) {
            this.loadingReports -= 1;
            if (this.loadingReports <= 0) {
                this.loadingReports = 0;
                clearTimeout(this.loadingReportsTimer);
            }
        }
        if (loadingCustomsData) {
            this.loadingCustomsData -= 1;
            if (this.loadingCustomsData <= 0) {
                this.loadingCustomsData = 0;
                clearTimeout(this.loadingCustomsDataTimer);
            }
        }
    }

    getRoastSummary(interval: { from: DateTime, to: DateTime }, reportType: '1830' | '1816' | '1807'): void {
        this.startSpinnerTimer(reportType);
        this.reportService.getRoastsSummary(interval.from, interval.to)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true && response.result) {
                        if (reportType === '1830') {
                            if (!this.roest3Changed) {
                                // all roasts, independent of discarded or not
                                this.form1830.roest3 = (response.result.endWeight.sum || 0) + (response.result.discarded.sum || 0);
                                const destCnt = response.result.discarded.destroyedCnt;
                                const totalCnt = response.result.endWeight.count + destCnt;
                                this.roest3Hint = `${totalCnt} Röstung${totalCnt !== 1 ? 'en' : ''}`;
                                if (this.form1830.allOutTaxed) {
                                    this.form1830.roest6 = (this.form1830.roest3 || 0) + (this.form1830.roest4 || 0) + (this.form1830.roest5 || 0);
                                }
                            } else {
                                this.roest3Hint = '... manuell eingegeben ...';
                            }
                            if (!this.roest9Changed) {
                                // only destroyed roasts
                                this.form1830.roest9 = (this.form1830.roest3 || 0) - (response.result.discarded.sum || 0);
                                const destCnt = response.result.discarded.destroyedCnt;
                                const totalCnt = response.result.endWeight.count;
                                this.roest9Hint =
                                    `${totalCnt} Röstung${totalCnt !== 1 ? 'en' : ''}${destCnt ? ` (ohne ${destCnt} Fehlröstung${destCnt > 1 ? 'en' : ''})` : ''}`;
                            } else {
                                this.roest9Hint = undefined;
                            }
                        } else if (reportType === '1816') {
                            if (!this.menge1Changed) {
                                // only destroyed roasts
                                this.form1816.menge1 = response.result.endWeight.sum || 0;
                                const totalCnt = response.result.endWeight.count;
                                const destCnt = response.result.discarded.destroyedCnt;
                                this.menge1Hint = `${totalCnt} Röstung${totalCnt !== 1 ? 'en' : ''}${destCnt ? ` (ohne ${destCnt} Fehlröstung${destCnt > 1 ? 'en' : ''})` : ''}`;
                            } else {
                                this.menge1Hint = '... manuell eingegeben ...';
                            }
                        } else {
                            // 1807
                            this.form1807.menge1 = response.result.endWeight.sum || 0;
                            const totalCnt = response.result.endWeight.count;
                            const destCnt = response.result.discarded.destroyedCnt;
                            this.menge1Hint = `${totalCnt} Röstung${totalCnt !== 1 ? 'en' : ''}${destCnt ? ` (ohne ${destCnt} Fehlröstung${destCnt > 1 ? 'en' : ''})` : ''}`;
                        }
                    } else {
                        this.utils.handleError('error retrieving information', response.error);
                    }
                    this.stopSpinnerTimer(reportType);
                },
                error: error => {
                    this.utils.handleError('error retrieving information', error);
                    this.stopSpinnerTimer(reportType);
                }
            });
    }

    getLastReportInfo(reportType: '1830' | '1816' | '1807'): void {
        this.startSpinnerTimer(reportType, true);
        this.reportService.getLastReportInfo(reportType)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        response.result = this.utils.dateifyObject(response.result, ['startDate', 'endDate']);
                        let interval: { from: DateTime, to: DateTime };
                        if (reportType === '1807') {
                            if (response.result?.endDate) {
                                this.form1807.this_date = response.result.endDate.plus({ day: 1 }).startOf('month');
                                this.form1807['1807_mon_steueranmeldung'] = this.months[this.form1807.this_date.month - 1];
                                this.form1807['1807_mon_steueranmeldung_jahr'] = this.form1807.this_date.year.toString();
                                this.month1807 = this.form1807.this_date.month as MonthNumbers;
                                this.year1807 = this.form1807.this_date.year;
                            }
                            interval = { from: this.form1807.this_date, to: this.form1807.this_date.endOf('month') };
                        } else {
                            interval = reportType === '1830'
                                ? { from: this.form1830.last_date, to: this.form1830.this_date || DateTime.now() }
                                : { from: this.form1816.startDate, to: this.form1816.endDate || DateTime.now() };
                            if (response.result) {
                                if (response.result.startDate) {
                                    // no previous 18xx form; use dates from latest roast report
                                    interval.from = response.result.startDate;
                                    interval.to = response.result.endDate;
                                } else {
                                    // have a previous 18xx form, use dates from there
                                    interval.from = response.result.endDate;
                                }
                                if (interval.from > interval.to) {
                                    interval.from = interval.to.startOf('month');
                                }
                                if (reportType === '1830') {
                                    this.form1830.last_date = interval.from;
                                    this.form1830.this_date = interval.to;
                                    this.form1830.roest1 = response.result.istbestand;
                                } else {
                                    this.form1816.startDate = interval.from;
                                    this.form1816.endDate = interval.to;
                                }
                            }
                        }
                        this.stopSpinnerTimer(reportType, true);
                        this.getRoastSummary(interval, reportType);
                    } else {
                        this.utils.handleError('error retrieving information', response.error);
                    }
                    this.stopSpinnerTimer(reportType, true);
                },
                error: error => {
                    this.utils.handleError('error retrieving information', error);
                    this.stopSpinnerTimer(reportType, true);
                }
            });
    }

    loadCustomsInfo(cb: () => void): void {
        this.startSpinnerTimer(undefined, true, true);
        this.userService.getCustomsInfo()
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true && response.result) {
                        const accountInfo = response.result;
                        if (!accountInfo.company) { accountInfo.company = {} }
                        if (!accountInfo.customs) { accountInfo.customs = {} }
                        if (accountInfo.customs.customs_office?.name && !this.hauptzollaemter.includes(accountInfo.customs.customs_office.name as Enumerations.Hauptzollaemter)) {
                            // try adding Hauptzollamt
                            if (this.hauptzollaemter.includes(`Hauptzollamt ${accountInfo.customs.customs_office.name.trim()}` as Enumerations.Hauptzollaemter)) {
                                accountInfo.customs.customs_office.name = `Hauptzollamt ${accountInfo.customs.customs_office.name.trim()}`;
                                Object.assign(this.customs, accountInfo.customs);
                                try {
                                    this.storeUserData();
                                } catch (err) {
                                    // ignore
                                }
                            }
                        }

                        Object.assign(this.company, this.utils.dateifyObject(accountInfo.company, ['foundation']));
                        Object.assign(this.customs, accountInfo.customs);
                        this.companyDataOpened = !(!!this.company.name && !!this.customs?.customs_office?.name && !!this.customs?.tax_warehouse?.number);
                        if (typeof cb === 'function') {
                            cb();
                        }
                    } else {
                        this.utils.handleError('error retrieving information', response.error);
                    }
                    this.stopSpinnerTimer(undefined, true, true);
                },
                error: error => {
                    this.utils.handleError('error retrieving information', error);
                    this.stopSpinnerTimer(undefined, true, true);
                }
            });
    }

    monthChanged(): void {
        if (this.month >= 1 && this.month <= 12) {
            this.startDate = DateTime.now().startOf('month').set({ month: this.month });
            if (this.startDate > DateTime.now()) {
                this.startDate.minus({ year: 1 });
            }
            this.endDate = this.startDate.endOf('month');
            this.datesChanged();
        }
    }

    datesChanged(): void {
        if (this.startDate.day !== 1
            || this.startDate.month !== this.endDate.month
            || this.startDate.year !== this.endDate.year
            || this.endDate.day !== this.endDate.daysInMonth) {
            this.month = undefined;
        } else {
            this.month = this.startDate.month;
        }
        if (this.endDate < this.startDate) {
            this.endDate = this.startDate.endOf('month');
        }
        this.endDate.endOf('day');
        this.getCustomsReport();
    }

    getCustomsReport(): void {
        if (!this.customs.report_type.country || this.customs.report_type.country === '-' || !this.customs.report_type.variant || this.customs.report_type.variant === '-') {
            // this.utils.handleError(undefined, this.tr.anslate('Reports Settings'));
            return;
        }
        this.startSpinnerTimer(undefined, true);
        this.reportService.getCustomsReport(this.customs.report_type.country, this.customs.report_type.variant, this.startDate.valueOf(), this.endDate.valueOf())
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response?.success === true) {
                        this.lastPurchases = this.utils.dateifyObjects(response.result.lastPurchases, ['date']);
                        this.sales = this.utils.dateifyStockChanges(response.result.sales);
                        this.createBeansYearLabel(this.sales);
                        this.purchases = this.utils.dateifyStockChanges(response.result.purchases);
                        this.createBeansYearLabel(this.purchases);
                        this.roasts = this.utils.dateifyRoasts(response.result.roasts);
                        this.createBeansYearLabel(this.roasts);
                        // if ((this.customs.report_type.country !== 'Germany' || (['Berlin', 'Dresden', 'Hamburg', 'Köln', 'Rosenheim'].indexOf(this.customs.report_type.variant) < 0))
                        //     && (this.customs.report_type.country !== 'Latvia' || this.customs.report_type.variant !== 'Generic')) {
                        //     this.logger.debug('customs report not implemented for ' + this.customs.report_type.country + ' / ' + this.customs.report_type.variant);
                        //     this.utils.handleError(undefined, 'server error: {{error}}#customs report not implemented for ' + this.customs.report_type.country + ' / ' + this.customs.report_type.variant);
                        // }
                    } else {
                        this.logger.debug('customs report could not be retrieved: ' + response.error);
                        this.utils.handleError(undefined, response.error);
                    }
                    this.stopSpinnerTimer(undefined, true);
                },
                error: error => {
                    this.logger.debug('customs report could not be retrieved: ' + error);
                    this.utils.handleError(undefined, error);
                    this.stopSpinnerTimer(undefined, true);
                }
            });
    }

    createBeansYearLabel(trans: StockChange[]): void {
        if (!trans) return;
        for (let r = 0; r < trans.length; r++) {
            const tr = trans[r];
            if (tr.coffee && !tr.coffee.yearLabel) {
                tr.coffee.yearLabel = this.utils.createBeansYearLabel(tr.coffee);
            }
            if (tr['blend']?.ingredients) {
                for (let i = 0; i < tr['blend'].ingredients.length; i++) {
                    const cof = tr['blend'].ingredients[i]?.coffee;
                    if (cof && !cof.yearLabel) {
                        cof.yearLabel = this.utils.createBeansYearLabel(cof);
                    }
                }
            }
        }
    }

    monthYearChanged(): void {
        this.form1807['1807_mon_steueranmeldung'] = this.months[(this.month1807 || 1) - 1];
        this.form1807['1807_mon_steueranmeldung_jahr'] = (this.year1807 || DateTime.now().year).toString();
        const date = DateTime.now().set({ year: this.year1807 || DateTime.now().year, month: this.month1807 || 1 }).startOf('month');
        this.form1807.last_date = date;
        this.form1807.this_date = date.endOf('month');
        this.getRoastSummary({ from: this.form1807.last_date, to: this.form1807.this_date }, '1807');
    }

    dateInput(attrName: string, newValue: DateTime, form: '1830' | '1816' | '1807'): void {
        if (!newValue) {
            this.alertService.error(this.tr.anslate('Invalid date'));
            return;
        }
        if (form === '1830') {
            if (attrName === 'this') {
                this.form1830.this_date.set({ day: newValue.day, month: newValue.month, year: newValue.year });
            } else {
                this.form1830.last_date.set({ day: newValue.day, month: newValue.month, year: newValue.year });
            }
            if (!this.roest3Changed || !this.roest9Changed) {
                this.getRoastSummary({ from: this.form1830.last_date, to: this.form1830.this_date }, form);
            }
        } else if (form === '1816') {
            if (!this.form1816[attrName]) {
                this.form1816[attrName] = newValue;
            } else {
                this.form1816[attrName].set({ day: newValue.day, month: newValue.month, year: newValue.year });
                if (attrName === 'startDate' || attrName === 'endDate') {
                    this.getRoastSummary({ from: this.form1816.startDate, to: this.form1816.endDate }, form);
                }
            }
        }
    }

    updateForms(): void {
        if (!this.roest3Changed || !this.roest9Changed) {
            this.getRoastSummary({ from: this.form1830.last_date, to: this.form1830.this_date }, '1830');
        }
        if (!this.menge1Changed) {
            this.getRoastSummary({ from: this.form1816.startDate, to: this.form1816.endDate }, '1816');
        }
    }

    allOutTaxedChanged(): void {
        if (this.form1830.allOutTaxed) {
            this.form1830.roest6 = (this.form1830.roest3 || 0) + (this.form1830.roest4 || 0) + (this.form1830.roest5 || 0);
        }
    }

    storeUserData(callback?: () => void): void {
        if (!this.currentUser) {
            this.utils.handleError('error updating user information', '');
            return;
        }
        if (this.readOnly) {
            if (typeof callback === 'function') {
                callback();
            }
            return;
        }
        // const user = Object.assign({}, { _id: this.currentUser.user_id, account: {} }) as User;
        // Object.assign(user.account, { company: this.company, customs: this.customs });
        const user = {
            _id: this.currentUser.user_id, account: {
                company: this.company, customs: this.customs,
            } as Account
        };

        this.userService.updateUser(user)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true && response.result) {
                        if (response.result.account) {
                            this.customs = response.result.account.customs;
                            this.company = this.utils.dateifyObject(response.result.account.company, ['foundation']);
                            if (!this.company) this.company = {};
                            if (!this.company.contact) this.company.contact = {};
                            if (!this.company.bank) this.company.bank = {};
                            if (!this.customs) this.company = {};
                            if (!this.customs.customs_office) this.customs.customs_office = {};
                            if (!this.customs.tax_warehouse) this.customs.tax_warehouse = {};
                        }
                        if (!this.currentUser.account?.company) {
                            if (!this.currentUser.account) this.currentUser.account = {};
                            this.currentUser.account.company = { name: this.company.name, number: this.company.number };
                        } else {
                            this.currentUser.account.company.name = this.company.name;
                            this.currentUser.account.company.number = this.company.number;
                        }
                        this.userService.storeCurrentUser(this.currentUser);
                        if (typeof callback === 'function') {
                            callback();
                        } else {
                            this.alertService.success(this.tr.anslate('Successfully updated'));
                        }
                    } else {
                        this.utils.handleError('error updating user information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating user information', error);
                }
            });
    }

    download1816(): void {
        this.storeUserData(() => {
            const data = {
                'zulassungsdatum_hza_1816': this.form1816.zulassungsdatum_hza_1816,
                'steueranmeldung_monat_1816': this.form1816.steueranmeldung_monat_1816,
                '1816_k30j': !!this.form1816.promonat,
                // 4. Der Bezug bzw. die Lieferung des Versandhändlers von Waren aus dem zollrechtlich freien Verkehr anderer Mitgliedstaaten wurde angezeigt
                '1816_k20': !!this.form1816['1816_k20'],
                '1816_k21': !this.form1816['1816_k20'], // k21 is "No" of k20
                // am
                '1816_datum1': this.form1816['1816_datum1'],
                // Geschäftszeichen
                '1816_freitext': this.form1816['1816_freitext'],

                // 5. Menge in Kilogramm, Steuerbetrag Euro, Cent
                // Röstkaffee
                menge1: this.form1816.menge1,
                steuerb1: (this.form1816.menge1 || 0) * this.reportService.getCoffeeTax('GER'),
                // löslicher Kaffee
                menge2: this.form1816.menge2,
                steuerb2: (this.form1816.menge2 || 0) * this.reportService.getCoffeeTax('GER', 'soluble'),
                // Gesamtbetrag der zu entrichtenden Kaffeesteuer
                summe: 0,

                // 6. Anlagen
                '1816_k22': this.form1816['1816_k22'],
                '1816_anlage1': this.form1816['1816_anlage1'],

                // 7. Ich versichere die Richtigkeit und Vollständigkeit meiner Angaben.
                '1816_ort': this.company.town,
                '1816_datum': DateTime.now(),

                startDate: this.form1816.startDate,
                endDate: this.form1816.endDate,
            };
            data.summe = (data.steuerb1 || 0) + (data.steuerb2 || 0);

            // 3. Ich melde die in Feld 5 angeführten Mengen als Steuerschuldner für Kaffee und / oder kaffeehaltigen Waren an: Aufgrund / Als
            for (let i = 1; i < 10; i++) {
                data[`1816_k${i}j`] = 'nein';
            }
            if (this.form1816.reason) {
                data[`1816_k${this.form1816.reason}j`] = 'ja';
            }

            this.creatingXML = true;
            this.reportService.createXML(data, '1816')
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: blob => {
                        this.utils.saveBlobToFileSystem(blob, `Form1816_${this.form1816['1816_datum1'].toFormat('yyyy-MM-dd')}.xml`);
                        this.creatingXML = false;
                        this.alertService.success(this.tr.anslate('Successfully added'));
                    },
                    error: error => {
                        this.creatingXML = false;
                        this.utils.handleError('error creating XML', error);
                    }
                });
        });
    }

    download1807(): void {
        this.storeUserData(() => {
            const data = { ... this.form1807 };
            delete data.this_date;
            delete data.last_date;

            this.creatingXML = true;
            this.reportService.createXML(data, '1807')
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: blob => {
                        this.utils.saveBlobToFileSystem(blob, `Form1807_${this.form1807.this_date.toFormat('yyyy-MM-dd')}.xml`);
                        this.creatingXML = false;
                        this.alertService.success(this.tr.anslate('Successfully added'));
                    },
                    error: error => {
                        this.creatingXML = false;
                        this.utils.handleError('error creating XML', error);
                    }
                });
        });
    }

    download1807ZP(): void {
        this.storeUserData(() => {
            const data = { ... this.form1807 };
            data.MENGE1 = data.menge1;
            delete data.this_date;
            delete data.last_date;

            this.creatingXML = true;
            this.reportService.createXML(data, '1807ZP')
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: blob => {
                        this.utils.saveBlobToFileSystem(blob, `Form1807_${this.form1807.this_date.toFormat('yyyy-MM-dd')}.xml`);
                        this.creatingXML = false;
                        this.alertService.success(this.tr.anslate('Successfully added'));
                    },
                    error: error => {
                        this.creatingXML = false;
                        this.utils.handleError('error creating XML', error);
                    }
                });
        });
    }

    download1830(): void {
        this.storeUserData(() => {
            const data = {
                // Istbestände bei der letzten Bestandsaufnahme
                roest1: this.form1830.roest1,

                // Bestände aus Zeile 1, die noch nicht als hergestellter Kaffee erfasst sind
                // Als Bestände, die noch nicht als hergestellter Kaffee gelten (vgl. Zeilen 2 
                // und 14 der nachfolgenden Seite), sind die noch nicht in den
                // steuerlichen Aufzeichnungen erfassten Kaffeemengen zu verstehen
                roest2: this.form1830.roest2, // ???

                // Zugänge seit der letzten Bestandsaufnahme aus der Herstellung (LBK Abt. I A Sp. 5 - 7)
                // i.e. end_weight of all roasts ('eigene Herstellung')
                roest3: this.form1830.roest3, // to be filled server side

                // Zugänge seit der letzten Bestandsaufnahme unversteuerte Aufnahme (LBK Abt. I A Sp. 5 - 7)
                roest4: this.form1830.roest4,
                // Zugänge seit der letzten Bestandsaufnahme versteuerte Aufnahme (LBK Abt. I B Sp. 5 - 7)
                roest5: this.form1830.roest5,

                // Abgänge seit der letzten Bestandsaufnahme versteuert (LBK Abt. II Sp. 4 - 6)
                roest6: this.form1830.roest6,
                // Abgänge seit der letzten Bestandsaufnahme unversteuert (LBK Abt. III A und III B Sp. 4 - 6)
                roest7: this.form1830.roest7,
                // Abgänge seit der letzten Bestandsaufnahme in die Produktion (LBK Abt. III C Sp. 4 - 6)
                roest8: this.form1830.roest8,

                // Istbestände der jetzigen Bestandsaufnahme
                roest9: this.form1830.roest9,
                // Bestände aus Zeile 13, die noch nicht als hergestellter Kaffee erfasst sind
                // Als Bestände, die noch nicht als hergestellter Kaffee gelten (vgl. Zeilen 2 
                // und 14 der nachfolgenden Seite), sind die noch nicht in den
                // steuerlichen Aufzeichnungen erfassten Kaffeemengen zu verstehen
                roest10: this.form1830.roest10, // ???
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any;

            // k1: Steuerlager zur Herstellung und Lagerung
            data.k1_1830 = this.customs.tax_warehouse.purpose === '1'; // this.tr.anslate('Production and storage');
            // k2: Steuerlager zur Lagerung
            data.k2_1830 = this.customs.tax_warehouse.purpose === '0'; // this.tr.anslate('Storage only');
            // k5: Letzte Bestandsaufnahme betrieblich
            data.k5_1830 = this.form1830.last_inventory_type === '1'; // this.tr.anslate('internally');
            // k6: Letzte Bestandsaufnahme amtlich
            data.k6_1830 = this.form1830.last_inventory_type === '0'; // this.tr.anslate('officially');

            // k_: ???
            data.k4_1830 = true;
            data.k3_1830 = false;
            data.k7_1830 = false;
            data.k8_1830 = true;
            data.f1830_k9 = false;
            data.k11_1830 = false;
            // k9: Das Hauptzollamt hat mir die Zulassung erteilt, für die Bestandsabrechnung betriebliche Aufzeichnungen zu nutzen.
            data.k9_1830 = false;
            data.k10_1830 = true;

            // Jetzige Bestandsaufnahme zum
            data.datum_1830 = this.form1830.this_date;
            // Letzte Bestandsaufnahme zum
            data.datum2_1830 = this.form1830.last_date;
            // k7: Stellungnahme siehe Anlage
            data.stellungnahme_1830 = this.form1830.stellungnahme_1830;

            // internal: whether all Zugänge (line 4) are versteuerte Abgänge (line 8)
            data.allOutTaxed = this.form1830.allOutTaxed;

            this.creatingXML = true;
            this.reportService.createXML(data, '1830')
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: blob => {
                        this.utils.saveBlobToFileSystem(blob, `Form1830_${this.form1830.this_date.toFormat('yyyy-MM-dd')}.xml`);
                        this.creatingXML = false;
                        this.alertService.success(this.tr.anslate('Successfully added'));
                    },
                    error: error => {
                        this.creatingXML = false;
                        this.utils.handleError('error creating XML', error);
                    }
                });
        });
    }

    setValue(field: string, $event: Event, form: '1830' | '1816' | '1807'): void {
        const theform = this[`form${form}`];
        if (!$event || typeof $event.target === 'undefined' || typeof $event.target['value'] === 'undefined') {
            theform[field] = 0;
            return;
        }
        theform[field] = parseFloat($event.target['value'].replace(',', '.'));
        if (isNaN(theform[field])) {
            theform[field] = 0;
        }
    }
}
