import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DateTime } from 'luxon';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslatorService } from 'src/app/util/services/translator.service';

@Component({
    selector: 'app-ap-datetime',
    templateUrl: './ap-datetime.component.html',
    styleUrls: ['./ap-datetime.component.scss']
})
export class ApDatetimeComponent {

    constructor(
        private alertService: AlertService,
        private tr: TranslatorService,
    ) { }

    _value: DateTime;
    hour: number;
    minute: number;

    @Input() disabled: boolean;
    @Input() dateRequired: boolean;
    @Input() set value(val: DateTime | string) {
        if (typeof val === 'string') {
            this._value = DateTime.fromISO(val);
        } else {
            this._value = val;
        }
        this.hour = this._value.hour;
        this.minute = this._value.minute;
    }
    get value(): DateTime {
        return this._value;
    }

    @Output() valueChange = new EventEmitter();

    dateInput(newValue: DateTime): void {
        if (!newValue) {
            this.alertService.error(this.tr.anslate('Invalid date'));
            return;
        }
        this._value.set({ day: newValue.day, month: newValue.month, year: newValue.year });
        this.valueChange.emit(this._value);
    }

    hourChanged(newVal: string): void {
        const intVal = Number.parseInt(newVal);
        if (Number.isFinite(intVal) && intVal >= 0 && intVal <= 23) {
            this.hour = intVal;
            this._value.set({ hour: this.hour });
            this.valueChange.emit(this._value);
        }
    }

    minuteChanged(newVal: string): void {
        const intVal = Number.parseInt(newVal);
        if (Number.isFinite(intVal) && intVal >= 0 && intVal <= 59) {
            this.minute = intVal;
            this._value.set({ minute: this.minute });
            this.valueChange.emit(this._value);
        }
    }
}
