import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/modules/ui/dialog/dialog.service';
import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy, ElementRef, Inject, LOCALE_ID, Output, EventEmitter } from '@angular/core';
import { Roast } from 'src/app/models/Roast';
import { Location } from 'src/app/models/Location';
import { StandardService } from 'src/app/util/services/standard.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { Enumerations } from 'src/app/models/Enumerations';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { ObjectChangedService } from 'src/app/util/services/objectchanged.service';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { YesNoDialogComponent } from 'src/app/modules/ui/dialog/yesno-dialog.component';
import { NGXLogger } from 'ngx-logger';
import { Coffee } from 'src/app/models/Coffee';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, throttleTime, takeUntil } from 'rxjs/operators';
import { concat } from 'rxjs';
import { Blend } from 'src/app/models/Blend';
import { BreakpointObserver } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';
import { CantDeleteDialogComponent } from 'src/app/modules/ui/dialog/cant-delete-dialog.component';
import pick from 'lodash-es/pick';
import { Certification } from 'src/app/models/Certification';
import { formatNumber } from '@angular/common';
import { GraphService } from 'src/app/modules/graph/graph.service';
import { Constants } from 'src/app/util/constants';
import cloneDeep from 'lodash-es/cloneDeep';
import { Utils2 } from 'src/app/util/utils2';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-roast',
    templateUrl: './roast.component.html',
    styleUrls: ['./roast.component.scss']
})
export class RoastComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(
        private standardService: StandardService,
        private userService: UserService,
        private sanitizer: DomSanitizer,
        private objectChangedService: ObjectChangedService,
        public tr: TranslatorService,
        private breakpointObserver: BreakpointObserver,
        public utils: Utils,
        private utils2: Utils2,
        private dialogService: DialogService,
        private logger: NGXLogger,
        private alertService: AlertService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private graphService: GraphService,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        graphService.energyUnitChanged$.subscribe(
            energyUnit => {
                this._energyUnit = energyUnit;
                if (this.roast) {
                    this.updateEnergy();
                }
            });
    }

    mainProperties = ['label', 'ref_id', 'coffee', 'location', 'report',
        'amount', 'reconciled', 'date', 'blend', 'end_weight', 'image',
        'notes', 'cupping_notes'];

    hiddenProperties = ['modified_at', 'roast_id', 'updated_at', 'updated_by', 'reportNote', 'refs', 'tooltip', '__v', '__t', 'tags', 'certInfo', 'GMT_offset', 'DRY_time', 'BTU_batch_str', 'CO2_batch_str', 'is_template'];

    fixedProperties = ['_id', 'hr_id', 'created_at', 'created_by', 'deleted', 'internal_hr_id', 'owner'];

    // tableProperties = all properties - mainProperties - fixedProperties - hiddenProperties

    readonlyProperties = ['charge_temp', 'drop_temp', 'drop_time', 'DEV_time', 'DEV_ratio', 'temperature', 'pressure', 'humidity', 'CM_ETD', 'CM_BTD', 'AUC', 'AUC_base', 'template', 'BTU_batch', 'CO2_batch'];

    // after an image changed, need to wait for the local server to reload; only for localhost setup
    waitForLocalServer = false;

    // isOrganic = false;

    private _roast: Roast;
    get roast(): Roast { return this._roast; }
    @Input() set roast(r: Roast) {
        this._roast = r;
        if (this._roast) {
            if (this._roast.coffee && !this._roast.coffee.yearLabel) {
                this._roast.coffee.yearLabel = this.utils.createBeansYearLabel(this._roast.coffee);
            }
            if (this._roast.blend && this._roast.blend.ingredients) {
                for (let i = 0; i < this._roast.blend.ingredients.length; i++) {
                    const ing = this._roast.blend.ingredients[i];
                    if (ing?.coffee && !ing.coffee.yearLabel) {
                        ing.coffee.yearLabel = this.utils.createBeansYearLabel(ing.coffee);
                    }
                }
            }
            this.updateCertifications();
            this._roast['BTU_batch_str'] = this.utils.convertEnergyStr(this._roast.BTU_batch, this.currentUser.energy_unit);
            this._roast['CO2_batch_str'] = this.convertAmount(this._roast.CO2_batch);
            if (typeof this._roast.date === 'string') {
                this._roast.date = DateTime.fromISO(this._roast.date);
            }
        }
    }

    @Input() index: number;
    @Input() idToHighlight: string;
    @Input() currentUser: UserType;
    filteredMachines: { label: string, cnt: number }[] = [];
    private machines: { label: string, cnt: number }[] = [];
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('machines') set _machines(ms: { label: string, cnt: number }[]) {
        this.machines = ms;
        this.filteredMachines = this.machines;
    }

    _energyUnit: string;
    get energyUnit(): string { return this._energyUnit; }
    @Input() set energyUnit(eu: string) {
        if (this._energyUnit !== eu) {
            this._energyUnit = eu;
            if (this.roast) {
                this.updateEnergy();
                this.graphService.energyUnitSource.next(this._energyUnit);
            }
        }
    }
    @Output() energyUnitChange = new EventEmitter<string>();

    @Input() readOnly = false;
    @Input() readOnlyAccount = false;
    @Input() editMode = -1;
    @Input() isNew = -1;
    isExpanded = false;
    @Input() editable = true;
    @Input() stores: Partial<Location>[];

    roastcopy: Roast;
    mainUnit: UnitSystemType = 'kg';
    currency = 'EUR';
    tempUnit = '°C';
    coffees: Coffee[];
    filteredCoffees: Coffee[];
    currentBlend: Roast['blend'];
    currentBlendCopy: Roast['blend'];
    couldBeBlendTemplate: Blend;
    newBlendName: string;
    blends: Blend[];
    filteredBlends: Blend[];
    commonCerts: Certification[] = [];

    roasting_notes: string;
    roastingNotesChanged: Subject<string> = new Subject<string>();
    cupping_notes: string;
    cuppingNotesChanged: Subject<string> = new Subject<string>();

    round = Math.round;
    floor = Math.floor;
    Number = Number;
    DateTime = DateTime;

    isLarge$: Observable<boolean>;
    isMiddleOrLarge$: Observable<boolean>;
    isLarge = false;

    submitPressed = false;
    waitingForChanges = false;
    isSavingTimer: ReturnType<typeof setTimeout>;
    isSaving = false;
    notsmaller = false;
    checkingIfDeletable = false;
    checkingIfDeletableTimer: ReturnType<typeof setTimeout>;

    origAmount: number;
    origEndWeight: number;

    // used to cancel all pending requests if user navigates away
    private ngUnsubscribe = new Subject();

    @ViewChild(MatExpansionPanel) expPanel: MatExpansionPanel;
    @ViewChild('appRoast') roastElem: ElementRef;

    ngOnInit(): void {
        this.locale = this.locale ?? 'en';

        // to get the latest settings (e.g. energyUnit after change in the graph pane), always load currentUser
        // if (!this.currentUser) {
        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        // }
        this.energyUnit = this.currentUser.energy_unit || 'BTU';
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
        if (this.currentUser.temperature_system === Enumerations.TEMPERATURE_SYSTEM.FAHRENHEIT) {
            this.tempUnit = '°F';
        }
        if (this.currentUser.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
        }

        if (this.roast) {
            this.roasting_notes = this.roast.notes;
            this.cupping_notes = this.roast.cupping_notes;
        }

        if (this.editable && this.editMode === this.index && typeof this.roastcopy === 'undefined' && this.roast) {
            // don't notify parent - command comes from parent anyway (and would reset isNew flag)
            this.edit(false);
            // those are called within edit():
            // this.getAllCoffees();
            // this.getAllBlends();
            // this.getAllStores();
        }

        if (this.expPanel && this.idToHighlight && (this.roast.roast_id === this.idToHighlight || this.roast._id === this.idToHighlight || this.roast.hr_id === this.idToHighlight)) {
            this.expPanel.open();
        }

        this.roastingNotesChanged.pipe(
            debounceTime(4000),
            distinctUntilChanged(),
            throttleTime(environment.RELOADTHROTTLE),
            takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.saveRoastingNotes());
        this.cuppingNotesChanged.pipe(
            debounceTime(4000),
            distinctUntilChanged(),
            throttleTime(environment.RELOADTHROTTLE),
            takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.saveCuppingNotes());

        this.isLarge$ = this.breakpointObserver.observe('(min-width: 900px)')
            .pipe(map(result => result.matches));
        this.isMiddleOrLarge$ = this.breakpointObserver.observe('(min-width: 600px)')
            .pipe(map(result => result.matches));

        this.isLarge$.subscribe(il => this.isLarge = il);
    }

    ngAfterViewInit(): void {
        if (this.idToHighlight && this.roast &&
            (this.roast.roast_id === this.idToHighlight || this.roast._id === this.idToHighlight || this.roast.hr_id === this.idToHighlight) &&
            !this.expPanel.expanded) {

            setTimeout(() => {
                this.expPanel.open();
                this.roastElem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            });
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    getAllCoffees(): void {
        this.standardService.getAll<Coffee>('coffees')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.coffees = this.utils.dateifyCoffees(response.result);
                        if (this.roast?.coffee?.hidden) {
                            // need to add this current coffee since hidden coffees are not retrieved by getAll
                            this.coffees.unshift(this.roast.coffee);
                        }
                        for (let c = 0; c < this.coffees.length; c++) {
                            const cof = this.coffees[c];
                            if (cof && !cof.yearLabel) {
                                cof.yearLabel = this.utils.createBeansYearLabel(cof);
                            }
                        }

                        this.filteredCoffees = this.coffees.slice();
                        // if (typeof this.roastcopy.coffee === 'undefined' && this.coffees && this.coffees.length > 0) {
                        //     this.roastcopy.coffee = this.coffees[0];
                        // }
                    } else {
                        this.utils.handleError('error retrieving all beans', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all beans', error);
                }
            });
    }

    getAllBlends(): void {
        this.standardService.getAll<Blend>('blends')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.blends = response.result;
                        // don't need to calc cof.yearLabel for all ingredients; done in roast-blend-edit
                        this.filteredBlends = this.blends;
                        if (this.editMode >= 0 && this.editMode === this.index) {
                            this.findMatchingBlend();
                        }
                    } else {
                        this.utils.handleError('error retrieving all beans', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all beans', error);
                }
            });
    }

    edit(notifyParent = true, doEdit = true): void {
        if (this.readOnly || !this.editable || !doEdit) {
            return;
        }

        this.getAllCoffees();

        this.roastcopy = Object.assign({}, this.roast);
        // no pre-selection #351
        // if (this.roastcopy && typeof this.roastcopy.location === 'undefined' && this.stores && this.stores.length > 0) {
        //     this.roastcopy.location = this.stores[0];
        // }
        // this.roastcopy.date = DateTime.fromJSDate(this.roastcopy.date) as unknown as Date;

        if (typeof this.roastcopy.tags === 'undefined') {
            this.roastcopy.tags = [];
        }
        // if (this.roast.coffee) {
        //     this.roastcopy.coffee = this.roast.coffee._id as unknown as Coffee;
        // }
        // if (this.roast.location) {
        //     this.roastcopy.location = this.roast.location._id as unknown as Location;
        // }
        if (this.roast.blend?.ingredients) {
            // need deep copy here
            this.currentBlend = cloneDeep(this.roast.blend);
            this.currentBlendCopy = this.getBlendCopy();
        }
        this.editMode = this.index;
        // here such that findMatchingBlend is surely called after the currentBlend deep copy above
        this.getAllBlends();
        if (notifyParent) {
            this.objectChangedService.objectChanged({ model: 'roasts', info: { editMode: this.index }, reload: false });
        }
        this.validateAmounts(this.roastcopy?.amount, this.roastcopy?.end_weight);
        this.origAmount = this.roastcopy?.amount;
        this.origEndWeight = this.roastcopy?.end_weight;
    }

    delete(): void {
        // if (this.readOnly) { return; }
        if (this.readOnlyAccount) { return; }

        this.checkingIfDeletableTimer = setTimeout(() => {
            this.checkingIfDeletable = true;
        }, 600);
        this.standardService.getRefs('roasts', this.roast._id)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.checkingIfDeletable = false;
                    clearTimeout(this.checkingIfDeletableTimer);
                    this.checkingIfDeletableTimer = undefined;
                    if (response.success === true) {
                        if (response.result.count > 0) {
                            const dialogRef = this.dialog.open(CantDeleteDialogComponent, {
                                closeOnNavigation: true,
                                data: {
                                    label: this.roast.hr_id + ' ' + this.roast.label,
                                    count: response.result.count,
                                    refs: this.utils.dateifyObjects(response.result.refs, ['date']),
                                    mainUnit: this.mainUnit,
                                    currency: this.currency
                                }
                            });

                            dialogRef.afterClosed().subscribe(result => {
                                if (result === true) {
                                    this.doDelete();
                                }
                            });

                        } else {
                            const dialogRef = this.dialog.open(YesNoDialogComponent, {
                                closeOnNavigation: true,
                                data: { text: this.tr.anslate('Do you really want to delete {{name}}?', { name: this.roast.hr_id + ' ' + this.roast.label }) }
                            });

                            dialogRef.afterClosed().subscribe(result => {
                                if (result === true) {
                                    this.doDelete();
                                }
                            });
                        }
                    } else {
                        this.utils.handleError('could not delete the data', response.error);
                    }
                },
                error: error => {
                    this.checkingIfDeletable = false;
                    clearTimeout(this.checkingIfDeletableTimer);
                    this.checkingIfDeletableTimer = undefined;
                    this.utils.handleError('could not delete the data', error);
                }
            });
    }

    private doDelete(): void {
        this.standardService.remove('roasts', this.roast._id)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.alertService.success(this.tr.anslate('Successfully removed'));
                        // notify parent of the change
                        this.roast.deleted = true;
                        this.objectChangedService.objectChanged({ model: 'roasts', info: { object: this.roast }, reload: false });
                        // // notify also about the relevant coffees - NO the component is not active anyway
                        // if (this.roast.coffee) {
                        //     this.objectChangedService.objectChanged({model: 'coffees', info: {object: this.roast.coffee}, reload: true})
                        // }
                        // if (this.roast.blend?.ingredients) {
                        //     for (let i = 0; i < this.roast.blend.ingredients.length; i++) {
                        //         if (this.roast.blend.ingredients[i].coffee) {
                        //             this.objectChangedService.objectChanged({model: 'coffees', info: {object: this.roast.blend.ingredients[i].coffee}, reload: true})
                        //         }
                        //     }
                        // }
                        this.isNew = -1;
                        this.editMode = -1;
                        this.currentBlend = undefined;
                        this.currentBlendCopy = undefined;
                    } else {
                        this.utils.handleError('error updating the roast information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the roast information', error);
                }
            });
    }

    cancel(): void {
        this.editMode = -1;
        this.roastcopy = undefined;
        this.currentBlend = undefined;
        this.currentBlendCopy = undefined;
        if (this.isNew === this.index) {
            this.isNew = -1;
            this.roast.deleted = true;
        }
        this.objectChangedService.objectChanged({ model: 'roasts', info: { object: this.roast }, reload: false });
        this.isNew = -1;
        this.notsmaller = false;
    }

    /**
     * Used to show a warning if yield equals or is larger than amount.
     * By design, at any call, only one of the parameters is a string (i.e.
     * what the user typed) and must not be converted to user units.
     * The other parameter is a number (i.e. stored value) and needs to
     * be converted in order to correctly compare the two values.
     * @param amount green beans weight
     * @param end_weight roasted beans weight
     */
    validateAmounts(amount: number | string, end_weight: number | string): void {
        let a: number;
        let e: number;
        if (typeof amount === 'string') {
            a = this.utils.parseLocaleFloat(amount);
            e = this.utils.convertToUserUnit(+end_weight, this.currentUser.unit_system);
        } else if (typeof end_weight === 'string') {
            a = this.utils.convertToUserUnit(+amount, this.currentUser.unit_system);
            e = this.utils.parseLocaleFloat(end_weight);
        } else {
            a = amount;
            e = end_weight;
        }
        this.notsmaller = a <= e;
    }

    // called from template; used to identify ENTER that should save the form
    save(): void {
        if (this.readOnly) { return; }

        this.submitPressed = true;
        if (!this.waitingForChanges) {
            this.doSave();
        }
    }

    // checks some preconditions and then saves the roast
    doSave(): void {
        // if (!this.roastcopy.coffee && !this.roastcopy.blend && !this.currentBlend) {
        //     this.dialogService.showDialog('Attention', 'Please select beans or a blend.')
        //     return;
        // }

        if (!this.roastcopy.location && (this.roastcopy.blend || this.roastcopy.coffee)) {
            this.dialogService.showDialog('Attention', 'Please select a source store first!')
            return;
        }

        if (!this.submitPressed) {
            return;
        }
        this.submitPressed = false;
        this.isSavingTimer = setTimeout(() => {
            this.isSaving = true;
        }, 600);

        this.notsmaller = false;

        // need a copy of the copy as we change that for sending to the server
        // and we would otherwise change the object that is currently displayed
        const myccopy = Object.assign({}, this.roastcopy);

        if (!myccopy.blend && this.currentBlend) {
            // only store label and ingredients in roast.blend
            myccopy.blend = pick(this.currentBlend, ['label', 'ingredients', '_id']);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let newBlendObs: Observable<{ success: boolean, result: any, error: string }>;
        if (myccopy.blend && myccopy.blend['internal_hr_id'] && myccopy.blend.ingredients) {
            // user selected a Blend template, need to clone it
            const ings = [];
            for (let i = 0; i < myccopy.blend.ingredients.length; i++) {
                let coffee = myccopy.blend.ingredients[i].coffee as unknown as Coffee;
                if (typeof coffee === 'object') {
                    // de-populate ingredient coffee
                    coffee = myccopy.blend.ingredients[i].coffee._id as unknown as Coffee;
                }
                ings.push({ coffee: coffee, ratio: myccopy.blend.ingredients[i].ratio, ratio_num: myccopy.blend.ingredients[i].ratio_num, ratio_denom: myccopy.blend.ingredients[i].ratio_denom });
            }
            myccopy.blend = { label: myccopy.blend.label, ingredients: ings, _id: undefined };

        } else if (myccopy.blend?.ingredients) {
            if (this.newBlendName) {
                // new blend template
                myccopy.blend.label = this.newBlendName;
            }

            if (!myccopy.blend.ingredients || myccopy.blend.ingredients.length === 0) {
                this.dialogService.showDialog('Attention', 'A blend needs at least one type of beans.');
                this.isSaving = false;
                clearTimeout(this.isSavingTimer);
                this.isSavingTimer = undefined;
                return;
            }
            if (!this.utils.percentCorrect(myccopy.blend)) {
                this.dialogService.showDialog('Attention', 'Ratios must be positive and add up to 100%.');
                this.isSaving = false;
                clearTimeout(this.isSavingTimer);
                this.isSavingTimer = undefined;
                return;
            }
            for (let i = 0; i < myccopy.blend.ingredients.length; i++) {
                if (myccopy.blend.ingredients[i].ratio === 0) {
                    this.dialogService.showDialog('Attention', '0% ratios are not allowed.');
                    this.isSaving = false;
                    clearTimeout(this.isSavingTimer);
                    this.isSavingTimer = undefined;
                    return;
                }
            }

            if (this.newBlendName) {
                // add blend with this newBlendName if it doesn't exist yet
                let exists = false;
                for (let b = 0; b < this.blends.length; b++) {
                    const blend = this.blends[b];
                    if (blend.label === this.newBlendName) {
                        exists = true;
                        break;
                    }
                }
                if (!exists) {
                    // need a copy as we change that for sending to the server
                    // and we would otherwise change the object that is currently displayed
                    const blendCopy = cloneDeep(myccopy.blend);
                    // de-populate
                    if (blendCopy.ingredients?.length > 0) {
                        blendCopy.ingredients.map(ing => {
                            ing.coffee = (ing?.coffee._id ? ing.coffee._id : ing.coffee) as unknown as Coffee;
                        });
                    }
                    newBlendObs = this.standardService.add('blends', blendCopy);
                }
            }
        }
        // remove undefined properties for better comparison with isEqual below
        if (myccopy.blend?.ingredients) {
            for (let i = 0; i < myccopy.blend.ingredients.length; i++) {
                const ing = myccopy.blend.ingredients[i];
                if (typeof ing.ratio_num === 'undefined') {
                    delete ing.ratio_num;
                }
                if (typeof ing.ratio_denom === 'undefined') {
                    delete ing.ratio_denom;
                }
            }
        }
        delete myccopy.refs;

        // de-populate coffee and location
        if (myccopy.coffee?._id) {
            myccopy.coffee = myccopy.coffee._id as unknown as Coffee;
        }
        if (myccopy.location?._id) {
            myccopy.location = myccopy.location._id as unknown as Location;
        }

        // prefer null instead of false for discarded
        if (myccopy.discarded === false) {
            myccopy.discarded = null;
        }

        // need to check this after the amounts have been calculated and before hr_id/_id treatment and modified_at
        // note that isEqual removes all equal properties from myccopy
        if (this.isNew !== this.index) {
            if (this.isEqual(this.roast, myccopy)) {
                this.alertService.success(this.tr.anslate('Nothing to change'));
                this.objectChangedService.objectChanged({ model: 'roasts', info: undefined, reload: false });
                this.editMode = -1;
                this.isSaving = false;
                clearTimeout(this.isSavingTimer);
                this.isSavingTimer = undefined;
                return;
            }
        } else {
            this.utils2.cleanResult(myccopy);
        }
        if (myccopy.hr_id && myccopy._id) {
            // make sure only one of _id and hr_id is present in the update object
            delete myccopy.hr_id;
        }
        myccopy.modified_at = DateTime.now();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let obs: Observable<{ success: boolean, result: Roast, error: string }>;
        if (this.isNew === this.index) {
            obs = this.standardService.add<Roast>('roasts', myccopy);
        } else {
            // if an existing item is updated, isEqual deleted _id and hr_id; re-add one of them (prefer _id)
            if (this.roast._id) {
                myccopy._id = this.roast._id;
            } else {
                myccopy.hr_id = this.roast.hr_id;
            }
            obs = this.standardService.update<Roast>('roasts', myccopy);
        }
        if (newBlendObs) {
            obs = concat(newBlendObs, obs);
        }
        // throttleTime does not work well with concatenated (mutlitple) observables?!
        // obs.pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
        obs.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response) {
                        // nothing changed
                        this.alertService.success(this.tr.anslate('Nothing to change'));
                        this.objectChangedService.objectChanged({ model: 'roasts', info: { editMode: -1 }, reload: false });

                    } else if (response.success === true) {
                        this.logger.debug('roast save received', response);

                        if (response.result) {
                            // ignore blend POST here
                            if (response.result.hr_id?.[0] === 'B') {
                                return;
                            }
                            this.alertService.success(this.tr.anslate('Successfully updated'));
                            this.roast = this.utils.dateifyRoasts([response.result])?.[0] || myccopy;

                            if (this.roast.coffee) {
                                // re-set coffee object
                                for (const coffee of this.coffees || []) {
                                    if ((this.roast.coffee._id || this.roast.coffee).toString() === coffee._id.toString()) {
                                        this.roast.coffee = coffee;
                                        break;
                                    }
                                }
                            }
                            if (this.roast.location) {
                                // re-set location object
                                for (let l = 0; l < this.stores.length; l++) {
                                    const location = this.stores[l];
                                    if ((this.roast.location._id || this.roast.location).toString() === location._id.toString()) {
                                        this.roast.location = location as Location;
                                        break;
                                    }
                                }
                            }

                            // notify parent of the change
                            this.objectChangedService.objectChanged({ model: 'roasts', info: { object: this.roast }, reload: false });
                            // // notify also about the relevant coffees - NO, component is not active anyway
                            // if (this.roast.coffee) {
                            //     this.objectChangedService.objectChanged({model: 'coffees', info: {object: this.roast.coffee}, reload: true})
                            // }
                            // if (this.roast.blend?.ingredients) {
                            //     for (let i = 0; i < this.roast.blend.ingredients.length; i++) {
                            //         if (this.roast.blend.ingredients[i].coffee) {
                            //             this.objectChangedService.objectChanged({model: 'coffees', info: {object: this.roast.blend.ingredients[i].coffee}, reload: true})
                            //         }
                            //     }
                            // }
                        }
                    } else {
                        this.utils.handleError('error updating the roast information', response.error);
                    }
                    this.currentBlend = undefined;
                    this.currentBlendCopy = undefined;
                    this.editMode = -1;
                    this.isNew = -1;
                    this.isSaving = false;
                    clearTimeout(this.isSavingTimer);
                    this.isSavingTimer = undefined;
                },
                error: error => {
                    this.utils.handleError('error updating the roast information', error);
                    this.isSaving = false;
                    clearTimeout(this.isSavingTimer);
                    this.isSavingTimer = undefined;
                }
            });
    }

    getBlendCopy(): Roast['blend'] {
        return Object.assign({}, this.currentBlend);
    }

    useTemplate(): void {
        this.currentBlend = this.couldBeBlendTemplate;
        this.currentBlendCopy = this.getBlendCopy();
        this.roastcopy.blend = this.couldBeBlendTemplate;
    }

    blendChanged(blend: Roast['blend']): void {
        // if we had a blend template but change anything, suggest blend template label as label for new roast blend
        let lastLabel: string;
        if (typeof blend.label === 'undefined' && this.currentBlend.label) {
            lastLabel = this.currentBlend.label;
        }
        this.currentBlend = blend;
        this.currentBlend['internal_hr_id'] = undefined;
        this.findMatchingBlend();
        if (lastLabel) {
            this.newBlendName = lastLabel;
        }
    }

    findMatchingBlend(): void {
        if (!this.blends) {
            return;
        }
        if (!this.currentBlend?.ingredients || this.currentBlend.ingredients.length === 0) {
            this.currentBlend = undefined;
            this.currentBlendCopy = undefined;
            return;
        }
        if (this.currentBlend.label) {
            // first check whether the label can be found in the templates
            for (let b = 0; b < this.blends.length; b++) {
                if (this.currentBlend.label === this.blends[b].label && this.isBlendEqualToTemplate(this.currentBlend, this.blends[b])) {
                    this.roastcopy.blend = this.blends[b];
                    this.currentBlend.label = this.blends[b].label;
                    this.currentBlendCopy = this.getBlendCopy();
                    return;
                }
            }
        }

        this.roastcopy.blend = undefined;

        // second, check whether the ratio can be found in the templates
        for (let b = 0; b < this.blends.length; b++) {
            const blendTemplate = this.blends[b];
            if (this.isBlendEqualToTemplate(this.currentBlend, blendTemplate)) {
                // this.roastcopy.blend = blendTemplate;
                // this.currentBlend.label = blendTemplate.label;
                // return;
                this.couldBeBlendTemplate = cloneDeep(blendTemplate);
                return;
            }
        }
        this.couldBeBlendTemplate = undefined;
        this.newBlendName = this.currentBlend.label;
    }

    isBlendEqualToTemplate(blendO: Roast['blend'], templateO: Blend): boolean {
        const blend = cloneDeep(blendO);
        const template = cloneDeep(templateO);
        if (blend.ingredients && template.ingredients) {
            if (blend.ingredients.length !== template.ingredients.length) {
                return false;
            }
            const blendIng = blend.ingredients.sort((a, b) => !a.coffee ? -1 : (!b.coffee ? 1 : a.coffee.internal_hr_id - b.coffee.internal_hr_id));
            const templateIng = template.ingredients.sort((a, b) => !a.coffee ? -1 : (!b.coffee ? 1 : a.coffee.internal_hr_id - b.coffee.internal_hr_id));
            for (let i = 0; i < blendIng.length; i++) {
                if (Math.abs(blendIng[i].ratio - templateIng[i].ratio) > Constants.EPSILON
                    || !blendIng[i].coffee || !this.utils.compareObjectsFn(blendIng[i].coffee, templateIng[i].coffee)) {
                    return false;
                }
            }
            return true;
        } else {
            return blend.ingredients === template.ingredients;
        }
    }

    blendSelected(): void {
        if (this.roastcopy.blend !== null) {
            this.roastcopy.coffee = null;
            this.currentBlend = cloneDeep(this.roastcopy.blend);
            this.currentBlendCopy = this.getBlendCopy();
        } else {
            this.currentBlend = undefined;
            this.currentBlendCopy = undefined;
        }
    }

    coffeeSelected(): void {
        if (this.roastcopy.coffee !== null) {
            this.roastcopy.blend = null;
            this.currentBlend = undefined;
            this.currentBlendCopy = undefined;
            this.couldBeBlendTemplate = undefined;
        }
    }

    /**
     * checks all main and tableProperties for equality
     * deletes all fixed and hidden properties (except _id, hr_id) from rUpdate as well as all others that are equal
     */
    isEqual(obj: Roast, updateObj: Roast): boolean {
        let equal = true;

        const props = Object.getOwnPropertyNames(updateObj);
        for (let p = 0; p < props.length; p++) {
            const prop = props[p];

            if (this.fixedProperties.indexOf(prop) >= 0 || this.hiddenProperties.indexOf(prop) >= 0) {
                // these can be ignored
                if (prop !== 'hr_id' && prop !== '_id') {
                    delete updateObj[prop];
                }
                continue;

            } else {
                // it's a table or mainProperty

                if ((obj[prop] == null || obj[prop]?.length === 0 || (Object.keys(obj[prop]).length === 0 && obj[prop].constructor === Object))
                    && (updateObj[prop] == null || updateObj[prop]?.length === 0 || (Object.keys(updateObj[prop]).length === 0 && updateObj[prop].constructor === Object))) {
                    // both are empty (undefined and null and [] and {} are considered equal here)
                    delete updateObj[prop];
                    continue;
                }

                if (obj[prop] == null || obj[prop]?.length === 0 || (Object.keys(obj[prop]).length === 0 && obj[prop].constructor === Object)
                    || updateObj[prop] == null || updateObj[prop]?.length === 0 || (Object.keys(updateObj[prop]).length === 0 && updateObj[prop].constructor === Object)) {
                    // only one is set
                    equal = false;
                    continue;
                }

                if (prop === 'date') {
                    // if (new Date(roast[prop]).getTime() !== new Date(rUpdate[prop]).getTime()) {
                    if (+obj[prop] === +updateObj[prop]) {
                        delete updateObj.date;
                    } else {
                        // don't "return false" here since we want to delete all other equal properties
                        equal = false;
                    }
                    continue;
                }

                if (prop === 'blend') {
                    // compare keeping in mind that coffee object might be populated
                    const b1 = obj.blend;
                    const b2 = updateObj.blend;
                    if (b1.label !== b2.label
                        || b1.ingredients && !b2.ingredients || b2.ingredients && !b1.ingredients
                        || b1.ingredients && b2.ingredients && b1.ingredients.length !== b2.ingredients.length) {

                        equal = false;
                        continue;
                    }
                    for (let i = 0; i < b1.ingredients.length; i++) {
                        const ing1 = b1.ingredients[i];
                        const ing2 = b2.ingredients[i];
                        if (ing1 && !ing2 || ing2 && !ing1 || ing1.ratio !== ing2.ratio
                            || (ing1.coffee?._id || ing1.coffee)?.toString() !== (ing2.coffee?._id || ing2.coffee)?.toString()) {

                            equal = false;
                            break;
                        }
                    }
                    if (!equal) {
                        continue;
                    }
                    delete updateObj.blend;
                    continue;
                }

                if (this.utils.compareObjectsFn(obj[prop], updateObj[prop]) || JSON.stringify(obj[prop]) === JSON.stringify(updateObj[prop])) {
                    delete updateObj[prop];
                } else {
                    equal = false;
                }
            }
        }
        return equal;
    }

    /**
     * decides whether to show the table of detailed properties or not
     */
    hasAProperty(): boolean {
        const props = Object.getOwnPropertyNames(this.roast);
        for (const prop of props) {
            if (this.mainProperties.indexOf(prop) < 0 && this.hiddenProperties.indexOf(prop) < 0 && this.fixedProperties.indexOf(prop) < 0) {
                // one of tableProperties
                if (this.utils.isaProperty(this.roast[prop])) {
                    // this property has a value to be shown
                    // this.logger.debug(`roast table property: ${prop}`);
                    return true;
                }
            }
        }
        // no tableProperties to show
        return false;
    }

    /**
     * decides whether to show the table of readonly properties or not
     */
    hasAReadonlyProperty(): boolean {
        for (const prop of this.readonlyProperties) {
            if (this.utils.isaProperty(this.roast[prop])) {
                // this property has a value to be shown
                return true;
            }
        }
        // no readonly properties to show
        return false;
    }

    getSortedIngredients(): { coffee: Coffee, ratio: number }[] {
        return this.roast.blend.ingredients.sort((i1, i2) => i2.ratio - i1.ratio);
    }

    saveRoastingNotes(): void {
        if (this.readOnly || this.roast.notes === this.roasting_notes) {
            this.logger.trace('ignore update (roasting_notes):', this.roasting_notes);
            return;
        }

        this.logger.debug('save notes:', this.roasting_notes);
        this.standardService.update<Roast>('roasts', { _id: this.roast._id, notes: this.roasting_notes, modified_at: DateTime.now(), amount: undefined, date: undefined })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        this.roast.notes = this.roasting_notes;
                    } else {
                        this.utils.handleError('error updating the roast information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the roast information', error);
                }
            });
    }

    saveCuppingNotes(): void {
        if (this.readOnly || this.roast.cupping_notes === this.cupping_notes) {
            this.logger.trace('ignore update (cupping_notes):', this.cupping_notes);
            return;
        }

        this.logger.debug('save cupping_notes:', this.cupping_notes);
        this.standardService.update<Roast>('roasts', { _id: this.roast._id, cupping_notes: this.cupping_notes, modified_at: DateTime.now(), amount: undefined, date: undefined })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        this.roast.cupping_notes = this.cupping_notes;
                    } else {
                        this.utils.handleError('error updating the roast information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the roast information', error);
                }
            });
    }

    isTemplateChanged() {
        if (this.readOnly) {
            this.logger.trace('ignore update: change to is_template');
            return;
        }

        this.logger.debug('save is_template:', this.roast.is_template);
        this.standardService.update<Roast>('roasts', { _id: this.roast._id, is_template: this.roast.is_template, modified_at: DateTime.now(), amount: undefined, date: undefined })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                    } else {
                        this.utils.handleError('error updating the roast information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the roast information', error);
                }
            });
    }

    panel(opened: boolean): void {
        this.isExpanded = opened;
    }

    updateCertifications(): void {
        // this.isOrganic = false;

        if (this.roast?.coffee) {
            this.commonCerts = this.roast.coffee.certifications;
        } else if (this.roast?.blend) {
            this.commonCerts = this.utils.calcCommonCerts(this.roast.blend);
        }
        // // this.isOrganic = this.utils.isOrganicBlend(this.roast.blend);
        // for (let c = 0; c < this.commonCerts?.length; c++) {
        //     const cert = this.commonCerts[c];
        //     if (cert.organic) {
        //         this.isOrganic = true;
        //         break;
        //     }
        // }
    }

    addImage(): void {
        if (this.readOnly || !this.roast) {
            return;
        }
        this.utils.addImage(this.roast, 'roasts', 'image', this.ngUnsubscribe,
            () => { this.isSaving = true; }, // dialog closed
            imagePath => { // saving done (or no changes)
                if (typeof imagePath !== 'undefined') {
                    if (imagePath && environment.BASE_API_URL.indexOf('localhost') >= 0) {
                        if (this.roastcopy) {
                            this.roastcopy.image = null;
                            this.waitForLocalServer = true;
                            setTimeout(() => {
                                this.waitForLocalServer = false;
                                this.roastcopy.image = imagePath;
                                // this.logger.debug('image reloaded for roastcopy');
                            }, 7250);
                        }
                        this.roast.image = null;
                        this.waitForLocalServer = true;
                        setTimeout(() => {
                            this.waitForLocalServer = false;
                            this.roast.image = imagePath;
                            // this.logger.debug('image reloaded for coffee');
                        }, 6750);
                    } else {
                        if (this.roastcopy) {
                            this.roastcopy.image = imagePath;
                        }
                        this.roast.image = imagePath;
                    }
                }
                this.isSaving = false
            },
        );
    }

    filesChanged(newFiles: string[]): void {
        if (this.readOnly) { return; }

        this.roast.files = newFiles;
        this.standardService.update<Roast>('roasts', { _id: this.roast._id, files: this.roast.files, modified_at: DateTime.now(), amount: undefined, date: undefined })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                        this.logger.debug('update successful');
                    } else {
                        this.utils.handleError('Could not update documents', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('Could not update documents', error);
                }
            });
    }

    // getRoastTime(): string | undefined {
    //     const mdate = DateTime.fromJSDate(this.roast.date);
    //     // if (mdate.diffNow('days').days > 5) {
    //         return mdate.toFormat('LT');
    //     // }
    //     return undefined;
    // }

    // changedRoastedData(prop: string) {
    //     switch (prop) {
    //         case 'end_weight':
    //             if ((this.roastcopy.end_weight || this.roastcopy.end_weight === 0) && this.roastcopy.density_roasted) {
    //                 this.roastcopy.volume_out = this.roastcopy.end_weight / this.roastcopy.density_roasted;
    //             }
    //             break;
    //         case 'density_roasted':
    //             if ((this.roastcopy.end_weight || this.roastcopy.end_weight === 0) && this.roastcopy.volume_out) {
    //                 this.roastcopy.density_roasted = this.roastcopy.end_weight / this.roastcopy.volume_out;
    //             }
    //             break;
    //         case 'volume_out':
    //             if ((this.roastcopy.end_weight || this.roastcopy.end_weight === 0) && (this.roastcopy.volume_out || this.roastcopy.volume_out === 0)) {
    //                 this.roastcopy.end_weight = this.roastcopy.density_roasted * this.roastcopy.volume_out;
    //             }
    //             break;

    //         default:
    //             break;
    //     }
    // }

    checkChangesUnits(parent: unknown, variable: string, oldValue: number, newValueStr: string, digits = 3): void {
        // amount must not be null ("required" server side)
        if (newValueStr === '' && variable !== 'amount') {
            parent[variable] = null;
            if (this.submitPressed === true) {
                this.doSave();
            }
            this.submitPressed = false;
            return;
        }
        parent[variable] = undefined;
        this.waitingForChanges = true;
        setTimeout(() => {
            const { val, changed } = this.utils.checkChangedValue(oldValue * this.utils.getUnitFactor(this.mainUnit), newValueStr, digits);
            parent[variable] = val / this.utils.getUnitFactor(this.mainUnit);
            this.waitingForChanges = false;
            if (changed) {
                // for the Bleinput component to reset to the correct value
                if (variable === 'end_weight') {
                    this.origEndWeight = parent[variable];
                }
                if (variable === 'amount') {
                    this.origAmount = parent[variable];
                }
                if (this.submitPressed === true && parent[variable] !== oldValue) {
                    this.doSave();
                }
            } else {
                this.validateAmounts(this.roastcopy?.amount, this.roastcopy?.end_weight);
            }
            this.submitPressed = false;
        });
    }

    checkChanges(variable: string, oldValue: number, newValueStr: string, digits = 3, clamp?: ((n: number) => number)): void {
        this.roastcopy[variable] = undefined;
        this.waitingForChanges = true;
        setTimeout(() => {
            const { val, changed } = this.utils.checkChangedValue(oldValue, newValueStr, digits, false, true, clamp);
            this.roastcopy[variable] = val;
            this.waitingForChanges = false;
            if (changed && this.submitPressed === true && this.roastcopy[variable] !== oldValue) {
                this.doSave();
            }
            this.submitPressed = false;
        });
    }

    sanitize(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    getAmountStr(coffee: Coffee): { pre: string, value: number, post: string } {
        if (!coffee) {
            return { pre: '', value: 0, post: '' };
        }
        if (!coffee.stock) {
            for (let c = 0; c < (this.coffees || []).length; c++) {
                const cof = this.coffees[c];
                if (this.utils.compareObjectsFn(cof, coffee)) {
                    coffee = cof;
                }
            }
        }
        if (!coffee) {
            return { pre: '', value: 0, post: '' };
        }
        for (let s = 0; s < coffee.stock?.length; s++) {
            if (this.utils.compareObjectsFn(coffee.stock[s].location, this.roastcopy.location)) {
                return this.utils.formatAmountForPipe(coffee.stock[s].amount, undefined, this.currentUser.unit_system);
            }
        }
        // could not find beans in current store => amount is 0
        return { pre: '', value: 0, post: '' };
    }

    /**
     * Formats an amount (kg) value. Does not convert into lbs!
     * @param val amount in kg
     * @returns string with unit (g/kg/t) appended
     */
    convertAmount(val: number): string {
        if (!val) {
            return undefined;
        }
        let unit = '';
        if (Math.abs(val) < 0.001) {
            return '0g';
        }
        unit = 'kg';
        if (Math.abs(val) >= 1000) {
            val = val / 1000;
            unit = 't';
        } else if (Math.abs(val) < 1) {
            val *= 1000;
            unit = 'g';
        }
        if (val >= 100) {
            return formatNumber(val, this.locale, '1.0-0') + unit;
        }
        return formatNumber(val, this.locale, '1.0-1') + unit;
    }

    updateEnergy(): void {
        this.roast['BTU_batch_str'] = this.utils.convertEnergyStr(this.roast.BTU_batch, this.energyUnit);
        this.roast['CO2_batch_str'] = this.convertAmount(this.roast.CO2_batch);
    }

    saveEnergyUnit(value: string): void {
        this.currentUser.energy_unit = value;
        this.energyUnitChange.emit(value);
        this.updateEnergy();

        if (!this.readOnly) {
            this.userService.updateUser({ _id: this.currentUser.user_id, energy_unit: value })
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true && response.result) {
                            this.userService.storeCurrentUser(this.currentUser);
                        } else {
                            this.utils.handleError('error updating user information', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('error updating user information', error);
                    }
                });
        }
    }

    changeMachineFilter(machine: string): void {
        if (!machine) {
            this.filteredMachines = this.machines.slice();
            return;
        }
        if (this.machines) {
            const upId = machine.toUpperCase();
            this.filteredMachines = this.machines.filter(m => m && m.label.toUpperCase().indexOf(upId) >= 0);
        }
    }
}
