import { User } from './User';
import { Account } from './Account';
import { RoastScheduledItem } from './RoastScheduledItem';

export class RoastSchedule {
    _id: string;
    created_at?: Date;
    updated_at?: Date;
    created_by?: User;
    updated_by?: User;
    owner?: Account;
    date?: string;
    items?: RoastScheduledItem[];
    // only in web client:
    summary: string;
    summaryMin: string;
    timeStr: string;
    weekday: string;
    weekdayShort: string;
}