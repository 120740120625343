/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Input, ViewChild } from '@angular/core';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Router } from '@angular/router';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, ChartEvent, TooltipItem } from 'chart.js';
import { NgStyle } from '@angular/common';


@Component({
    standalone: true,
    imports: [BaseChartDirective, NgStyle],
    selector: 'app-density-graph',
    templateUrl: './density-graph.component.html',
})
export class DensityGraphComponent {

    constructor(
        private tr: TranslatorService,
        private router: Router
    ) { }

    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

    @Input() set isSmall(is: boolean) {
        if (this.chart) {
            this.chart.chart.options.scales.y.display = !is;
            // this.chart.chart.update();
        }
    }
    @Input() isDarkmode = false;

    canvasHeight = '350px';

    haveData = false;

    data = {
        labels: [],
        cropYears: [],
        datasets: []
    };
    options: ChartConfiguration<'bar'>['options'] = {
        indexAxis: 'y' as const,
        plugins: {
            //        animation: {
            //          duration: 0, // general animation time
            //        },
            // hover: {
            //     animationDuration: 0, // duration of animations when hovering an item
            // },
            title: {
                display: true,
                text: this.tr.anslate('Density'),
                position: 'top' as const,
            },
            datalabels: {
                display: false,
            },
            // rough: false,,
            //            rough: {
            //                roughness: 1,      // 1,
            //                bowing: 0.1,       // 1,
            ////                fillStyle: 'hachure', //'solid',
            //                fillWeight: 0.4,   // 0.5,
            //                hachureAngle: 21,  // -41,
            //                hachureGap: 3,     // 4,
            ////                curveStepCount: 1, // 9,
            //                simplification: 0
            //            }
            legend: {
                display: false,
            },
            tooltip: {
                mode: 'index' as const,
                displayColors: false,
                backgroundColor: 'rgba(66,66,66,0.8)', // '#424242',
                titleColor: '#fff',
                bodyColor: 'rgba(0, 0, 0, 0)',

                callbacks: {
                    title: (tooltipItems: TooltipItem<'bar'>[]) => {
                        const index = tooltipItems[0].dataIndex;
                        let header = this.data.labels[index];
                        if (this.data.cropYears && this.data.cropYears[index]) {
                            header = header + ', ' + this.data.cropYears[index];
                        }
                        return header;
                    },
                    label: (tooltipItem: TooltipItem<'bar'>) => {
                        const datasetIndex = tooltipItem.datasetIndex;
                        const index = tooltipItem.dataIndex;
                        const value = Math.round(this.data.datasets[datasetIndex].data[index]);
                        return `${value}g/l`;
                    }
                }
            },
        },
        scales: {
            x: {
                grid: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)',
                    // zeroLineColor: 'rgba(0, 0, 0, 0.25)',
                },
                border: {
                    display: true,
                },
                suggestedMin: 650,
                suggestedMax: 750,
                ticks: {
                    display: true,
                },
                title: {
                    display: true,
                    text: 'g/l'
                },
            },
            y: {
                grid: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)',
                    // zeroLineColor: 'rgba(0, 0, 0, 0.25)',
                },
                border: {
                    display: true,
                },
                ticks: {
                    display: true,
                },
            },
        },
        layout: {
            padding: {
                left: 5,
                right: 20,
                top: 20,
                bottom: 20
            }
        },
        aspectRatio: 0.9,
        maintainAspectRatio: false,
    };

    @Input() set newData(nd: any) {
        this.setNewData(nd);
    }


    adaptGraphSize() {
        try {
            if (this.chart) {
                this.canvasHeight = (this.data.labels.length * 15 + 250) + 'px';
                this.chart.render();
            } else {
                setTimeout(() => {
                    this.canvasHeight = (this.data.labels.length * 15 + 250) + 'px';
                    this.chart?.render();
                });
            }
        } catch (err) {
        }
    }

    chartClicked(event: { event?: ChartEvent, active?: { datasetIndex?: number, index?: number }[] }) {
        if (event.active?.length > 0) {
            const elem = event.active[0];
            const internal_hr_id = this.data.datasets[elem.datasetIndex]?.['coffees']?.[elem.index];
            if (internal_hr_id) {
                this.router.navigate(['/coffees', { id: 'C' + internal_hr_id }]);
            }
        }
    }

    setNewData(nd: any) {
        this.haveData = false;
        if (nd?.coffees?.length > 1 &&
            nd.labels?.length === nd.data.length &&
            nd.data?.length === nd.coffees.length) {
            this.haveData = true;

            const borderColors = [];
            const backgroundColors = [];
            const hoverBackgroundColors = [];
            for (let i = 0; i < nd.data.length; i++) {
                if (i === 0) {
                    borderColors.push('#db5785');            // secondary-300
                    backgroundColors.push('#db5785A0');      // secondary-300
                    hoverBackgroundColors.push('#db5785DD'); // secondary-300
                } else if (i === (nd.data.length - 1)) {
                    borderColors.push('#bdbdbd');            // grey-400
                    backgroundColors.push('#bdbdbdA0');      // grey-400
                    hoverBackgroundColors.push('#bdbdbdFF'); // grey-400
                } else {
                    borderColors.push('#43a7cf');            // primary-400
                    backgroundColors.push('#43a7cfA0');      // primary-400
                    hoverBackgroundColors.push('#43a7cfEE'); // primary-400
                }
            }

            this.data.labels = nd.labels;
            if (nd.additionalData) {
                this.data.cropYears = nd.additionalData[0].labels;
            }
            this.data.datasets = [{
                categoryPercentage: 1,
                barPercentage: 0.6,
                data: nd.data,
                coffees: nd.coffees,
                backgroundColor: backgroundColors,
                hoverBackgroundColor: hoverBackgroundColors,
                borderColor: borderColors,
                borderWidth: 0
            }];
        }

        if (this.isDarkmode) {
            this.options.scales.x.grid.color = 'rgba(255, 255, 255, 0.2)';
            // this.options.scales.x.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
            this.options.scales.y.grid.color = 'rgba(255, 255, 255, 0.2)';
            // this.options.scales.y.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
            this.options.plugins.tooltip.titleColor = '#212121DD'; // G900/BB
            this.options.plugins.tooltip.bodyColor = '#212121DD'; // G900/BB
            this.options.plugins.tooltip.backgroundColor = '#eeeeeeEE'; // G200/DD => '#eeeeee'
        } else {
            this.options.scales.x.grid.color = 'rgba(0, 0, 0, 0.1)';
            // this.options.scales.x.grid.zeroLineColor = 'rgba(0, 0, 0, 0.25)';
            this.options.scales.y.grid.color = 'rgba(0, 0, 0, 0.1)';
            // this.options.scales.y.grid.zeroLineColor = 'rgba(0, 0, 0, 0.25)';
            this.options.plugins.tooltip.titleColor = '#fff';
            this.options.plugins.tooltip.bodyColor = '#fff';
            this.options.plugins.tooltip.backgroundColor = 'rgba(66,66,66,0.8)'; // G800, '#424242'
        }

        this.adaptGraphSize();
    }
}
