/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { DecimalPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import cloneDeep from 'lodash-es/cloneDeep';
import { BaseChartDirective } from 'ng2-charts';

import { DateTime } from 'luxon';
import 'chartjs-adapter-luxon';
import 'chartjs-plugin-stacked100';
import { Chart, LineController, LineElement, LinearScale, PointElement, Title, Legend, Tooltip, TooltipItem, ChartConfiguration } from 'chart.js';
Chart.register(LineController, LineElement, PointElement, LinearScale, Title, Legend, Tooltip);

@Component({
    standalone: true,
    imports: [BaseChartDirective],
    selector: 'app-roastamountaccumulated-graph',
    templateUrl: './roastamountaccumulated-graph.component.html',
})

export class RoastamountaccumulatedGraphComponent implements OnInit {

    constructor(
        private tr: TranslatorService,
        private userService: UserService,
        private breakpointObserver: BreakpointObserver,
        private decimalPipe: DecimalPipe,
        private utils: Utils,
        private router: Router,
    ) {
    }

    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

    // needed to have the correct order of ngOnInit and newData setter
    inited = false;
    updData: any;

    // the current year (20xx) or the year of the "to" timeframe
    thisYear: number = DateTime.now().year;
    // the date of the first entry in the timeframe
    // firstDate = new Date(0);

    @Input() mainUnit: UnitSystemType = 'kg';
    @Input() currentUser: UserType;
    @Input() isDarkmode = false;

    _small = false;
    @Input() set isSmall(is: boolean) {
        this._small = is;
    }
    _isRatherSmall = false;
    isRatherSmall$: Observable<boolean>;

    haveData = false;

    additionalData: any;

    data: ChartConfiguration<'line'>['data'] & { yield_idx?: number, last_yield_idx?: number, amount_idx?: number, last_amount_idx?: number };

    weight_factor = 1; // if max weights are large (>2000kg or >4000lbs) the weight data in sets is divided by weight_factor to make ticks more readable
    // weight_factor is 1 if data is in this.mainUnit, 1000 if this.mainUnit is 'kg' and data values are in 't' or
    // weight_factor is 2000 if this.mainUnit is 'lbs' and data values are in 't'
    tickUnit: UnitSystemType | 't' = this.mainUnit; // the label used to indicate the weight unit of axis ticks (the string 'kg', 'lbs', or 't')

    options: ChartConfiguration<'line'>['options'] = {
        plugins: {
            roughness: {
                disabled: true,
            },
            title: {
                display: true,
                text: 'Accumulated Production', // translated below
                position: 'top' as const,
            },
            legend: {
                display: true,
                position: 'bottom' as const,
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                mode: 'nearest' as const,
                intersect: false,
                // we do no longer filter only this years data
                //            filter: (tooltipItem, data) => (tooltipItem.datasetIndex === data.yield_idx || tooltipItem.datasetIndex === data.amount_idx) && tooltipItem.index !== 0,
                backgroundColor: 'rgba(66,66,66,0.8)', // '#424242',
                titleColor: 'rgba(255, 255, 255, 0)',
                bodyColor: 'rgba(0, 0, 0, 0)',
                displayColors: false,
                callbacks: {
                    title: (tooltipItems: TooltipItem<'line'>[]) => {
                    // title: (tooltipItems: { index: any; datasetIndex: number; }[], data: { yield_idx: number, amount_idx: number, last_yield_idx: number, last_amount_idx: number, datasets: { data: { t_display: Date }[] }[] }) => {
                        if (tooltipItems.length) {
                            const tooltipItem = tooltipItems[0];
                            const index = tooltipItem.dataIndex;
                            if (index > 0) {
                                // independent on whether point to yield or amount, show amount data (if available)
                                let amount_idx = -1;
                                if ((tooltipItem.datasetIndex === this.data.yield_idx || tooltipItem.datasetIndex === this.data.amount_idx) && this.data.amount_idx > -1) {
                                    amount_idx = this.data.amount_idx;
                                } else if ((tooltipItem.datasetIndex === this.data.last_yield_idx || tooltipItem.datasetIndex === this.data.last_amount_idx) && this.data.last_amount_idx > -1) {
                                    amount_idx = this.data.last_amount_idx;
                                }
                                if ((amount_idx > -1) && (this.data.datasets[amount_idx]?.data?.[index]?.['t_display'])) {
                                    // (chart.js 4) changed
                                    const d = this.data.datasets[amount_idx].data[index]['t_display'];
                                    return DateTime.fromJSDate(d).toFormat('DD');
                                }
                            }
                        }
                    },
                    beforeBody: (tooltipItems: TooltipItem<'line'>[]) => {
                    // beforeBody: (tooltipItems: { index: any; datasetIndex: number; }[], data: { yield_idx: number, amount_idx: number, last_yield_idx: number, last_amount_idx: number, datasets: { data: { y: number }[], counts: number[], countTotals: number[] }[] }) => {
                        if (tooltipItems.length) {
                            const str = [];
                            const tooltipItem = tooltipItems[0];
                            const index = tooltipItem.dataIndex;
                            if (index > 0) {
                                // check whether to show current or last year's data
                                let amount_idx = -1;
                                let yield_idx = -1;
                                if ((tooltipItem.datasetIndex === this.data.yield_idx || tooltipItem.datasetIndex === this.data.amount_idx) && this.data.amount_idx > -1) {
                                    amount_idx = this.data.amount_idx;
                                    yield_idx = this.data.yield_idx;
                                } else if ((tooltipItem.datasetIndex === this.data.last_yield_idx || tooltipItem.datasetIndex === this.data.last_amount_idx) && this.data.last_amount_idx > -1) {
                                    amount_idx = this.data.last_amount_idx;
                                    yield_idx = this.data.last_yield_idx;
                                }
                                if ((amount_idx > -1) && (this.data.datasets[amount_idx].data?.[index]?.['y'])) {
                                    const convFactor = this.utils.getUnitFactor(this.mainUnit) * 1 / this.weight_factor; // to convert values back before formatting
                                    const totalAmount = this.data.datasets[amount_idx].data[index]['y'] / convFactor;
                                    const totalAmount_formated = this.utils.formatAmountForPipe(totalAmount, undefined, this.currentUser.unit_system);
                                    const totalAmount_formated_full = this.decimalPipe.transform(totalAmount_formated.value, '1.0-1') + totalAmount_formated.post;
                                    if (this.data.datasets[amount_idx]?.['counts']?.[index]) {
                                        const count = this.data.datasets[amount_idx]['counts'][index];
                                        const countTotal = this.data.datasets[amount_idx]['countTotals'][index];
                                        str.push(`${this.tr.anslate('batches')}: ${count} (${countTotal})`);
                                    }
                                    const dayAmountRaw = (totalAmount - this.data.datasets[amount_idx].data[index - 1]['y'] / convFactor);
                                    const dayAmount = Math.round(dayAmountRaw * 10) / 10;
                                    const dayAmount_formated = this.utils.formatAmountForPipe(dayAmount, undefined, this.currentUser.unit_system);
                                    const dayAmount_formated_full = this.decimalPipe.transform(dayAmount_formated.value, '1.0-1') + dayAmount_formated.post;
                                    str.push(this.tr.anslate('amount') + `: ${dayAmount_formated_full} (${totalAmount_formated_full})`);
                                    if ((yield_idx > -1) && this.data.datasets[yield_idx].data?.[index]?.['y']) {
                                        const totalYield = this.data.datasets[yield_idx].data[index]['y'] / convFactor;
                                        const totalYield_formated = this.utils.formatAmountForPipe(totalYield, undefined, this.currentUser.unit_system);
                                        const totalYield_formated_full = this.decimalPipe.transform(totalYield_formated.value, '1.0-1') + totalYield_formated.post;
                                        const dayYieldRaw = (totalYield - this.data.datasets[yield_idx].data[index - 1]['y'] / convFactor);
                                        const dayYield = Math.round(dayYieldRaw * 10) / 10;
                                        const dayYield_formated = this.utils.formatAmountForPipe(dayYield, undefined, this.currentUser.unit_system);
                                        const dayYield_formated_full = this.decimalPipe.transform(dayYield_formated.value, '1.0-1') + dayYield_formated.post;
                                        if (dayYield > 0) {
                                            str.push(this.tr.anslate('yield') + `: ${dayYield_formated_full} (${totalYield_formated_full})`);
                                            const loss = Math.round((dayAmountRaw - dayYieldRaw) / dayAmountRaw * 1000) / 10;
                                            str.push(this.tr.anslate('loss') + ': ' + loss + '%');
                                        }
                                    }
                                    return str;
                                }
                            }
                        }
                    },
                    label: () => {
                        return '';
                    },
                }
            },
        },
        hover: {
            mode: null,
            intersect: false,
        },
        layout: {
            padding: {
                left: 5,
                right: 30,
                top: 20,
                bottom: 20
            }
        },
        elements: {
            point: {
                radius: 0,
                hitRadius: 4,
                hoverRadius: 4
            },
            line: {
                tension: 0
            },
        },
        scales: {
            x: {
                grid: {
                    drawOnChartArea: true,
                    display: true,
                    // TODO (chart.js 4) check if ok to remove
                    // offsetGridLines: false,
                    color: 'rgba(0, 0, 0, 0.1)',
                    // TODO (chart.js 4) check if ok to remove
                    // zeroLineColor: '#999'
                },
                type: 'time' as const,
                time: {
                    unit: 'month' as const,
                    // TODO (chart.js 4) check if ok to remove (switched type from time to timeseries)
                    // distribution: 'series'
                },
                display: true,
                title: {
                    display: false,
                },
                ticks: {
                    autoSkip: true,
                    callback: (value: string | number, index: number, ticks: { value: number }[]) => {
                        try {
                            return DateTime.fromMillis(ticks[index].value).toFormat(this._isRatherSmall ? 'MMM' : 'MMMM');
                        } catch (error) {
                            return value;
                        }
                    },
                },
            },
            y: {
                display: true,
                title: {
                    display: false
                },
                suggestedMin: 0,
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 5,
                    callback: (value: unknown) => {
                        if ((this._small) && (this.tickUnit !== 't')) {
                            return `${value}`;
                        } else {
                            return `${value}${this.tickUnit}`;
                        }
                    }
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                    // TODO (chart.js 4) check if ok to remove
                    // zeroLineColor: '#999'
                },
            },
        },
        responsive: true,
        aspectRatio: 1.2,
        maintainAspectRatio: false,
    };

    @Input() set newData(nd: any) {
        this.setNewData(nd);
    }


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }

        this.options.plugins.title.text = this.tr.anslate('Accumulated Production');

        this.isRatherSmall$ = this.breakpointObserver.observe('(max-width: 999px)')
            .pipe(map(result => result.matches));

        this.inited = true;
        // mainUnit @Input is set after the options object is initialized
        if (this.updData) {
            this.setNewData(this.updData);
            this.updData = undefined;
        }

        this.isRatherSmall$.subscribe(val => this._isRatherSmall = val);
    }

    setNewData(nd_in: any) {
        const nd = cloneDeep(nd_in); // we need to make a copy before we destructively change the data to allow other charts to access the unmodified data
        this.haveData = false;
        if (nd?.data?.length > 1 || (nd.additionalData?.length && nd.additionalData[0]?.dates?.length > 1)) {
            this.thisYear = nd.labels.length ? (DateTime.fromISO(nd.labels[nd.labels.length - 1]).year || DateTime.now().year) : DateTime.now().year;
            // this.firstDate = new Date(nd.labels[0]);
            const firstDay = new Date(this.thisYear, 0, 1);
            const firstDay_lastYear = new Date(this.thisYear - 1, 0, 1);

            this.data = { datasets: [] };
            this.data.yield_idx = -1; // set to the datasetIndex of current years yield dataset if it exists or -1
            this.data.amount_idx = -1; // set to the datasetIndex of current years amount dataset if it exists or -1
            this.data.last_yield_idx = -1; // set to the datasetIndex of last years yield dataset if it exists or -1
            this.data.last_amount_idx = -1; // set to the datasetIndex of last years amount dataset if it exists or -1

            const amountEntries = [];
            const yieldEntries = [];
            let countEntries = [];
            const countTotals = [];
            let totalAmount = 0;
            let totalYield = 0;
            const lastAmountEntries = [];
            const lastYieldEntries = [];
            let lastCountEntries = [];
            const lastCountTotals = [];
            let lastTotalAmount = 0;
            let lastTotalYield = 0;

            // 0. convert the weight data if needed
            if (this.mainUnit === 'lbs' || this.mainUnit === 'lb') {
                const convFactor = this.utils.getUnitFactor(this.mainUnit);
                nd.data = nd.data.map((x: number) => x * convFactor); // amounts per day
                nd['coffees'] = nd['coffees'].map((x: number) => x * convFactor); // yields per day
                nd['additionalData'][1].data = nd['additionalData'][1].data.map((x: number) => x * convFactor); // amounts per day (last year)
                nd['additionalData'][2].data = nd['additionalData'][2].data.map((x: number) => x * convFactor); // yields per day (last year)
                if (Math.max(nd.data.reduce((a: number, b: number) => a + b, 0), nd['additionalData'][1].data.reduce((a: number, b: number) => a + b, 0)) > 4000) {
                    this.weight_factor = 2000;
                    this.tickUnit = 't';
                    nd.data = nd.data.map((x: number) => x / this.weight_factor); // amounts per day
                    nd['coffees'] = nd['coffees'].map(x => x / this.weight_factor); // yields per day
                    nd['additionalData'][1].data = nd['additionalData'][1].data.map((x: number) => x / this.weight_factor); // amounts per day (last year)
                    nd['additionalData'][2].data = nd['additionalData'][2].data.map((x: number) => x / this.weight_factor); // yields per day (last year)
                } else {
                    this.weight_factor = 1;
                    this.tickUnit = this.mainUnit;
                }
            } else {
                if (Math.max(nd.data.reduce((a: number, b: number) => a + b, 0), nd['additionalData'][1].data.reduce((a: number, b: number) => a + b, 0)) > 2000) {
                    this.weight_factor = 1000;
                    this.tickUnit = 't';
                    nd.data = nd.data.map((x: number) => x / this.weight_factor); // amounts per day
                    nd['coffees'] = nd['coffees'].map((x: number) => x / this.weight_factor); // yields per day
                    nd['additionalData'][1].data = nd['additionalData'][1].data.map((x: number) => x / this.weight_factor); // amounts per day (last year)
                    nd['additionalData'][2].data = nd['additionalData'][2].data.map((x: number) => x / this.weight_factor); // yields per day (last year)
                } else {
                    this.weight_factor = 1;
                    this.tickUnit = this.mainUnit;
                }
            }

            // 1. collect data

            if (nd.labels?.length && nd.data.length === nd.labels.length) {
                for (let i = 0; i < nd.data.length; i++) {
                    const d = new Date(nd.labels[i]);
                    totalAmount += nd.data[i];
                    amountEntries.push({ y: totalAmount, x: d, t_display: d });
                    totalYield += nd.coffees[i];
                    yieldEntries.push({ y: totalYield, x: d });
                }
            }

            if (nd.additionalData?.length > 2) {
                const dates = nd.additionalData[0]?.dates;
                if (dates?.length && dates.length === nd.additionalData[1]?.data?.length &&
                    nd.additionalData[1].data.length === nd.additionalData[2]?.data.length) {
                    for (let i = 0; i < dates.length; i++) {
                        const d = new Date(dates[i]);
                        d.setFullYear(this.thisYear);
                        const d_display = new Date(dates[i]);
                        lastTotalAmount += nd.additionalData[1].data[i];
                        lastAmountEntries.push({ y: lastTotalAmount, x: d, t_display: d_display });
                        lastTotalYield += nd.additionalData[2].data[i];
                        lastYieldEntries.push({ y: lastTotalYield, x: d });
                    }
                }
            }

            if (nd.additionalData?.length > 3 && nd.additionalData[3]?.data.length === nd.labels.length) {
                countEntries = nd.additionalData[3].data.slice();
                countEntries.unshift(0); // add one extra for this fake first day
                let totalCount = 0;
                for (let i = 0; i < countEntries.length; i++) {
                    totalCount += countEntries[i];
                    countTotals.push(totalCount);
                }
            }

            if (nd.additionalData?.length > 10) {
                const dates = nd.additionalData[0]?.dates;
                if (dates?.length && dates.length === nd.additionalData[10]?.data?.length) {
                    lastCountEntries = nd.additionalData[10].data.slice();
                    lastCountEntries.unshift(0); // add one extra for this fake first day
                    let totalCount = 0;
                    for (let i = 0; i < lastCountEntries.length; i++) {
                        totalCount += lastCountEntries[i];
                        lastCountTotals.push(totalCount);
                    }
                }
            }

            const amountLabel = this.tr.anslate('amount');
            const yieldLabel = this.tr.anslate('yield');

            // 2. compute datasets
            if (totalYield > 0) {
                // we have at all some non-zero data in this set
                this.haveData = true;

                // we add a zero to the front
                yieldEntries.unshift({ y: null, x: firstDay });

                this.data.yield_idx = 0;

                this.data.datasets.push({
                    label: this.thisYear === DateTime.now().year ? yieldLabel : `${yieldLabel} ${this.thisYear}`,
                    data: yieldEntries,
                    // (chart.js 4) added
                    fill: true,
                    stepped: false,
                    //                    cubicInterpolationMode: 'monotone', // 'default'
                    borderWidth: this.isDarkmode ? 1 : 2,
                    backgroundColor: this.isDarkmode ? '#144566DD' : '#0c6aa666', // dark: light: P900/66
                    borderColor: this.isDarkmode ? '#1e90c1CC' : '#0c6aa6DD',
                    pointHoverBorderWidth: 1,
                    pointHoverBackgroundColor: '#0c6aa6DD',
                    pointHoverBorderColor: '#0c6aa6DD',
                });
            }
            if (totalAmount > 0) {
                // we have at all some non-zero data in this set
                this.haveData = true;

                this.data.amount_idx = this.data.datasets.length;

                // we add a zero to the front
                amountEntries.unshift({ y: null, x: firstDay, t_display: firstDay });

                this.data.datasets.push({
                    label: this.thisYear === DateTime.now().year ? amountLabel : `${amountLabel} ${this.thisYear}`,
                    data: amountEntries,
                    // (chart.js 4) added
                    fill: true,
                    stepped: false,
                    borderWidth: 1,
                    //backgroundColor: '#1985ba55', // P700
                    backgroundColor: this.isDarkmode ? '#43a7cf66' : '#147bb344', // dark: P400/66, light: P800/44
                    //borderColor: '#1985ba00',
                    borderColor: this.isDarkmode ? '#43a7cfBB' : '#147bb3AA', // dark: P400/BB, light: P800/AA
                    showLine: true,
                    pointHoverBorderWidth: 1,
                    pointHoverBackgroundColor: '#1985baDD',
                    pointHoverBorderColor: '#1985baDD',
                });
                this.data.datasets[this.data.datasets.length - 1]['counts'] = countEntries;
                this.data.datasets[this.data.datasets.length - 1]['countTotals'] = countTotals;
            }

            // last years data

            if (lastTotalYield > 0) {
                // we have at all some non-zero data for last year
                this.haveData = true;

                this.data.last_yield_idx = this.data.datasets.length;

                // we add a zero to the front
                lastYieldEntries.unshift({ y: null, x: firstDay });

                this.data.datasets.push({
                    label: `${yieldLabel} ${this.thisYear - 1}`,
                    data: lastYieldEntries,
                    // (chart.js 4) added
                    fill: true,
                    stepped: false,
                    borderWidth: this.isDarkmode ? 1 : 2,
                    pointHoverRadius: 0,
                    borderColor: this.isDarkmode ? '#fafafa44' : '#bdbdbd55', // dark: G200/66, light: G400/55
                    backgroundColor: this.isDarkmode ? '#61616199' : '#bdbdbd55', // dark: G700/AA, light: G400/55
                });
            }
            if (lastTotalAmount > 0) {
                // we have at all some non-zero data for last year
                this.haveData = true;

                this.data.last_amount_idx = this.data.datasets.length;

                // we add a zero to the front
                lastAmountEntries.unshift({ y: null, x: firstDay, t_display: firstDay_lastYear });

                this.data.datasets.push({
                    label: `${amountLabel} ${this.thisYear - 1}`,
                    data: lastAmountEntries,
                    // (chart.js 4) added
                    fill: true,
                    stepped: false,
                    borderWidth: this.isDarkmode ? 0 : 1,
                    pointHoverRadius: 0,
                    borderColor: this.isDarkmode ? '#f5f5f500' : '#1e90c100',
                    backgroundColor: this.isDarkmode ? '#fafafa66' : '#e0e0e077', // dark: G50/, light: G300/77
                });
                this.data.datasets[this.data.datasets.length - 1]['counts'] = lastCountEntries;
                this.data.datasets[this.data.datasets.length - 1]['countTotals'] = lastCountTotals;
            }

            // this.options.scales.x.ticks['min'] = (this.firstDate?.year === this.thisYear) ? DateTime.fromJSDate(this.firstDate).startOf('month').valueOf() : undefined;
            // this.options.scales.x.ticks['max'] = ...

            if (this.isDarkmode) {
                this.options.scales.x.grid.color = 'rgba(255, 255, 255, 0.2)';
                // this.options.scales.x.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
                this.options.scales.y.grid.color = 'rgba(255, 255, 255, 0.2)';
                // this.options.scales.y.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
                this.options.plugins.tooltip.titleColor = '#212121DD'; // G900/BB
                this.options.plugins.tooltip.bodyColor = '#212121DD'; // G900/BB
                this.options.plugins.tooltip.backgroundColor = '#eeeeeeDD'; // G200/DD => '#eeeeee'
            } else {
                this.options.scales.x.grid.color = 'rgba(0, 0, 0, 0.1)';
                // this.options.scales.x.grid.zeroLineColor = '#999';
                this.options.scales.y.grid.color = 'rgba(0, 0, 0, 0.1)';
                // this.options.scales.y.grid.zeroLineColor = '#999';
                this.options.plugins.tooltip.titleColor = '#fff';
                this.options.plugins.tooltip.bodyColor = '#fff';
                this.options.plugins.tooltip.backgroundColor = 'rgba(66,66,66,0.8)'; // G800, '#424242'
            }
        }
        this.chart?.update();
        // TODO add methods to save image
        // console.log(this.chart.toBase64Image());
    }
}
