/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Component, OnInit, Input, LOCALE_ID, Inject, ViewChild } from '@angular/core';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Router } from '@angular/router';
// import 'chartjs-plugin-roughness';
import * as roughness from 'src/app/util/chartjs-plugin-roughness';
import { Chart, BarController, BarElement, LinearScale, Title, Legend, Tooltip, TooltipItem, LegendItem, ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { DateTime } from 'luxon';
import { formatNumber } from '@angular/common';
Chart.register(BarController, BarElement, LinearScale, Title, Legend, Tooltip);


@Component({
    standalone: true,
    selector: 'app-roastamounttotal-graph',
    templateUrl: './roastamounttotal-graph.component.html',
    imports: [BaseChartDirective],
})
export class RoastamounttotalGraphComponent implements OnInit {

    constructor(
        private tr: TranslatorService,
        private userService: UserService,
        private utils: Utils,
        private router: Router,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        if (roughness) console.log('loaded rougness');
    }

    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

    // needed to have the correct order of ngOnInit and newData setter
    inited = false;
    updData: any;

    readonly BTU_INDEX = 2;
    readonly CO2_INDEX = 3;
    readonly TOTALS_BTU_INDEX = 8;
    readonly TOTALS_CO2_INDEX = 9;

    readonly co2Unit = 'kg';

    @Input() mainUnit: UnitSystemType = 'kg';
    @Input() currentUser: UserType;
    @Input() isDarkmode = false;

    _small = false;
    @Input() set isSmall(is: boolean) {
        this._small = is;
        if (this.chart) {
            this.options.plugins.legend.display = !is;
            this.chart.render();
        }
    }

    haveData = false;

    data: ChartConfiguration<'line'>['data'];

    weight_factor = 1; // if max sweights are large (>2000kg or >4000lbs) the weight data in sets is divided by weight_factor to make ticks more readable
    // weight_factor is 1 if data is in this.mainUnit, 1000 if this.mainUnit is 'kg' and data values are in 't' or
    // weight_factor is 2000 if this.mainUnit is 'lbs' and data values are in 't'
    tickUnit: UnitSystemType | 't' = this.mainUnit; // the label used to indicate the weight unit of axis ticks (the string 'kg', 'lbs', or 't')

    // plugins = [ChartRough];

    options: ChartConfiguration<'bar'>['options'] = {
        indexAxis: 'y' as const,
        plugins: {
            title: {
                display: true,
                text: this.tr.anslate('Yearly Production'),
            },
            legend: {
                display: true,
                position: 'bottom' as const,
                labels: {
                    filter: (legendItem: LegendItem, chartData: any) => {
                        return chartData.datasets[legendItem.datasetIndex].data[0] > 0;
                    }
                }
            },
            datalabels: {
                display: false,
            },
            roughness: {
                roughness: 0,
                bowing: 1,
                fillStyle: 'hachure',
                fillWeight: 0,
                hachureAngle: -41,
                hachureGap: 2.5,
                curveStepCount: 1,
                simplification: 0
            },
            tooltip: {
                mode: 'index' as const,
                position: 'nearest' as const,
                backgroundColor: 'rgba(66,66,66,0.8)', // '#424242',
                titleColor: 'rgba(255, 255, 255, 0)',
                bodyColor: 'rgba(0, 0, 0, 0)',
                displayColors: false,
                //            filter: (tooltipItem, data) => tooltipItem.datasetIndex === 0 || data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] !== 0,
                callbacks: {
                    beforeBody: (tooltipItems: TooltipItem<'bar'>[]) => {
                        if (tooltipItems.length) {
                            const tooltipItem = tooltipItems[0];
                            const index = tooltipItem.dataIndex; // year index
                            const convFactor = this.utils.getUnitFactor(this.mainUnit) * 1 / this.weight_factor; // to convert values back before formatting
                            const str = [];
                            const yearYield = this.data.datasets[0].data[index] as number;
                            const yearAmount = yearYield + (this.data.datasets[1].data[index] as number);
                            const yearLoss = (yearAmount - yearYield) / yearAmount * 100;
                            const yearAmountFormatted = this.utils.formatAmountForPipe(yearAmount / convFactor, undefined, this.currentUser.unit_system);
                            const yearAmountFullyFormatted = formatNumber(yearAmountFormatted.value, this.locale, '1.0-1') + yearAmountFormatted.post;
                            let amount_str = `${this.tr.anslate('amount')}: ${yearAmountFullyFormatted}`;
                            const loss_str = `${this.tr.anslate('loss')}: ${Math.round(yearLoss * 10) / 10}%`;
                            const yearYieldFormatted = this.utils.formatAmountForPipe(yearYield / convFactor, undefined, this.currentUser.unit_system);
                            const yearYieldFullyFormatted = formatNumber(yearYieldFormatted.value, this.locale, '1.0-1') + yearYieldFormatted.post;
                            let yield_str = `${this.tr.anslate('yield')}: ${yearYieldFullyFormatted}`;
                            if ((index === 0) && this.data.datasets.length > 3) {
                                const estimated_yield = this.data.datasets[2]['value'];
                                const estimated_yield_formatted = this.utils.formatAmountForPipe(estimated_yield, undefined, this.currentUser.unit_system);
                                const estimated_yield_fully_formatted = formatNumber(estimated_yield_formatted.value, this.locale, '1.0-1') + estimated_yield_formatted.post;
                                const estimated_amount = this.data.datasets[3]['value'];
                                const estimated_amount_formatted = this.utils.formatAmountForPipe(estimated_amount, undefined, this.currentUser.unit_system);
                                const estimated_amount_fully_formatted = formatNumber(estimated_amount_formatted.value, this.locale, '1.0-1') + estimated_amount_formatted.post;
                                if (this.data.datasets[2]['value'] > 0) {
                                    yield_str += ` (${estimated_yield_fully_formatted})`;
                                }
                                if (this.data.datasets[3]['value'] > 0) {
                                    amount_str += ` (${estimated_amount_fully_formatted})`;
                                }
                            }
                            if (this.data.datasets[1]?.['counts']?.[index] && this.data.datasets[1]['roastDays'][index]) {
                                const count = this.data.datasets[1]['counts'][index];
                                const days = this.data.datasets[1]['roastDays'][index];
                                if (days > 1) {
                                    str.push(`${this.tr.anslate('batches')}: ${this.tr.anslate('{{count}} on {{days}} days', { count, days }).trim()}`);
                                } else if (days === 1) {
                                    str.push(`${this.tr.anslate('batches')}: ${this.tr.anslate('{{count}} on one day', { count }).trim()}`);
                                } else {
                                    str.push(`${this.tr.anslate('batches')}: ${count}`);
                                }
                            }
                            str.push(amount_str);
                            if (yearYield > 0) {
                                str.push(yield_str);
                            }
                            if (yearYield > 0) {
                                str.push(loss_str);
                            }
                            const energy = this.data.datasets[0]['totalBTU']?.[index];
                            if (energy) {
                                const btu = this.utils.convertEnergy(energy, this.currentUser.energy_unit);
                                let btu_str: string;
                                let btu_unit: string;
                                if (btu >= 100000) {
                                    btu_str = formatNumber(btu / 1000, this.locale, '1.0-0');
                                    btu_unit = this.utils.getUnit1000(this.currentUser.energy_unit);
                                } else {
                                    btu_str = formatNumber(btu, this.locale, '1.0-0');
                                    btu_unit = this.currentUser.energy_unit;
                                }
                                let energy_tag = this.tr.anslate('Energy');
                                if (this.locale !== 'de') {
                                    energy_tag = energy_tag.toLowerCase();
                                }
                                let energy_str = `${energy_tag}: ${btu_str} ${btu_unit}`;
                                let total_amount_with_btu = this.data.datasets[0]['totalAmountWithBTU']?.[index];
                                if (total_amount_with_btu) {
                                    total_amount_with_btu *= this.utils.getUnitFactor(this.mainUnit);
                                    let btu_per_weight = btu / total_amount_with_btu;
                                    if (btu_per_weight > 0) {
                                        let btu_per_weight_unit = this.currentUser.energy_unit;
                                        if (btu_per_weight < 1) {
                                            const energyUnitDividedBy1000 = this.utils.getUnitDividedBy1000(btu_per_weight_unit);
                                            if (energyUnitDividedBy1000) {
                                                btu_per_weight *= 1000.0;
                                                btu_per_weight_unit = energyUnitDividedBy1000;
                                            }
                                        }
                                        let digits = 0;
                                        if (btu_per_weight < 10) {
                                            digits = 2;
                                        } else if (btu_per_weight < 100) {
                                            digits = 1;
                                        }
                                        const btu_per_weight_str = formatNumber(btu_per_weight, this.locale, `1.0-${digits}`);
                                        energy_str += ` (${btu_per_weight_str} ${btu_per_weight_unit}/${this.mainUnit})`;
                                    }
                                }
                                str.push(energy_str);
                            }
                            const co2 = this.data.datasets[0]['totalCO2']?.[index];
                            let totalRoastedCO2 = this.data.datasets[0]['totalAmountWithCO2']?.[index];
                            if (co2 && totalRoastedCO2) {
                                totalRoastedCO2 *= this.utils.getUnitFactor(this.mainUnit);
                                const co2_str = formatNumber(co2, this.locale, '1.0-0');
                                const co2per = co2 / totalRoastedCO2;
                                const co2per_fmt = this.utils.formatAmountForPipe(co2per);
                                const co2_value = co2per_fmt.value;
                                let digits = 0;
                                if (co2_value < 10) {
                                    digits = 2;
                                } else if (co2_value < 100) {
                                    digits = 1;
                                }
                                const co2_value_str = formatNumber(co2_value, this.locale, `1.0-${digits}`)
                                const emission_str = `CO2: ${co2_str} kg (${co2_value_str} ${co2per_fmt.post}/${this.mainUnit})`;
                                str.push(emission_str);
                            }
                            return str;
                            // yield (estimated yield), amount (estimated amount), loss
                        }
                    },
                    label: () => {
                        return '';
                    },
                }
            },
        },
        // hover: {
        //     animationDuration: 0, // duration of animations when hovering an item
        // },
        // animation: {
        //     duration: 0 // general animation time
        // },
        layout: {
            padding: {
                left: 5,
                right: 30,
                top: 20,
                bottom: 20
            }
        },
        scales: {
            x: {
                stacked: true,
                title: {
                    display: false
                },
                ticks: {
                    callback: (value: unknown) => {
                            if ((this._small) && (this.tickUnit !== 't')) {
                            return `${value}`;
                        } else {
                            return `${value}${this.tickUnit}`;
                        }
                    }
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                    // zeroLineColor: '#999'
                },
            },
            y: {
                stacked: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                    // zeroLineColor: 'rgba(0, 0, 0, 0.1)',
                },
            },
        },
        responsive: true,
        aspectRatio: 1.2,
        maintainAspectRatio: false,
    };

    @Input() set newData(nd: any) {
        this.setNewData(nd);
    }

    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }

        // Chart.pluginService.register(ChartRough);
        this.inited = true;
        // mainUnit @Input is set after the options object is initialized
        if (this.updData) {
            this.setNewData(this.updData);
            this.updData = undefined;
        }
    }

    setNewData(nd: any) {
        this.data = { labels: [], datasets: [] };
        this.haveData = false;

        if (nd?.data) {
            if (nd.labels?.length && nd.additionalData?.length > 1 &&
                nd.coffees && nd.additionalData[0]?.data && nd.additionalData[1]?.data &&
                nd.coffees.length === nd.labels.length &&
                nd.data && nd.labels.length === nd.data.length &&
                nd.additionalData[0].data.length === nd.data.length &&
                nd.additionalData[1].data.length === nd.data.length) {

                this.haveData = true;

                let totalBTU = [];
                let totalCO2 = [];
                let totalAmountWithBTU = [];
                let totalAmountWithCO2 = [];
                if (nd.additionalData?.length > Math.max(this.BTU_INDEX, this.CO2_INDEX, this.TOTALS_BTU_INDEX, this.TOTALS_CO2_INDEX)) {
                    totalBTU = nd.additionalData[this.BTU_INDEX].data;
                    totalCO2 = nd.additionalData[this.CO2_INDEX].data;
                    totalAmountWithBTU = nd.additionalData[this.TOTALS_BTU_INDEX].data;
                    totalAmountWithCO2 = nd.additionalData[this.TOTALS_CO2_INDEX].data;
                }

                // convert the weight data if needed
                let coffees_converted = nd.coffees; // yields per year
                let data_converted = nd.data;       // amounts per year
                if (this.mainUnit === 'lbs') {
                    const convFactor = this.utils.getUnitFactor(this.mainUnit)
                    coffees_converted = coffees_converted.map((x: number) => x * convFactor);
                    data_converted = data_converted.map((x: number) => x * convFactor);
                    if (Math.max(...data_converted) > 4000) {
                        this.weight_factor = 2000;
                        this.tickUnit = 't';
                        coffees_converted = coffees_converted.map((x: number) => x / this.weight_factor);
                        data_converted = data_converted.map((x: number) => x / this.weight_factor);
                    } else {
                        this.weight_factor = 1;
                        this.tickUnit = this.mainUnit;
                    }
                } else {
                    if (Math.max(...data_converted) > 2000) {
                        this.weight_factor = 1000;
                        this.tickUnit = 't';
                        coffees_converted = coffees_converted.map((x: number) => x / this.weight_factor);
                        data_converted = data_converted.map((x: number) => x / this.weight_factor);
                    } else {
                        this.weight_factor = 1;
                        this.tickUnit = this.mainUnit;
                    }
                }
                const dsets = [];

                if (this.isDarkmode) {
                    this.options.scales.x.grid.color = 'rgba(255, 255, 255, 0.2)';
                    // this.options.scales.x.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
                    this.options.scales.y.grid.color = 'rgba(255, 255, 255, 0.2)';
                    // this.options.scales.y.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
                    this.options.plugins.tooltip.titleColor = '#212121DD'; // G900/BB
                    this.options.plugins.tooltip.bodyColor = '#212121DD'; // G900/BB
                    this.options.plugins.tooltip.backgroundColor = '#eeeeeeDD'; // G200/DD => '#eeeeee'
                } else {
                    this.options.scales.x.grid.color = 'rgba(0, 0, 0, 0.1)';
                    // this.options.scales.x.grid.zeroLineColor = '#999';
                    this.options.scales.y.grid.color = 'rgba(0, 0, 0, 0.1)';
                    // this.options.scales.y.grid.zeroLineColor = 'rgba(0, 0, 0, 0.1)';
                    this.options.plugins.tooltip.titleColor = '#fff';
                    this.options.plugins.tooltip.bodyColor = '#fff';
                    this.options.plugins.tooltip.backgroundColor = 'rgba(66,66,66,0.8)'; // G800, '#424242'
                }

                // add yields dataset (+energy/CO2 data)
                dsets.push({
                    label: this.tr.anslate('yield'),
                    data: coffees_converted, // yields converted
                    //
                    totalBTU: totalBTU,
                    totalCO2: totalCO2,
                    totalAmountWithBTU: totalAmountWithBTU,
                    totalAmountWithCO2: totalAmountWithCO2,
                    //
                    backgroundColor: this.isDarkmode ? '#1985ba77' : '#0c6aa666', // dark: P700/77, light: P900/66
                    borderColor: this.isDarkmode ? '#1985baDD' : '#0c6aa6', // dark: 700/DD, light: P900
                    borderWidth: 1,
                    hoverBackgroundColor: this.isDarkmode ? '#1985baAA' : '#0c6aa699', // dark: P700/AA, light: P900/99
                    hoverBorderColor: this.isDarkmode ? '#1985ba' : '#0c6aa6', // dark: P700/DD, light: P900
                    roughness: {
                        roughness: 0,
                        fillStyle: 'solid',
                        bowing: 0
                    }
                });

                // add amounts dataset
                dsets.push({
                    label: this.tr.anslate('amount'),
                    data: data_converted.map((n: number, i: number) => n - coffees_converted[i]),
                    counts: nd.additionalData[0].data, // number of batches
                    roastDays: nd.additionalData[1].data, // number of roast days
                    //                    backgroundColor: '#147bb344', // P800
                    backgroundColor: this.isDarkmode ? '#43a7cf77' : '#147bb344', // dark: P400/77, light: P800/44
                    borderColor: this.isDarkmode ? '#43a7cfDD' : '#147bb3AA', // dark: P400/DD, light: P800/AA
                    borderWidth: 1,
                    hoverBackgroundColor: this.isDarkmode ? '#43a7cfBB' : '#147bb377', // dark:P400/BB , leight: P800/77
                    hoverBorderColor: this.isDarkmode ? '#43a7cf' : '#147bb3DD', // dark:P400 , leight: P800/DD
                    roughness: {
                        roughness: 0,
                        fillStyle: 'solid',
                        bowing: 0
                    }
                });

                // estimates for the current year
                if ((nd.labels.length > 0) && nd.labels[0] === new Date().getFullYear().toString()) {
                    const currentYield = coffees_converted[0];
                    const currentAmount = data_converted[0];
                    const dayOfYear = DateTime.now().ordinal;
                    const estimated_yield = (currentYield / dayOfYear) * 365;
                    const estimated_amount = (currentAmount / dayOfYear) * 365;

                    // only show estimated total for currrent year after 30. January and if at least 3 roastDays have been registered
                    if ((dayOfYear > 30) && (nd.additionalData[1].data.length) && nd.additionalData[1].data[0] > 2) {
                        dsets.push({
                            label: this.tr.anslate('estimated yield'),
                            data: data_converted.map((n, i) => ((i === 0) ? Math.max(0, estimated_yield - Math.max(currentAmount, currentYield)) : 0)),
                            value: (nd['coffees'][0] / dayOfYear) * 365, // estimated yield in kg
                            backgroundColor: this.isDarkmode ? '#9e9e9eCC' : '#757575A0', // dark: G500/CC, light: G600/A0
                            hoverBackgroundColor: this.isDarkmode ? '#9e9e9eFF' : '#424242FF', // dark: G500/FF, light: G800/FF
                            borderWidth: 1,
                            borderColor: this.isDarkmode ? '#9e9e9eBB' : '#bdbdbd', // dark: G500/BB, light: G400
                            hoverBorderColor: this.isDarkmode ? '#bdbdbdEE' : '#9e9e9e', // dark: G400/EEom, light: G500
                        });
                        dsets.push({
                            label: this.tr.anslate('estimated amount'),
                            data: data_converted.map((n, i) => ((i === 0) ? Math.max(0, estimated_amount - Math.max(estimated_yield, Math.max(currentAmount, currentYield))) : 0)),
                            value: (nd.data[0] / dayOfYear) * 365, // estimated amount in kg
                            // this worked with rough:
                            // backgroundColor: this.isDarkmode ? '#e0e0e0DD' : '#bdbdbdA0',  // dark: G300/DD, light: G400/A0
                            backgroundColor: this.isDarkmode ? '#fafafa66' : '#e0e0e077', // dark: G50/, light: G300/77
                            hoverBackgroundColor: this.isDarkmode ? '#eeeeee' : '#9e9e9eFF', // dark: G200/EE, light: G500/A0
                            borderWidth: 0
                        });
                    }
                }

                this.data.labels = nd.labels;
                this.data.datasets = dsets;
            }
        }
        this.chart?.render();
    }
}
