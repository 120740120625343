/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Component, OnInit, Input, ViewChild, Inject, LOCALE_ID } from '@angular/core';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import 'chartjs-plugin-stacked100';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { DecimalPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import cloneDeep from 'lodash-es/cloneDeep';
import 'chartjs-adapter-luxon';
import 'chartjs-plugin-stacked100';
import { Chart, BarController, BarElement, LinearScale, Title, Legend, Tooltip, TooltipItem, ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { DateTime, Info } from 'luxon';
Chart.register(BarController, BarElement, LinearScale, Title, Legend, Tooltip);

@Component({
    standalone: true,
    selector: 'app-roastamountpertime-graph',
    templateUrl: './roastamountpertime-graph.component.html',
    imports: [BaseChartDirective],
})

export class RoastamountpertimeGraphComponent implements OnInit {

    constructor(
        private tr: TranslatorService,
        private userService: UserService,
        private breakpointObserver: BreakpointObserver,
        private decimalPipe: DecimalPipe,
        private utils: Utils,
        private router: Router,
        @Inject(LOCALE_ID) public locale: string,
    ) { }

    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

    // the current year (20xx) or the year of the "to" timeframe
    thisYear: number = DateTime.now().year;

    // needed to have the correct order of ngOnInit and newData setter
    inited = false;
    updData: any;

    @Input() mainUnit: UnitSystemType = 'kg';
    @Input() currentUser: UserType;
    @Input() isDarkmode = false;

    _small = false;
    @Input() set isSmall(is: boolean) {
        this._small = is;
    }
    _isRatherSmall = false;
    isRatherSmall$: Observable<boolean>;

    haveData = false;

    additionalData: any;

    data: ChartConfiguration<'line'>['data'] & { yield_idx?: number, last_yield_idx?: number, amount_idx?: number, last_amount_idx?: number };

    weight_factor = 1; // if max weights are large (>2000kg or >4000lbs) the weight data in sets is divided by weight_factor to make ticks more readable
    // weight_factor is 1 if data is in this.mainUnit, 1000 if this.mainUnit is 'kg' and data values are in 't' or
    // weight_factor is 2000 if this.mainUnit is 'lbs' and data values are in 't'
    tickUnit: UnitSystemType | 't' = this.mainUnit; // the label used to indicate the weight unit of axis ticks (the string 'kg', 'lbs', or 't')

    options: ChartConfiguration<'bar'>['options'] = {
        plugins: {
            roughness: {
                disabled: true,
            },
            // TODO chart.js 4 check
            // rough: false,
            datalabels: {
                display: false,
            },
            title: {
                display: true,
                text: 'Monthly Production', // translated below
                position: 'top' as const,
            },
            legend: {
                display: true,
                position: 'bottom' as const,
            },
            tooltip: {
                mode: 'index' as const,
                position: 'average' as const,
                backgroundColor: 'rgba(66,66,66,0.8)', // '#424242',
                titleColor: 'rgba(255, 255, 255, 0)',
                bodyColor: 'rgba(0, 0, 0, 0)',
                displayColors: false,
                // filter to show only tooltips for month with current year amount
                //            filter: (tooltipItem, data) => (tooltipItem.datasetIndex === data.amount_idx && data.datasets[data.amount_idx].data[tooltipItem.index] > 0),
                callbacks: {
                    title: (tooltipItems: TooltipItem<'bar'>[]) => {
                        if (tooltipItems[0]) {
                            const index = tooltipItems[0].dataIndex; // month
                            if (tooltipItems?.[0]?.parsed.y && !tooltipItems?.[1]?.parsed.y) {
                                // have only data for previous year
                                return `${this.utils.getMonthStr(index)} ${this.thisYear - 1}`;
                            }
                            if (!tooltipItems?.[0]?.parsed.y && tooltipItems?.[1]?.parsed.y) {
                                // have only data for latest year
                                return `${this.utils.getMonthStr(index)} ${this.thisYear}`;
                            }
                            // have data for latest and previous year
                            return `${this.utils.getMonthStr(index)} ${this.thisYear} (${this.thisYear - 1})`;
                        }
                    },
                    // beforeBody: (tooltipItems: { index: number; datasetIndex: number; }[], data: { amount_idx: number, last_amount_idx: number, datasets: { data: number[], label: string, batches: number[] }[] }) => {
                    beforeBody: (tooltipItems: TooltipItem<'bar'>[]) => {
                        if (tooltipItems[0]) {
                            const tooltipItem = tooltipItems[0];
                            const str: string[] = [];
                            const index = tooltipItem.dataIndex; // month
                            if (index > -1) {
                                const convFactor = this.utils.getUnitFactor(this.mainUnit) * 1 / this.weight_factor; // to convert values back before formatting

                                const roast_amount_label = this.tr.anslate('amount');
                                const roast_yield_label = this.tr.anslate('yield');
                                let onlyLastYear = false;
                                if (this.data.amount_idx < 0) {
                                    onlyLastYear = true;
                                } else {
                                    // datasetIndex of current years yield dataset if it exists or -1
                                    const dataset_amount = this.data.datasets[this.data.amount_idx];

                                    let roast_amount = 0;
                                    let roast_yield = 0;

                                    if (this.data.yield_idx > -1) {
                                        const dataset_yield = this.data.datasets[this.data.yield_idx];
                                        if (dataset_yield?.data && dataset_yield?.label) {
                                            roast_yield = (dataset_yield.data[index] as number) / convFactor;
                                            // dataset_yield.label contains the year, e.g. Yield 2022
                                            // roast_yield_label = dataset_yield.label;
                                        }
                                    }

                                    if (dataset_amount?.data && dataset_amount?.label) {
                                        roast_amount = roast_yield + ((dataset_amount.data[index] as number) / convFactor);
                                        // roast_amount_label = dataset_amount.label;
                                    }

                                    if (roast_amount <= 0) {
                                        onlyLastYear = true;
                                    } else {
                                        const roast_amount_formated = this.utils.formatAmountForPipe(roast_amount, undefined, this.currentUser.unit_system);
                                        const roast_amount_formated_full = this.decimalPipe.transform(roast_amount_formated.value, '1.0-1') + roast_amount_formated.post;

                                        if (dataset_amount?.['batches']?.[index]) {
                                            str.push(`${this.tr.anslate('batches')}: ${dataset_amount['batches'][index]}`);
                                        }

                                        let amount_line = `${roast_amount_label}: ${roast_amount_formated_full}`;

                                        let roast_yield_last = 0;
                                        let roast_amount_last = 0;

                                        if (this.data.last_yield_idx > -1) {
                                            const dataset_yield_last = this.data.datasets[this.data.last_yield_idx];
                                            if (dataset_yield_last?.data) {
                                                roast_yield_last = (dataset_yield_last.data[index] as number) / convFactor;
                                            }
                                        }
                                        if (this.data.last_amount_idx > -1) {
                                            const dataset_amount_last = this.data.datasets[this.data.last_amount_idx];
                                            if (dataset_amount_last?.data) {
                                                roast_amount_last = roast_yield_last + ((dataset_amount_last.data[index] as number) / convFactor);
                                            }
                                        }
                                        if (roast_amount_last > 0) {
                                            const roast_amount_last_formated = this.utils.formatAmountForPipe(roast_amount_last, undefined, this.currentUser.unit_system);
                                            const roast_amount_last_formated_full = this.decimalPipe.transform(roast_amount_last_formated.value, '1.0-1') + roast_amount_last_formated.post;
                                            amount_line += ` (${roast_amount_last_formated_full})`;
                                        }

                                        str.push(amount_line);
                                        if (roast_yield > 0) {
                                            const roast_yield_formated = this.utils.formatAmountForPipe(roast_yield, undefined, this.currentUser.unit_system);
                                            const roast_yield_formated_full = this.decimalPipe.transform(roast_yield_formated.value, '1.0-1') + roast_yield_formated.post;
                                            let yield_line = `${roast_yield_label}: ${roast_yield_formated_full}`;
                                            if (roast_yield_last > 0) {
                                                const roast_yield_last_formated = this.utils.formatAmountForPipe(roast_yield_last, undefined, this.currentUser.unit_system);
                                                const roast_yield_last_formated_full = this.decimalPipe.transform(roast_yield_last_formated.value, '1.0-1') + roast_yield_last_formated.post;
                                                yield_line += ` (${roast_yield_last_formated_full})`;
                                            }
                                            str.push(yield_line);
                                            const loss = Math.round((roast_amount - roast_yield) / roast_amount * 1000) / 10;
                                            str.push(this.tr.anslate('loss') + ': ' + loss + '%');
                                        }
                                    }
                                }
                                if (onlyLastYear) {
                                    // there is no amount this year yet, we only present last years data if available

                                    const last_dataset_amount = this.data.datasets[this.data.last_amount_idx];

                                    let last_roast_amount = 0;
                                    // let last_roast_amount_label = '';
                                    let last_roast_yield = 0;
                                    // let last_roast_yield_label = '';

                                    if (this.data.last_yield_idx > -1) {
                                        const last_dataset_yield = this.data.datasets[this.data.last_yield_idx];
                                        if (last_dataset_yield?.data && last_dataset_yield?.label) {
                                            last_roast_yield = (last_dataset_yield.data[index] as number) / convFactor;
                                            // last_roast_yield_label = last_dataset_yield.label;
                                        }
                                    }

                                    if (last_dataset_amount?.data && last_dataset_amount?.label) {
                                        last_roast_amount = last_roast_yield + ((last_dataset_amount.data[index] as number) / convFactor);
                                        // last_roast_amount_label = last_dataset_amount.label;
                                    }

                                    if (last_roast_amount > 0) {
                                        const last_roast_amount_formated = this.utils.formatAmountForPipe(last_roast_amount, undefined, this.currentUser.unit_system);
                                        const last_roast_amount_formated_full = this.decimalPipe.transform(last_roast_amount_formated.value, '1.0-1') + last_roast_amount_formated.post;

                                        const last_amount_line = `${roast_amount_label}: ${last_roast_amount_formated_full}`;
                                        str.push(last_amount_line);


                                        if (last_roast_yield > 0) {
                                            const last_roast_yield_formated = this.utils.formatAmountForPipe(last_roast_yield, undefined, this.currentUser.unit_system);
                                            const last_roast_yield_formated_full = this.decimalPipe.transform(last_roast_yield_formated.value, '1.0-1') + last_roast_yield_formated.post;
                                            const last_yield_line = `${roast_yield_label}: ${last_roast_yield_formated_full}`;
                                            str.push(last_yield_line);
                                            const loss = Math.round((last_roast_amount - last_roast_yield) / last_roast_amount * 1000) / 10;
                                            str.push(this.tr.anslate('loss') + ': ' + loss + '%');
                                        }
                                    }
                                }
                            }
                            return str;
                        }
                    },
                    // TODO check
                    label: () => {
                        return '';
                    },
                }
            },
        },
        hover: {
            mode: 'index' as const,
            intersect: false,
        },
        layout: {
            padding: {
                left: 5,
                right: 30,
                top: 20,
                bottom: 20
            }
        },
        elements: {
            point: {
                radius: 0,
                hitRadius: 4,
                hoverRadius: 4
            }
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    drawOnChartArea: true,
                    display: true,
                    // offsetGridLines: true,
                    color: 'rgba(0, 0, 0, 0.1)',
                    // zeroLineColor: '#999'
                },
                display: true,
                title: {
                    display: false,
                },
                max: DateTime.now().endOf('year').toMillis(),
                ticks: {
                    autoSkip: true,
                    callback: (value: string | number, index: number) => {
                        try {
                            return Info.months(this._isRatherSmall ? 'short' : 'long', { locale: this.locale })[index];
                            // const d = new Date(2000, index, 1);
                            // return DateTime.fromJSDate(d).toFormat(this._isRatherSmall ? 'MMM' : 'MMMM');
                        } catch (error) {
                            return `${value}`;
                        }
                    },
                }
            },
            y: {
                stacked: true,
                display: true,
                title: {
                    display: false
                },
                suggestedMin: 0,
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 5,
                    callback: (value: number | string) => {
                        if (this._small && this.tickUnit !== 't') {
                            return `${value}`;
                        } else {
                            return `${value}${this.tickUnit}`;
                        }
                    }
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                    // zeroLineColor: '#999'
                },
            },
        },
        responsive: true,
        aspectRatio: 1.2,
        maintainAspectRatio: false,
    };

    @Input() set newData(nd: any) {
        this.setNewData(nd);
    }


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }

        this.isRatherSmall$ = this.breakpointObserver.observe('(max-width: 999px)')
            .pipe(map(result => result.matches));

        // this.options.title.text = this.tr.anslate('Monthly Production');

        this.inited = true;
        // mainUnit @Input is set after the options object is initializedx
        if (this.updData) {
            this.setNewData(this.updData);
            this.updData = undefined;
        }
        this.isRatherSmall$.subscribe(val => this._isRatherSmall = val);
    }

    setNewData(nd_in: any) {
        // we need to make a copy before we destructively change the data to allow other charts to access the unmodified data
        const nd = cloneDeep(nd_in);
        this.haveData = false;

        if (nd?.data && nd.labels && nd.coffees && nd.additionalData?.length > 3 &&
            nd.additionalData[0]?.dates && nd.additionalData[1]?.data && nd.additionalData[2]?.data && nd.additionalData[3].data &&
            nd.labels.length === nd.data.length && nd.labels.length === nd.coffees.length && nd.labels.length === nd.additionalData[3].data.length &&
            (nd.data.length > 0 || nd.additionalData[0].dates.length > 0) &&
            nd.additionalData[0].dates.length === nd.additionalData[1].data.length &&
            nd.additionalData[0].dates.length === nd.additionalData[2].data.length) {

            this.thisYear = nd.labels.length ? (DateTime.fromISO(nd.labels[nd.labels.length - 1]).year || DateTime.now().year) : DateTime.now().year;

            // 0. prepare data arrays

            let this_year_amounts = new Array(12).fill(0);
            let this_year_yields = new Array(12).fill(0);
            const this_year_batches = new Array(12).fill(0);
            let last_year_amounts = new Array(12).fill(0);
            let last_year_yields = new Array(12).fill(0);

            // fill this years data
            for (let i = 0; i < nd.labels.length; i++) {
                const d = DateTime.fromISO(nd.labels[i]);
                const n = d.month - 1;
                if (nd.data[i] > nd.coffees[i]) {
                    this_year_amounts[n] += nd.data[i] - nd.coffees[i]; // just the amounts in addition to the yields
                    this_year_yields[n] += nd.coffees[i];
                } else { // yield must be fake, we ignore it and just use the real amount and set the yield to 0 (not displayed)
                    this_year_amounts[n] += nd.data[i];
                    this_year_yields[n] += 0;
                }
                this_year_batches[n] += nd.additionalData[3].data[i];
            }

            // fill last years data
            for (let i = 0; i < nd.additionalData[0].dates.length; i++) {
                const d = DateTime.fromISO(nd.additionalData[0].dates[i]);
                const n = d.month - 1;
                last_year_amounts[n] += nd.additionalData[1].data[i] - nd.additionalData[2].data[i]; // just the amounts in addition to the yields
                last_year_yields[n] += nd.additionalData[2].data[i];
            }

            // 1. convert the weight data if needed
            if (this.mainUnit === 'lbs' || this.mainUnit === 'lb') {
                const convFactor = this.utils.getUnitFactor(this.mainUnit);
                this_year_amounts = this_year_amounts.map(x => x * convFactor); // amounts per month
                this_year_yields = this_year_yields.map(x => x * convFactor); // yields per month
                last_year_amounts = last_year_amounts.map(x => x * convFactor); // amounts per month (last year)
                last_year_yields = last_year_yields.map(x => x * convFactor); // yields per month (last year)
                let this_year_max = 0;
                let last_year_max = 0;
                for (let i = 0; i < this_year_amounts.length; i++) {
                    this_year_max = Math.max(this_year_max, this_year_amounts[i] + (this_year_yields[i] || 0));
                }
                for (let i = 0; i < last_year_amounts.length; i++) {
                    last_year_max = Math.max(last_year_max, last_year_amounts[i] + (last_year_yields[i] || 0));
                }
                if (Math.max(this_year_max, last_year_max) > 4000) {
                    this.weight_factor = 2000;
                    this.tickUnit = 't';
                    this_year_amounts = this_year_amounts.map(x => x / this.weight_factor); // amounts per month
                    this_year_yields = this_year_yields.map(x => x / this.weight_factor); // yields per month
                    last_year_amounts = last_year_amounts.map(x => x / this.weight_factor); // amounts per month (last year)
                    last_year_yields = last_year_yields.map(x => x / this.weight_factor); // yields per month (last year)
                } else {
                    this.weight_factor = 1;
                    this.tickUnit = this.mainUnit;
                }
            } else {
                let this_year_max = 0;
                let last_year_max = 0;
                for (let i = 0; i < this_year_amounts.length; i++) {
                    this_year_max = Math.max(this_year_max, this_year_amounts[i] + (this_year_yields[i] || 0));
                }
                for (let i = 0; i < last_year_amounts.length; i++) {
                    last_year_max = Math.max(last_year_max, last_year_amounts[i] || 0 + (last_year_yields[i] || 0));
                }
                if (Math.max(this_year_max, last_year_max) > 2000) {
                    this.weight_factor = 1000;
                    this.tickUnit = 't';
                    this_year_amounts = this_year_amounts.map(x => x / this.weight_factor); // amounts per month
                    this_year_yields = this_year_yields.map(x => x / this.weight_factor); // yields per month
                    last_year_amounts = last_year_amounts.map(x => x / this.weight_factor); // amounts per month (last year)
                    last_year_yields = last_year_yields.map(x => x / this.weight_factor); // yields per month (last year)
                } else {
                    this.weight_factor = 1;
                    this.tickUnit = this.mainUnit;
                }
            }


            const this_year_total_amount = this_year_amounts.reduce((a, b) => a + b, 0);
            const this_year_total_yield = this_year_yields.reduce((a, b) => a + b, 0);
            const last_year_total_amount = last_year_amounts.reduce((a, b) => a + b, 0);
            const last_year_total_yield = last_year_yields.reduce((a, b) => a + b, 0);

            // 2. create datasets
            if ((this_year_total_amount > 0) || (last_year_total_amount > 0)) {

                this.haveData = true;
                this.data = { labels: new Array(12).fill(0), datasets: [] }
            };

            this.data.yield_idx = -1; // set to the datasetIndex of current years yield dataset if it exists or -1
            this.data.amount_idx = -1; // set to the datasetIndex of current years yield dataset if it exists or -1
            this.data.last_yield_idx = -1; // set to the datasetIndex of last years yield dataset if it exists or -1
            this.data.last_amount_idx = -1; // set to the datasetIndex of last years yield dataset if it exists or -1

            const amountLabel = this.tr.anslate('amount');
            const yieldLabel = this.tr.anslate('yield');

            if (this_year_total_yield > 0) {
                this.data.yield_idx = this.data.datasets.length;
                this.data.datasets.push({
                    stack: '0',
                    order: 2,
                    label: this.thisYear === DateTime.now().year ? yieldLabel : `${yieldLabel} ${this.thisYear}`,
                    borderWidth: 1,
                    backgroundColor: this.isDarkmode ? '#144566DD' : '#0c6aa666', // dark: light: P900/66
                    borderColor: this.isDarkmode ? '#1e90c1CC' : '#0c6aa6DD',
                    hoverBackgroundColor: this.isDarkmode ? '#1985baAA' : '#0c6aa699', // dark: P700/AA, light: P900/99
                    hoverBorderColor: this.isDarkmode ? '#1985ba' : '#0c6aa6', // dark: P700/DD, light: P900
                    data: this_year_yields
                });
            }
            if (this_year_total_amount > 0) {
                this.data.amount_idx = this.data.datasets.length;
                this.data.datasets.push({
                    stack: '0',
                    order: 3,
                    label: this.thisYear === DateTime.now().year ? amountLabel : `${amountLabel} ${this.thisYear}`,
                    borderWidth: 1,
                    backgroundColor: this.isDarkmode ? '#43a7cf66' : '#147bb344', // dark: P400/66, light: P800/44
                    borderColor: this.isDarkmode ? '#43a7cfBB' : '#147bb3AA', // dark: P400/BB, light: P800/AA
                    hoverBackgroundColor: this.isDarkmode ? '#43a7cfBB' : '#147bb377', // dark:P400/BB , leight: P800/77
                    hoverBorderColor: this.isDarkmode ? '#43a7cf' : '#147bb3DD', // dark:P400 , leight: P800/DD
                    data: this_year_amounts,
                });
                this.data.datasets[this.data.datasets.length - 1]['batches'] = this_year_batches;
            }

            if (last_year_total_amount > 0) {
                if (last_year_total_yield > 0) {
                    this.data.last_yield_idx = this.data.datasets.length;
                    this.data.datasets.push({
                        stack: '1',
                        order: 0,
                        label: `${yieldLabel} ${this.thisYear - 1}`,
                        borderWidth: 1,
                        borderColor: this.isDarkmode ? '#9e9e9e77' : '#bdbdbd77', // dark: G200/66, light: G400/55
                        backgroundColor: this.isDarkmode ? '#9e9e9e77' : '#bdbdbd77', // dark: G700/AA, light: G400/55
                        hoverBackgroundColor: this.isDarkmode ? '#9e9e9e77' : '#bdbdbd77', // dark:P400/BB , leight: P800/77
                        data: last_year_yields
                    });
                }
                this.data.last_amount_idx = this.data.datasets.length;
                this.data.datasets.push({
                    stack: '1',
                    order: 1,
                    label: `${amountLabel} ${this.thisYear - 1}`,
                    borderWidth: 0,
                    borderColor: this.isDarkmode ? '#fafafa60' : '#e0e0e088',
                    backgroundColor: this.isDarkmode ? '#fafafa60' : '#e0e0e088', // dark: G50/, light: G300/77
                    hoverBackgroundColor: this.isDarkmode ? '#fafafa60' : '#e0e0e088', // dark:P400/BB , leight: P800/77
                    data: last_year_amounts
                });
            }

            if (this.isDarkmode) {
                this.options.scales.x.grid.color = 'rgba(255, 255, 255, 0.2)';
                // this.options.scales.x.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
                this.options.scales.y.grid.color = 'rgba(255, 255, 255, 0.2)';
                // this.options.scales.y.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
                this.options.plugins.tooltip.titleColor = '#212121DD'; // G900/BB
                this.options.plugins.tooltip.bodyColor = '#212121DD'; // G900/BB
                this.options.plugins.tooltip.backgroundColor = '#eeeeeeDD'; // G200/DD => '#eeeeee'
            } else {
                this.options.scales.x.grid.color = 'rgba(0, 0, 0, 0.1)';
                // this.options.scales.x.grid.zeroLineColor = '#999';
                this.options.scales.y.grid.color = 'rgba(0, 0, 0, 0.1)';
                // this.options.scales.y.grid.zeroLineColor = '#999';
                this.options.plugins.tooltip.titleColor = '#fff';
                this.options.plugins.tooltip.bodyColor = '#fff';
                this.options.plugins.tooltip.backgroundColor = 'rgba(66,66,66,0.8)'; // G800, '#424242'
            }
        }
        this.chart?.update();
    }
}
