import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { FilterOptions } from './services/standard.service';
import { DateTime } from 'luxon';
// import { DebouncedFunc } from 'lodash-es';

@Injectable({
    providedIn: 'root'
})
export class Utils2 {

    constructor(
        private logger: NGXLogger,
        public tr: TranslatorService,
    ) { }

    /**
     * Normally not used directly, use cleanResult instead.
     * Removes all "empty" properties from the given array of objects using cleanResult recursively.
     * @param {T} obj the object to clean
     * @param {number} depth the current recursion depth just to avoid an "infinite loop"
     */
    private cleanOneObject<T>(obj: T, depth: number) {
        if (!obj) {
            return;
        }
        const props = Object.getOwnPropertyNames(obj);
        for (let p = 0; p < props.length; p++) {
            const prop = obj[props[p]];
            if (prop == null
                // empty array:
                || prop.length === 0
                // empty object {}:
                || (Object.keys(prop).length === 0 && prop.constructor === Object)
                // array with only one empty object [{}]:
                || (prop.length === 1 && (prop[0] && Object.keys(prop[0]).length === 0 && prop[0].constructor === Object))
                || (DateTime.isDateTime(prop) && !prop.isValid)) {
                // logger.debug('remove property %s as its value is %s', props[p], JSON.stringify(obj[props[p]]));
                delete obj[props[p]];
            } else if (props[p] !== '_id' && typeof prop === 'object' && !(DateTime.isDateTime(prop))) {
                // recurse for all object properties
                obj[props[p]] = this.cleanResult(prop, depth + 1);
                // remove null, undefined, [], {}
                if (obj[props[p]] == null || obj[props[p]].length === 0 || (obj[props[p]] && Object.keys(obj[props[p]]).length === 0 && obj[props[p]].constructor === Object)) {
                    delete obj[props[p]];
                }
            }
        }
    }

    // cleanOneObject<T>(obj: T, depth = 0): T {
    //     return this.traverseObjects(obj);
    // }
    // cleanArray<T>(objs: T[], depth = 0): T[] {
    //     return this.traverseObjects(objs);
    // }

    /**
     * Removes all "empty" properties from the given array of objects using cleanObject recursively.
     * Directly changes the objects and returns them.
     * @param {T} objs one object or an array of objects to clean
     * @param {number} depth the current recursion depth just to avoid an "infinite loop" (max 10 recursions)
     */
    cleanResult<T>(objs: T, depth: number = 0): T {
        try {
            if (depth > 10) {
                this.logger.warn('too many recursions in cleanResult');
                return objs;
            }

            if (!objs) {
                return undefined;
            }
            if (objs['length']) {
                for (let o = 0; o < objs['length']; o++) {
                    // never send refs; just used on the client, generated on the fly on the server
                    if (objs[o]) {
                        delete objs[o]['refs'];
                        if (!objs[o].default_unit?.name) {
                            // specifically remove default default_unit === { name: Enumerations.CoffeeUnits._NONE, size: 1 }
                            delete objs[o].default_unit;
                        }
                        this.cleanOneObject(objs[o], depth);
                    }
                }
            } else {
                // never send refs; just used on the client, generated on the fly on the server
                delete objs['refs'];
                if (!objs['default_unit']?.name) {
                    delete objs['default_unit'];
                }
                this.cleanOneObject(objs, depth);
            }
            return objs;
        } catch (err) {
            // ignore for now
        }
    }

    /**
     * 
     * @param filter filter to remove "non-filter" attributes
     */
    cleanLoadedFilter(filter: FilterOptions) {
        // not needed but potentially added through DB roundtrip
        delete filter.filtername;
        delete filter.filtertype;
        delete filter['owner'];
        delete filter['created_by'];
        delete filter['updated_by'];
        delete filter['created_at'];
        delete filter['updated_at'];
        this.cleanResult(filter);
    }
}
