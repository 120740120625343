// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('material-symbols-outlined.bcc1c3244e759cda.woff2') format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate(0, -100%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
.fadeInDown {
  /* Safari 4+ */
  /* Fx 5+ */
  /* Opera 12+ */
  animation: fadeInDown 1s ease 0.3s 1 normal both;
  /* IE 10+, Fx 29+ */
  animation-name: fadeInDown;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    color: white;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    color: white;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse2 {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fadeInDownAlert {
  from {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
.fadeInDownAlert {
  /* Safari 4+ */
  /* Fx 5+ */
  /* Opera 12+ */
  animation: fadeInDownAlert 0.5s ease 0.2s 1 normal both;
  /* IE 10+, Fx 29+ */
  animation-name: fadeInDownAlert;
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
}
.fadeOutUp {
  animation: fadeOutUp 1s ease 0.3s 1 normal both;
  animation-name: fadeOutUp;
}

body.inheritCursors * {
  cursor: inherit !important;
}

html {
  --mat-toolbar-standard-height: 45px;
  --mat-toolbar-mobile-height: 37px;
  --mat-form-field-container-height: 40px;
  --mat-select-trigger-text-size: 14px;
}

/* BEGIN new for Angular 16+ */
th.mat-mdc-header-cell:first-of-type,
td.mat-mdc-cell:first-of-type,
td.mat-mdc-footer-cell:first-of-type {
  padding-left: 24px;
}

.select-with-logo .mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
}

.mdc-list-item--with-leading-icon.mdc-list-item {
  padding-left: 4px;
  padding-right: 8px;
}

mat-form-field.form-group.mat-mdc-form-field:not(.no-bottom-margin) {
  margin-bottom: 5px;
}

.mdc-text-field--no-label .mat-mdc-form-field-flex .mat-mdc-form-field-infix,
.mat-mdc-form-field .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  padding-top: 15px;
  padding-bottom: 0;
}

.blends-formfield-thin.mat-mdc-form-field .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  padding-top: 0;
}

.mat-mdc-form-field.select-with-logo .mat-mdc-form-field-infix {
  height: inherit;
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  font-size: 14px;
}

.mat-mdc-form-field .mdc-text-field.mat-mdc-text-field-wrapper {
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 2px;
}

mat-form-field.mat-mdc-form-field,
.mat-mdc-form-field .mat-mdc-select-trigger,
.mat-mdc-form-field .mat-mdc-select-placeholder {
  font-size: 14px;
}

.scheduler-planner-narrow-formfield .mat-mdc-select-placeholder {
  font-size: 12px;
  font-style: italic;
}

.mat-mdc-form-field .mdc-text-field.mdc-text-field--filled {
  align-items: flex-start;
}
.mat-mdc-form-field .mdc-text-field.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mat-mdc-form-field .mdc-text-field.mdc-text-field--filled.mdc-text-field--disabled {
  background-color: transparent;
}

/* allow narrower input fields, e.g. for filters etc. */
.mdc-button.add-button.action-button {
  margin-top: 0;
}

/* narrower paginator */
.mat-mdc-paginator .mat-mdc-paginator-container {
  min-height: 25px;
  padding-top: 5px;
}

.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding: 0;
  min-height: 0;
  height: 24px;
}

.mat-mdc-paginator .mat-mdc-paginator-page-size-select {
  width: 60px;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper {
  padding-left: 5px;
  padding-right: 5px;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper .mdc-line-ripple {
  display: none;
}

.mat-mdc-icon-button.mat-mdc-button-base.mat-mdc-paginator-navigation-previous,
.mat-mdc-icon-button.mat-mdc-button-base.mat-mdc-paginator-navigation-next {
  padding: 0;
  height: 24px;
}

/* flag dropdown */
.mat-mdc-form-field.language-selector .mat-mdc-text-field-wrapper.mdc-text-field {
  padding-right: 0;
  padding-left: 0;
}

.mat-mdc-form-field.language-selector .mat-mdc-option {
  padding-left: 3px;
  padding-right: 3px;
}

.mat-mdc-form-field.language-selector .mat-mdc-select-value {
  max-width: inherit;
}

.mat-mdc-form-field.language-selector div.mdc-text-field.mdc-text-field--filled div.mat-mdc-form-field-infix {
  border: 0;
  padding: 0;
  min-height: 0;
}

.mat-mdc-form-field.language-selector .mat-mdc-select {
  text-align: center;
}

.mat-mdc-form-field.language-selector {
  width: 51px;
  margin-right: 10px;
  max-height: inherit;
  display: block;
  margin-bottom: 0;
}

.flag-size {
  height: 14px;
  margin-top: -4px;
}

.cdk-overlay-container .cdk-overlay-pane .mat-mdc-select-panel.language-flag-select {
  max-height: initial;
}

.cdk-overlay-container .cdk-overlay-pane .language-flag-select .mat-mdc-option.mdc-list-item {
  padding-left: 10px;
  padding-right: 10px;
}

.cdk-overlay-container .cdk-overlay-pane .language-flag-select .mat-pseudo-checkbox,
.mat-mdc-form-field.language-selector .mat-mdc-select-arrow-wrapper,
.mat-mdc-form-field.language-selector .mdc-line-ripple {
  display: none;
}

/* account change dropdown */
.account-div {
  text-align: center;
  position: relative;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 3px;
}

.mat-mdc-form-field.account-selector .mat-mdc-text-field-wrapper.mdc-text-field {
  padding-left: 5px;
  padding-right: 5px;
}

.cdk-overlay-container .cdk-overlay-pane .account-selector .mat-pseudo-checkbox,
.mat-mdc-form-field.account-selector .mdc-line-ripple {
  display: none;
}

.mat-mdc-form-field.account-selector .mat-mdc-select-value {
  max-width: inherit;
}

.mat-mdc-form-field.account-selector div.mdc-text-field.mdc-text-field--filled div.mat-mdc-form-field-infix {
  padding: 5px 0 0 0;
}

.account-selector mat-select-trigger {
  margin-right: 5px;
}

.account-selector .mat-mdc-select {
  text-align: right;
  vertical-align: middle;
}

.mat-mdc-select-panel.show-all-options .mat-mdc-option .mdc-list-item__primary-text {
  margin-right: 0;
  width: 100%;
}

.mat-mdc-select-panel.show-all-options {
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content;
}

.mat-mdc-form-field.datesize .mat-mdc-form-field-infix {
  padding-top: 16px;
}

/* END new for Angular 16 */
.mdc-tooltip--multiline {
  white-space: pre-wrap;
}

/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(""), url('roboto-v29-latin-300.0dfc8c1e31826e6b.woff2') format("woff2"), url('roboto-v29-latin-300.c5e7b25aef3f051c.woff') format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"), url('roboto-v29-latin-regular.7b8d7718ba08bc7d.woff2') format("woff2"), url('roboto-v29-latin-regular.a69044dd3989ddbd.woff') format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(""), url('roboto-v29-latin-500.f7e6f1dc1466c7e1.woff2') format("woff2"), url('roboto-v29-latin-500.2c985b6613b2c639.woff') format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
html,
body {
  height: 100%;
  margin: 0;
}

.sidenav-container {
  /* height: calc(100% - 64px); */
  height: 100%;
}

a:not(:hover) {
  text-decoration: none;
}

.enlargepage {
  min-height: 250px;
  flex: 1;
}

.enlargepage-smaller {
  min-height: 100px;
  flex: 1;
}

.date-input-narrow,
.date-hint-narrow {
  max-width: 124px;
  width: 114px;
}

.date-hint-narrow {
  text-align: right;
  margin-left: 10px;
  padding-right: 15px;
}

.ap-popuplike {
  position: absolute;
  width: 300px;
  margin-left: -150px;
  left: 50%;
  margin-top: 2cm;
  margin-bottom: 2cm;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.25);
}

.spacearound {
  padding-left: 5px;
  padding-right: 5px;
}

.spaceright {
  padding-right: 5px;
}

.spaceleft {
  padding-left: 5px;
}

.right-align {
  text-align: right;
}

.right-aligned-header > .mat-content {
  justify-content: space-between;
}

.main-note {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 40px;
}

/* .mat-content > mat-panel-title, .mat-content > mat-panel-description {
  flex: 0 0 auto;
} */
/* replace stepper 1 2 3 icons with location coffee done */
.initwizard mat-step-header .mat-step-label {
  overflow: visible;
}

.initwizard mat-step-header .mat-step-icon-not-touched span,
.initwizard mat-step-header .mat-step-icon span,
.initwizard mat-step-header .mat-step-icon-not-touched .mat-icon,
.initwizard mat-step-header .mat-step-icon .mat-icon {
  display: none;
}

.initwizard div.mat-step:nth-of-type(1) mat-step-header .mat-step-icon-not-touched:after,
.initwizard div.mat-step:nth-of-type(1) mat-step-header .mat-step-icon:after {
  content: "place" !important;
  font-family: "Material Icons" !important;
  font-feature-settings: "liga" 1;
}

.initwizard div.mat-step:nth-of-type(2) mat-step-header .mat-step-icon-not-touched:after,
.initwizard div.mat-step:nth-of-type(2) mat-step-header .mat-step-icon:after {
  content: "local_cafe" !important;
  font-family: "Material Icons" !important;
  font-feature-settings: "liga" 1;
}

.initwizard div.mat-step:nth-of-type(3) mat-step-header .mat-step-icon-not-touched:after,
.initwizard div.mat-step:nth-of-type(3) mat-step-header .mat-step-icon:after {
  content: "done" !important;
  font-family: "Material Icons" !important;
  font-feature-settings: "liga" 1;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-wrap {
  display: flex;
}

.twoLineOption {
  line-height: 1.3em;
}

.twoline-select-with-icon .mat-mdc-option .mdc-list-item__primary-text {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actionbar {
  width: 100%;
  margin-top: 10px;
  padding: 5px 0;
  text-align: center;
}

.action-button,
.action-button.mat-mdc-raised-button,
.action-button.mat-mdc-raised-button:not(:disabled) {
  margin: 0 5px 5px 5px;
  color: white;
  max-height: 36px;
  letter-spacing: normal;
}

.action-button.mat-mdc-raised-button .mat-icon,
.edit-button.mat-mdc-raised-button .mat-icon {
  vertical-align: middle;
  margin: 0;
  font-size: 24px;
  width: 24px;
  height: 24px;
}

.edit-button,
.edit-button.mat-mdc-raised-button,
.edit-button.mat-mdc-raised-button:not([disabled]) {
  margin: 0 5px 5px 5px;
  color: white;
  letter-spacing: normal;
}

.clone-button,
.clone-button.mat-mdc-raised-button {
  margin-right: 15px;
}

.overview {
  width: 100%;
  margin-top: 10px;
  margin-right: 10px;
  padding: 5px;
}

.sortbar {
  margin-bottom: 8px;
  margin-left: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.sortbar-inner {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: auto;
}

.sortbar-inner .mat-button-toggle-button .mat-button-toggle-label-content {
  line-height: 24px;
  font-size: 14px;
}

.mat-step-icon,
.mat-step-icon-not-touched {
  text-align: center;
}

.centered {
  margin-left: auto;
  margin-right: auto;
}

.currency-input {
  border: none;
  border-bottom: #949494 solid 1px;
}

.mat-step-icon {
  padding-top: 2px;
}

.wait-outer {
  width: 100%;
}

.spinner {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

.mat-mdc-progress-spinner.white-spinner circle {
  stroke: #ffffff;
}

.mat-mdc-form-field {
  width: 100%;
}

.notesdiv.mat-mdc-form-field {
  top: 15px;
}

#dropZone {
  height: 100%;
  background-color: rgba(240, 248, 255, 0.568627451);
  width: 100%;
}

#dropZone .content {
  height: 100%;
  font-weight: bold;
  font-size: x-large;
}

.comment {
  margin-top: -15px;
  text-align: start;
  font-size: 12px;
  margin-bottom: 15px;
}

.comment-ble {
  text-align: end;
  margin-top: -28px;
  margin-bottom: 0;
}

tr.report-element-row .mat-expansion-panel {
  background: #f5f5f5;
}

.littlespaceleft {
  margin-left: 3px;
}

.littlespaceright {
  margin-right: 3px;
}

.smallfont {
  font-size: 85%;
  margin-left: 1em;
  text-indent: -1em;
  letter-spacing: normal;
}

.secondLineIndent {
  margin-left: 2em;
}

.smallicon {
  cursor: pointer;
  margin-left: 0;
  vertical-align: top;
  font-size: 16px;
  line-height: 16px;
  height: 16px;
  width: 16px;
  text-indent: 0;
}

.smallicon-second {
  margin-left: 0;
}

.small-close-icon {
  height: 14px;
  width: 22px;
  line-height: 14px;
  font-size: 14px;
  cursor: pointer;
}

.smallish-close-icon {
  height: 16px;
  width: 22px;
  line-height: 16px;
  font-size: 16px;
  cursor: pointer;
}

.overview-close-icon {
  height: 18px;
  width: 18px;
  line-height: 18px;
  font-size: 18px;
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.text-centered {
  text-align: center;
}

app-purchase-dialog,
app-sell-dialog,
app-transfer-dialog,
app-correct-dialog {
  display: block;
  width: 296px;
}

.amount-input {
  width: 70px;
  text-align: right;
}

.mat-mdc-form-field.amount-input-ff1 {
  max-width: 166px;
  text-align: right;
  padding-right: 10px;
}

.mat-mdc-form-field.amount-input-ff2 {
  max-width: 130px;
  text-align: right;
}

.amount-input-ff1 .amount-input,
.amount-input-ff2 .amount-input {
  margin-right: 3px;
}

.mat-mdc-form-field.amount-input-ff1 .mat-mdc-form-field-label,
.mat-mdc-form-field.amount-input-ff2 .mat-mdc-form-field-label {
  text-align: left;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field.amount-input-ff2 .mat-mdc-form-field-label {
  text-overflow: unset;
  overflow: visible;
}

.mat-mdc-form-field.date-input-ff1 {
  max-width: 130px;
  padding-right: 10px;
}

.mat-mdc-form-field.date-input-ff2 {
  max-width: 130px;
  text-align: right;
}

.date-range-flex-div {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  align-items: center;
  flex-wrap: wrap;
}

.mat-datepicker-toggle .mat-mdc-icon-button {
  height: 24px;
  width: 24px;
  padding: 0;
}

.nobreak {
  white-space: nowrap;
}

.ellipsed {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

div.top-space .text-container {
  margin-top: 110px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.wrap-checkbox-label label.mat-checkbox-layout {
  white-space: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.cursor-copy {
  cursor: copy;
}

.cursor-grab {
  cursor: -webkit-grab;
  cursor: grab;
}

.cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.snack-right {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin-left: auto !important;
  margin-top: 50px !important;
  margin-right: 80px !important;
}

.snack-right .mat-mdc-simple-snack-bar {
  justify-content: center;
}

app-update-snack {
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
}

.otherside-button {
  margin-right: auto;
}

.smallesticon {
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  width: 14px;
  text-indent: 0;
}

.upload-link {
  text-align: right;
  padding-top: 10px;
  font-size: 75%;
}

app-coffee .upload-link,
app-blend .upload-link,
app-roast .upload-link {
  text-align: left;
}

.mat-mdc-card-avatar,
.contact-avatar {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-icon {
  width: 50px;
  height: 50px;
}

.avatar-icon-generic {
  width: 32px;
  height: 32px;
}

.readonly-spacer {
  height: 15px;
}

.mat-icon.helpicon {
  height: 16px;
  width: 16px;
  font-size: 16px;
  vertical-align: text-bottom;
}

.sort-indicator {
  z-index: 1;
}

.sort-indicator .mat-icon {
  font-size: 15px;
  line-height: 24px;
  margin-left: -24px;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.sortButton.mat-button-toggle-checked {
  padding-right: 15px;
}

.sortButton {
  border-radius: 4px;
}

.sortButtonGroup {
  flex-wrap: wrap;
  border: none;
  height: 24px;
}

.filter-input-div {
  float: right;
  margin-right: 10px;
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.filter-input-div .mat-mdc-form-field {
  width: 150px;
}

image-cropper.cropping {
  background: none;
}
image-cropper.cropping div.overlay,
image-cropper.cropping div.cropper {
  outline: none !important;
}

.mat-action-row.separate-buttons,
.inner-action-row.separate-buttons {
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-baseline {
  display: flex;
  align-items: baseline;
}

.flex-end {
  display: flex;
  align-items: flex-end;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.smallerfont {
  font-size: 10px;
  line-height: 12px;
}

.highlight-add-option {
  font-style: italic;
}

.hideme {
  animation: fadeOut ease 2s;
  -webkit-animation: fadeOut ease 2s;
  -moz-animation: fadeOut ease 2s;
  -o-animation: fadeOut ease 2s;
  -ms-animation: fadeOut ease 2s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.showme {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.helptip.helptip-organic {
  left: -154px;
  top: 22px;
}

.helptip {
  opacity: 0;
  position: absolute;
  z-index: 1000;
  left: -140px;
  width: 300px;
  margin-top: 17px;
  background: #177ab2;
  color: #eeeeee;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  text-align: center;
  border-radius: 10px;
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  white-space: pre-wrap;
  padding: 10px 10px 10px 10px;
}

.helptip:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #177ab2 transparent;
  border-width: 0 20px 20px;
  top: -20px;
  left: 50%;
  margin-left: -20px;
}

.helptip a {
  color: #eeeeee;
}

.mat-mdc-form-field.form-group.halfsizeleft {
  width: 44%;
  margin-right: 1%;
}

.mat-mdc-form-field.form-group.halfsizeright {
  width: 55%;
}

.halfsizeleft-withicon .mat-mdc-form-field.form-group {
  width: 75%;
  margin-right: 1%;
}

.halfsizeright-withicon .mat-mdc-form-field.form-group {
  width: 75%;
  margin-right: 1%;
}

.halfsizeright-withicon {
  text-align: end;
}

.mat-mdc-form-field.form-group.thirdsizeleft {
  width: 32%;
  margin-right: 1%;
}

.mat-mdc-form-field.form-group.default-unit-size-kg-middle {
  width: 50%;
  margin-right: 1%;
}

.mat-mdc-form-field.form-group.default-unit-size-lbs-right {
  width: 16%;
}

.mat-mdc-form-field.form-group.twothirdsize {
  width: 66%;
}

.button-spinner {
  margin: 0 auto;
}

.spinner-button {
  height: 36px;
  vertical-align: bottom;
}

app-select-roastreports .mat-ink-bar {
  width: 100px;
}

a.mat-mdc-tab-link,
a.mat-tab-link:hover,
a.mat-tab-link:focus {
  text-decoration: none;
}

.mat-expansion-panel-body {
  position: relative;
}

.organic-icon {
  fill: #27a734;
}

.hide-icon {
  font-size: 16px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  margin-left: 10px;
}

.custom-snack a {
  cursor: pointer;
}

.dropdown-image {
  height: 35px;
  width: 55px;
  margin-right: 15px;
  margin-left: 5px;
}

app-supplierpartners .mat-expansion-panel-header-title {
  flex-wrap: wrap;
}

.contact-card .mat-expansion-panel-body {
  padding-top: 16px;
}

.contact-card.mat-expansion-panel {
  display: inline-flex;
  vertical-align: top;
}

app-contacts .mat-expansion-panel-header {
  padding: 0 14px;
}

.dropdown-text-image {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 300px;
}

.dropdown-text-image-label {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.mat-icon.smallish {
  font-size: 17px;
}

.mat-icon.smallish2 {
  font-size: 20px;
}

h2.main-heading {
  margin: 20px 0 20px 5px;
  font-weight: 500;
  font-size: 30px;
  line-height: 33px;
  white-space: nowrap;
}

.left-align .mat-sort-header-content {
  text-align: left;
}

.origin-select {
  max-width: 130px;
  margin-left: 10px;
}

.narrow-select {
  max-width: 130px;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0;
}

.filter-hints {
  margin-right: 20px;
  font-size: 85%;
  text-align: right;
}

.filter-parts .wide-filter-input {
  display: block;
  width: 268px;
}

.left-margin {
  display: inline-block;
  margin-left: 10px;
  padding-right: 0;
}

.left-margin-only {
  margin-left: 10px;
}

.right-padding {
  padding-right: 10px;
}

.allowNull-container label {
  margin-bottom: 0;
}

.newroastreport .table-badge {
  left: 0 !important;
}

.table-badge {
  position: absolute;
  top: -15px;
  left: -10px;
  padding: 1px 7px 1px 7px;
  border-width: 3px;
  border-style: solid;
  border-radius: 14px;
}

.overview-copied {
  color: green;
}

.overview-copyFailed {
  color: darkred;
}

.scheduler-input-group .mat-mdc-chip-graphic {
  display: none;
}

.scheduler-input-group .mat-mdc-form-field-hint-wrapper {
  padding-left: 0;
  padding-right: 0;
}

.scheduler-select-option-with-stock .mdc-list-item__primary-text {
  width: 100%;
}

.scheduler-label-with-stock {
  display: flex;
  justify-content: space-between;
}

.scheduler-input-group.scheduler-users-group .checkbox-filter-with-value {
  margin-top: 4px;
}

.scheduler-input-group.scheduler-users-group .allowNull-label {
  margin-top: 6px;
}

.scheduler-input-group .checkbox-filter-main-div {
  width: unset;
}

.scheduler-input-flex-middle .mat-mdc-form-field-infix {
  width: unset;
}

.scheduler-planner-div-container.cdk-drop-list-dragging {
  cursor: move;
}

.scheduler-list-item.cdk-drag-preview {
  cursor: move;
}

.scheduler-planner-favorites-container .scheduler-list-custom-placeholder,
.scheduler-planner-favorites-container .scheduler-list-custom-placeholder2 {
  width: 255px !important;
  min-width: 255px !important;
  max-width: 255px !important;
  margin-right: 10px !important;
  margin-bottom: 0 !important;
}

.scheduler-planner-div-container .scheduler-list-custom-placeholder,
.scheduler-planner-div-container .scheduler-list-custom-placeholder2 {
  width: 345px !important;
  max-width: 345px !important;
}

.small-checkboxes .mdc-checkbox {
  padding: 0;
}

.small-checkboxes .mat-mdc-checkbox-touch-target {
  height: 18px;
  width: 18px;
}

.small-checkboxes .mdc-checkbox .mdc-checkbox__native-control {
  height: 18px;
  width: 18px;
  left: 0;
  top: 0;
}

.small-checkboxes .mat-mdc-checkbox .mdc-checkbox__background {
  left: 0;
  top: 0;
}

.hidden-datepicker-input {
  width: 0;
  padding: 0;
  visibility: hidden;
  margin: 0;
  border: 0;
}

.no-bottom-margin {
  margin-bottom: 0;
}

@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('material-symbols-outlined.bcc1c3244e759cda.woff2') format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate(0, -100%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
.fadeInDown {
  /* Safari 4+ */
  /* Fx 5+ */
  /* Opera 12+ */
  animation: fadeInDown 1s ease 0.3s 1 normal both;
  /* IE 10+, Fx 29+ */
  animation-name: fadeInDown;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    color: white;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    color: white;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse2 {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fadeInDownAlert {
  from {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
.fadeInDownAlert {
  /* Safari 4+ */
  /* Fx 5+ */
  /* Opera 12+ */
  animation: fadeInDownAlert 0.5s ease 0.2s 1 normal both;
  /* IE 10+, Fx 29+ */
  animation-name: fadeInDownAlert;
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
}
.fadeOutUp {
  animation: fadeOutUp 1s ease 0.3s 1 normal both;
  animation-name: fadeOutUp;
}

body.inheritCursors * {
  cursor: inherit !important;
}

html {
  --mat-toolbar-standard-height: 45px;
  --mat-toolbar-mobile-height: 37px;
  --mat-form-field-container-height: 40px;
  --mat-select-trigger-text-size: 14px;
}

/* BEGIN new for Angular 16+ */
th.mat-mdc-header-cell:first-of-type,
td.mat-mdc-cell:first-of-type,
td.mat-mdc-footer-cell:first-of-type {
  padding-left: 24px;
}

.select-with-logo .mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
}

.mdc-list-item--with-leading-icon.mdc-list-item {
  padding-left: 4px;
  padding-right: 8px;
}

mat-form-field.form-group.mat-mdc-form-field:not(.no-bottom-margin) {
  margin-bottom: 5px;
}

.mdc-text-field--no-label .mat-mdc-form-field-flex .mat-mdc-form-field-infix,
.mat-mdc-form-field .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  padding-top: 15px;
  padding-bottom: 0;
}

.blends-formfield-thin.mat-mdc-form-field .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  padding-top: 0;
}

.mat-mdc-form-field.select-with-logo .mat-mdc-form-field-infix {
  height: inherit;
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  font-size: 14px;
}

.mat-mdc-form-field .mdc-text-field.mat-mdc-text-field-wrapper {
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 2px;
}

mat-form-field.mat-mdc-form-field,
.mat-mdc-form-field .mat-mdc-select-trigger,
.mat-mdc-form-field .mat-mdc-select-placeholder {
  font-size: 14px;
}

.scheduler-planner-narrow-formfield .mat-mdc-select-placeholder {
  font-size: 12px;
  font-style: italic;
}

.mat-mdc-form-field .mdc-text-field.mdc-text-field--filled {
  align-items: flex-start;
}
.mat-mdc-form-field .mdc-text-field.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mat-mdc-form-field .mdc-text-field.mdc-text-field--filled.mdc-text-field--disabled {
  background-color: transparent;
}

/* allow narrower input fields, e.g. for filters etc. */
.mdc-button.add-button.action-button {
  margin-top: 0;
}

/* narrower paginator */
.mat-mdc-paginator .mat-mdc-paginator-container {
  min-height: 25px;
  padding-top: 5px;
}

.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding: 0;
  min-height: 0;
  height: 24px;
}

.mat-mdc-paginator .mat-mdc-paginator-page-size-select {
  width: 60px;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper {
  padding-left: 5px;
  padding-right: 5px;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper .mdc-line-ripple {
  display: none;
}

.mat-mdc-icon-button.mat-mdc-button-base.mat-mdc-paginator-navigation-previous,
.mat-mdc-icon-button.mat-mdc-button-base.mat-mdc-paginator-navigation-next {
  padding: 0;
  height: 24px;
}

/* flag dropdown */
.mat-mdc-form-field.language-selector .mat-mdc-text-field-wrapper.mdc-text-field {
  padding-right: 0;
  padding-left: 0;
}

.mat-mdc-form-field.language-selector .mat-mdc-option {
  padding-left: 3px;
  padding-right: 3px;
}

.mat-mdc-form-field.language-selector .mat-mdc-select-value {
  max-width: inherit;
}

.mat-mdc-form-field.language-selector div.mdc-text-field.mdc-text-field--filled div.mat-mdc-form-field-infix {
  border: 0;
  padding: 0;
  min-height: 0;
}

.mat-mdc-form-field.language-selector .mat-mdc-select {
  text-align: center;
}

.mat-mdc-form-field.language-selector {
  width: 51px;
  margin-right: 10px;
  max-height: inherit;
  display: block;
  margin-bottom: 0;
}

.flag-size {
  height: 14px;
  margin-top: -4px;
}

.cdk-overlay-container .cdk-overlay-pane .mat-mdc-select-panel.language-flag-select {
  max-height: initial;
}

.cdk-overlay-container .cdk-overlay-pane .language-flag-select .mat-mdc-option.mdc-list-item {
  padding-left: 10px;
  padding-right: 10px;
}

.cdk-overlay-container .cdk-overlay-pane .language-flag-select .mat-pseudo-checkbox,
.mat-mdc-form-field.language-selector .mat-mdc-select-arrow-wrapper,
.mat-mdc-form-field.language-selector .mdc-line-ripple {
  display: none;
}

/* account change dropdown */
.account-div {
  text-align: center;
  position: relative;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 3px;
}

.mat-mdc-form-field.account-selector .mat-mdc-text-field-wrapper.mdc-text-field {
  padding-left: 5px;
  padding-right: 5px;
}

.cdk-overlay-container .cdk-overlay-pane .account-selector .mat-pseudo-checkbox,
.mat-mdc-form-field.account-selector .mdc-line-ripple {
  display: none;
}

.mat-mdc-form-field.account-selector .mat-mdc-select-value {
  max-width: inherit;
}

.mat-mdc-form-field.account-selector div.mdc-text-field.mdc-text-field--filled div.mat-mdc-form-field-infix {
  padding: 5px 0 0 0;
}

.account-selector mat-select-trigger {
  margin-right: 5px;
}

.account-selector .mat-mdc-select {
  text-align: right;
  vertical-align: middle;
}

.mat-mdc-select-panel.show-all-options .mat-mdc-option .mdc-list-item__primary-text {
  margin-right: 0;
  width: 100%;
}

.mat-mdc-select-panel.show-all-options {
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content;
}

.mat-mdc-form-field.datesize .mat-mdc-form-field-infix {
  padding-top: 16px;
}

/* END new for Angular 16 */
.mdc-tooltip--multiline {
  white-space: pre-wrap;
}

/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(""), url('roboto-v29-latin-300.0dfc8c1e31826e6b.woff2') format("woff2"), url('roboto-v29-latin-300.c5e7b25aef3f051c.woff') format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"), url('roboto-v29-latin-regular.7b8d7718ba08bc7d.woff2') format("woff2"), url('roboto-v29-latin-regular.a69044dd3989ddbd.woff') format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(""), url('roboto-v29-latin-500.f7e6f1dc1466c7e1.woff2') format("woff2"), url('roboto-v29-latin-500.2c985b6613b2c639.woff') format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
html,
body {
  height: 100%;
  margin: 0;
}

.sidenav-container {
  /* height: calc(100% - 64px); */
  height: 100%;
}

a:not(:hover) {
  text-decoration: none;
}

.enlargepage {
  min-height: 250px;
  flex: 1;
}

.enlargepage-smaller {
  min-height: 100px;
  flex: 1;
}

.date-input-narrow,
.date-hint-narrow {
  max-width: 124px;
  width: 114px;
}

.date-hint-narrow {
  text-align: right;
  margin-left: 10px;
  padding-right: 15px;
}

.ap-popuplike {
  position: absolute;
  width: 300px;
  margin-left: -150px;
  left: 50%;
  margin-top: 2cm;
  margin-bottom: 2cm;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.25);
}

.spacearound {
  padding-left: 5px;
  padding-right: 5px;
}

.spaceright {
  padding-right: 5px;
}

.spaceleft {
  padding-left: 5px;
}

.right-align {
  text-align: right;
}

.right-aligned-header > .mat-content {
  justify-content: space-between;
}

.main-note {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 40px;
}

/* .mat-content > mat-panel-title, .mat-content > mat-panel-description {
  flex: 0 0 auto;
} */
/* replace stepper 1 2 3 icons with location coffee done */
.initwizard mat-step-header .mat-step-label {
  overflow: visible;
}

.initwizard mat-step-header .mat-step-icon-not-touched span,
.initwizard mat-step-header .mat-step-icon span,
.initwizard mat-step-header .mat-step-icon-not-touched .mat-icon,
.initwizard mat-step-header .mat-step-icon .mat-icon {
  display: none;
}

.initwizard div.mat-step:nth-of-type(1) mat-step-header .mat-step-icon-not-touched:after,
.initwizard div.mat-step:nth-of-type(1) mat-step-header .mat-step-icon:after {
  content: "place" !important;
  font-family: "Material Icons" !important;
  font-feature-settings: "liga" 1;
}

.initwizard div.mat-step:nth-of-type(2) mat-step-header .mat-step-icon-not-touched:after,
.initwizard div.mat-step:nth-of-type(2) mat-step-header .mat-step-icon:after {
  content: "local_cafe" !important;
  font-family: "Material Icons" !important;
  font-feature-settings: "liga" 1;
}

.initwizard div.mat-step:nth-of-type(3) mat-step-header .mat-step-icon-not-touched:after,
.initwizard div.mat-step:nth-of-type(3) mat-step-header .mat-step-icon:after {
  content: "done" !important;
  font-family: "Material Icons" !important;
  font-feature-settings: "liga" 1;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-wrap {
  display: flex;
}

.twoLineOption {
  line-height: 1.3em;
}

.twoline-select-with-icon .mat-mdc-option .mdc-list-item__primary-text {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actionbar {
  width: 100%;
  margin-top: 10px;
  padding: 5px 0;
  text-align: center;
}

.action-button,
.action-button.mat-mdc-raised-button,
.action-button.mat-mdc-raised-button:not(:disabled) {
  margin: 0 5px 5px 5px;
  color: white;
  max-height: 36px;
  letter-spacing: normal;
}

.action-button.mat-mdc-raised-button .mat-icon,
.edit-button.mat-mdc-raised-button .mat-icon {
  vertical-align: middle;
  margin: 0;
  font-size: 24px;
  width: 24px;
  height: 24px;
}

.edit-button,
.edit-button.mat-mdc-raised-button,
.edit-button.mat-mdc-raised-button:not([disabled]) {
  margin: 0 5px 5px 5px;
  color: white;
  letter-spacing: normal;
}

.clone-button,
.clone-button.mat-mdc-raised-button {
  margin-right: 15px;
}

.overview {
  width: 100%;
  margin-top: 10px;
  margin-right: 10px;
  padding: 5px;
}

.sortbar {
  margin-bottom: 8px;
  margin-left: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.sortbar-inner {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: auto;
}

.sortbar-inner .mat-button-toggle-button .mat-button-toggle-label-content {
  line-height: 24px;
  font-size: 14px;
}

.mat-step-icon,
.mat-step-icon-not-touched {
  text-align: center;
}

.centered {
  margin-left: auto;
  margin-right: auto;
}

.currency-input {
  border: none;
  border-bottom: #949494 solid 1px;
}

.mat-step-icon {
  padding-top: 2px;
}

.wait-outer {
  width: 100%;
}

.spinner {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

.mat-mdc-progress-spinner.white-spinner circle {
  stroke: #ffffff;
}

.mat-mdc-form-field {
  width: 100%;
}

.notesdiv.mat-mdc-form-field {
  top: 15px;
}

#dropZone {
  height: 100%;
  background-color: rgba(240, 248, 255, 0.568627451);
  width: 100%;
}

#dropZone .content {
  height: 100%;
  font-weight: bold;
  font-size: x-large;
}

.comment {
  margin-top: -15px;
  text-align: start;
  font-size: 12px;
  margin-bottom: 15px;
}

.comment-ble {
  text-align: end;
  margin-top: -28px;
  margin-bottom: 0;
}

tr.report-element-row .mat-expansion-panel {
  background: #f5f5f5;
}

.littlespaceleft {
  margin-left: 3px;
}

.littlespaceright {
  margin-right: 3px;
}

.smallfont {
  font-size: 85%;
  margin-left: 1em;
  text-indent: -1em;
  letter-spacing: normal;
}

.secondLineIndent {
  margin-left: 2em;
}

.smallicon {
  cursor: pointer;
  margin-left: 0;
  vertical-align: top;
  font-size: 16px;
  line-height: 16px;
  height: 16px;
  width: 16px;
  text-indent: 0;
}

.smallicon-second {
  margin-left: 0;
}

.small-close-icon {
  height: 14px;
  width: 22px;
  line-height: 14px;
  font-size: 14px;
  cursor: pointer;
}

.smallish-close-icon {
  height: 16px;
  width: 22px;
  line-height: 16px;
  font-size: 16px;
  cursor: pointer;
}

.overview-close-icon {
  height: 18px;
  width: 18px;
  line-height: 18px;
  font-size: 18px;
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.text-centered {
  text-align: center;
}

app-purchase-dialog,
app-sell-dialog,
app-transfer-dialog,
app-correct-dialog {
  display: block;
  width: 296px;
}

.amount-input {
  width: 70px;
  text-align: right;
}

.mat-mdc-form-field.amount-input-ff1 {
  max-width: 166px;
  text-align: right;
  padding-right: 10px;
}

.mat-mdc-form-field.amount-input-ff2 {
  max-width: 130px;
  text-align: right;
}

.amount-input-ff1 .amount-input,
.amount-input-ff2 .amount-input {
  margin-right: 3px;
}

.mat-mdc-form-field.amount-input-ff1 .mat-mdc-form-field-label,
.mat-mdc-form-field.amount-input-ff2 .mat-mdc-form-field-label {
  text-align: left;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field.amount-input-ff2 .mat-mdc-form-field-label {
  text-overflow: unset;
  overflow: visible;
}

.mat-mdc-form-field.date-input-ff1 {
  max-width: 130px;
  padding-right: 10px;
}

.mat-mdc-form-field.date-input-ff2 {
  max-width: 130px;
  text-align: right;
}

.date-range-flex-div {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  align-items: center;
  flex-wrap: wrap;
}

.mat-datepicker-toggle .mat-mdc-icon-button {
  height: 24px;
  width: 24px;
  padding: 0;
}

.nobreak {
  white-space: nowrap;
}

.ellipsed {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

div.top-space .text-container {
  margin-top: 110px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.wrap-checkbox-label label.mat-checkbox-layout {
  white-space: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.cursor-copy {
  cursor: copy;
}

.cursor-grab {
  cursor: -webkit-grab;
  cursor: grab;
}

.cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.snack-right {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin-left: auto !important;
  margin-top: 50px !important;
  margin-right: 80px !important;
}

.snack-right .mat-mdc-simple-snack-bar {
  justify-content: center;
}

app-update-snack {
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
}

.otherside-button {
  margin-right: auto;
}

.smallesticon {
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  width: 14px;
  text-indent: 0;
}

.upload-link {
  text-align: right;
  padding-top: 10px;
  font-size: 75%;
}

app-coffee .upload-link,
app-blend .upload-link,
app-roast .upload-link {
  text-align: left;
}

.mat-mdc-card-avatar,
.contact-avatar {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-icon {
  width: 50px;
  height: 50px;
}

.avatar-icon-generic {
  width: 32px;
  height: 32px;
}

.readonly-spacer {
  height: 15px;
}

.mat-icon.helpicon {
  height: 16px;
  width: 16px;
  font-size: 16px;
  vertical-align: text-bottom;
}

.sort-indicator {
  z-index: 1;
}

.sort-indicator .mat-icon {
  font-size: 15px;
  line-height: 24px;
  margin-left: -24px;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.sortButton.mat-button-toggle-checked {
  padding-right: 15px;
}

.sortButton {
  border-radius: 4px;
}

.sortButtonGroup {
  flex-wrap: wrap;
  border: none;
  height: 24px;
}

.filter-input-div {
  float: right;
  margin-right: 10px;
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.filter-input-div .mat-mdc-form-field {
  width: 150px;
}

image-cropper.cropping {
  background: none;
}
image-cropper.cropping div.overlay,
image-cropper.cropping div.cropper {
  outline: none !important;
}

.mat-action-row.separate-buttons,
.inner-action-row.separate-buttons {
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-baseline {
  display: flex;
  align-items: baseline;
}

.flex-end {
  display: flex;
  align-items: flex-end;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.smallerfont {
  font-size: 10px;
  line-height: 12px;
}

.highlight-add-option {
  font-style: italic;
}

.hideme {
  animation: fadeOut ease 2s;
  -webkit-animation: fadeOut ease 2s;
  -moz-animation: fadeOut ease 2s;
  -o-animation: fadeOut ease 2s;
  -ms-animation: fadeOut ease 2s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.showme {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.helptip.helptip-organic {
  left: -154px;
  top: 22px;
}

.helptip {
  opacity: 0;
  position: absolute;
  z-index: 1000;
  left: -140px;
  width: 300px;
  margin-top: 17px;
  background: #177ab2;
  color: #eeeeee;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  text-align: center;
  border-radius: 10px;
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  white-space: pre-wrap;
  padding: 10px 10px 10px 10px;
}

.helptip:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #177ab2 transparent;
  border-width: 0 20px 20px;
  top: -20px;
  left: 50%;
  margin-left: -20px;
}

.helptip a {
  color: #eeeeee;
}

.mat-mdc-form-field.form-group.halfsizeleft {
  width: 44%;
  margin-right: 1%;
}

.mat-mdc-form-field.form-group.halfsizeright {
  width: 55%;
}

.halfsizeleft-withicon .mat-mdc-form-field.form-group {
  width: 75%;
  margin-right: 1%;
}

.halfsizeright-withicon .mat-mdc-form-field.form-group {
  width: 75%;
  margin-right: 1%;
}

.halfsizeright-withicon {
  text-align: end;
}

.mat-mdc-form-field.form-group.thirdsizeleft {
  width: 32%;
  margin-right: 1%;
}

.mat-mdc-form-field.form-group.default-unit-size-kg-middle {
  width: 50%;
  margin-right: 1%;
}

.mat-mdc-form-field.form-group.default-unit-size-lbs-right {
  width: 16%;
}

.mat-mdc-form-field.form-group.twothirdsize {
  width: 66%;
}

.button-spinner {
  margin: 0 auto;
}

.spinner-button {
  height: 36px;
  vertical-align: bottom;
}

app-select-roastreports .mat-ink-bar {
  width: 100px;
}

a.mat-mdc-tab-link,
a.mat-tab-link:hover,
a.mat-tab-link:focus {
  text-decoration: none;
}

.mat-expansion-panel-body {
  position: relative;
}

.organic-icon {
  fill: #27a734;
}

.hide-icon {
  font-size: 16px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  margin-left: 10px;
}

.custom-snack a {
  cursor: pointer;
}

.dropdown-image {
  height: 35px;
  width: 55px;
  margin-right: 15px;
  margin-left: 5px;
}

app-supplierpartners .mat-expansion-panel-header-title {
  flex-wrap: wrap;
}

.contact-card .mat-expansion-panel-body {
  padding-top: 16px;
}

.contact-card.mat-expansion-panel {
  display: inline-flex;
  vertical-align: top;
}

app-contacts .mat-expansion-panel-header {
  padding: 0 14px;
}

.dropdown-text-image {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 300px;
}

.dropdown-text-image-label {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.mat-icon.smallish {
  font-size: 17px;
}

.mat-icon.smallish2 {
  font-size: 20px;
}

h2.main-heading {
  margin: 20px 0 20px 5px;
  font-weight: 500;
  font-size: 30px;
  line-height: 33px;
  white-space: nowrap;
}

.left-align .mat-sort-header-content {
  text-align: left;
}

.origin-select {
  max-width: 130px;
  margin-left: 10px;
}

.narrow-select {
  max-width: 130px;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0;
}

.filter-hints {
  margin-right: 20px;
  font-size: 85%;
  text-align: right;
}

.filter-parts .wide-filter-input {
  display: block;
  width: 268px;
}

.left-margin {
  display: inline-block;
  margin-left: 10px;
  padding-right: 0;
}

.left-margin-only {
  margin-left: 10px;
}

.right-padding {
  padding-right: 10px;
}

.allowNull-container label {
  margin-bottom: 0;
}

.newroastreport .table-badge {
  left: 0 !important;
}

.table-badge {
  position: absolute;
  top: -15px;
  left: -10px;
  padding: 1px 7px 1px 7px;
  border-width: 3px;
  border-style: solid;
  border-radius: 14px;
}

.overview-copied {
  color: green;
}

.overview-copyFailed {
  color: darkred;
}

.scheduler-input-group .mat-mdc-chip-graphic {
  display: none;
}

.scheduler-input-group .mat-mdc-form-field-hint-wrapper {
  padding-left: 0;
  padding-right: 0;
}

.scheduler-select-option-with-stock .mdc-list-item__primary-text {
  width: 100%;
}

.scheduler-label-with-stock {
  display: flex;
  justify-content: space-between;
}

.scheduler-input-group.scheduler-users-group .checkbox-filter-with-value {
  margin-top: 4px;
}

.scheduler-input-group.scheduler-users-group .allowNull-label {
  margin-top: 6px;
}

.scheduler-input-group .checkbox-filter-main-div {
  width: unset;
}

.scheduler-input-flex-middle .mat-mdc-form-field-infix {
  width: unset;
}

.scheduler-planner-div-container.cdk-drop-list-dragging {
  cursor: move;
}

.scheduler-list-item.cdk-drag-preview {
  cursor: move;
}

.scheduler-planner-favorites-container .scheduler-list-custom-placeholder,
.scheduler-planner-favorites-container .scheduler-list-custom-placeholder2 {
  width: 255px !important;
  min-width: 255px !important;
  max-width: 255px !important;
  margin-right: 10px !important;
  margin-bottom: 0 !important;
}

.scheduler-planner-div-container .scheduler-list-custom-placeholder,
.scheduler-planner-div-container .scheduler-list-custom-placeholder2 {
  width: 345px !important;
  max-width: 345px !important;
}

.small-checkboxes .mdc-checkbox {
  padding: 0;
}

.small-checkboxes .mat-mdc-checkbox-touch-target {
  height: 18px;
  width: 18px;
}

.small-checkboxes .mdc-checkbox .mdc-checkbox__native-control {
  height: 18px;
  width: 18px;
  left: 0;
  top: 0;
}

.small-checkboxes .mat-mdc-checkbox .mdc-checkbox__background {
  left: 0;
  top: 0;
}

.hidden-datepicker-input {
  width: 0;
  padding: 0;
  visibility: hidden;
  margin: 0;
  border: 0;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.paneltitle {
  font-weight: normal;
  align-items: baseline;
  min-width: 0;
  margin-right: 0;
  display: block;
  flex-grow: 3;
  margin-right: 2px;
}

.paneltitle.shortTitle {
  flex-grow: 2;
}

.small-font {
  font-size: 80%;
}

.head-date-wrapper {
  display: flex;
  flex-wrap: wrap;
  text-align: right;
  flex-grow: 1;
  min-width: 90px;
}

.head-date-line {
  flex-grow: 1;
}

.head-date-line-time {
  font-size: 80%;
  flex-grow: 1;
  width: 100%;
}

.outerdiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}

.nottable {
  min-width: 300px;
  margin-top: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
  flex-grow: 1;
}

.labelitem {
  padding-bottom: 5px;
}

.tablediv {
  min-width: 300px;
  max-width: 440px;
  overflow-x: auto;
  margin-right: 10px;
}

table.proptable {
  min-width: 250px;
  margin: 15px;
}

table.proptable td.labelname {
  font-weight: bold;
  padding-right: 8px;
}

table.proptable td {
  font-size: 90%;
}

table.proptable td {
  padding-left: 4px;
  padding-right: 3px;
}

table.proptable .labelname {
  text-align: right;
}

table.proptable input[type=number]::-webkit-inner-spin-button,
table.proptable input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

table.proptable input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
          appearance: textfield;
}

.mat-mdc-form-field {
  width: 100%;
}

table.proptable input[type=number],
table.proptable input.number-input {
  width: 60px;
  text-align: right;
}

table.proptable input.number-input::-webkit-input-placeholder {
  text-align: left;
}

table.proptable input.number-input:-moz-placeholder {
  text-align: left;
}

table.proptable input.number-input::-moz-placeholder {
  text-align: left;
}

table.proptable input.number-input:-ms-input-placeholder {
  text-align: left;
}

.proptable input.thirdwidth,
.proptable mat-select.thirdwidth {
  width: 60px;
  min-width: 50px;
}

.proptable input.halfwidth {
  width: 20%;
  min-width: 50px;
  margin-right: 5px;
}

.imgdiv {
  margin-top: 15px;
}

img {
  width: 250px;
}

.mat-expansion-panel {
  margin-right: 5px;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  flex-basis: 0;
}

.mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
  margin-right: 0;
  max-width: 40%;
}

.mat-expansion-panel-header {
  min-height: 50px;
  touch-action: pan-y !important;
}

.mat-icon.small {
  width: 12px;
  height: 12px;
  font-size: 12px;
  line-height: 12px;
}

.full-width {
  min-width: 0;
  width: 100%;
}

.spaceright-more {
  margin-right: 5px;
}

.spinner-with-comment {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  font-size: 90%;
}

.spinner-comment {
  margin-left: 10px;
}

.number-dropdown {
  display: flex;
  align-items: center;
}

.proptable .mat-mdc-select.nexttonumber {
  margin-left: 3px;
  flex-grow: 1;
  margin-top: 1px;
}

.head-label {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
}

.mat-expanded .head-label {
  font-weight: bold;
}

.head-hrid {
  font-size: 80%;
  margin-right: 5px;
}

.panel-titel-amount-wrapper {
  display: flex;
  align-items: flex-end;
}

.head-stock-wrapper {
  text-align: right;
  min-width: 75px;
  margin-right: 15px;
}

.head-label-wrapper {
  text-overflow: ellipsis;
  width: calc(100% - 110px);
}

.wide-table {
  width: 99%;
}

.button-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px 8px 16px 24px;
}

ul.ingredients-list {
  -webkit-padding-start: 1em;
          padding-inline-start: 1em;
  margin-bottom: 0;
}

.smallfont {
  font-size: 85%;
}

.sum-cell {
  font-weight: bold;
}

.mat-mdc-cell, .mat-mdc-footer-cell, .mat-mdc-header-cell {
  padding: 0.75rem;
}

.top-space {
  margin-top: 15px;
}

.mat-expansion-panel.mat-expanded.missing-roasts:not(.reconciled),
.mat-expansion-panel.mat-expanded.missing-roasts:not(.reconciled):focus,
.mat-expansion-panel.mat-expanded.missing-roasts:not(.reconciled):hover {
  background: none;
}

.mat-expansion-panel.reconciled::before {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0px;
  content: "";
  width: 13px;
}

.mat-expansion-panel.missing-roasts:not(.reconciled):not(.mat-expanded)::before {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0px;
  content: "";
  width: 13px;
}

.dates {
  font-weight: bold;
}

.mat-expansion-panel {
  position: relative;
}

.button-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px 8px 16px 24px;
}

.other-table-heading {
  margin-top: 20px;
  margin-bottom: 8px;
}

.report-container {
  padding-left: 15px;
}

.report-link {
  cursor: pointer;
}

.report-link.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.report-link-space {
  margin-top: 8px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.report-link-space-flex {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 350px;
  margin: 0 auto 0 auto;
  min-height: 62px;
}

.report-pdf-space-flex {
  display: flex;
  align-items: center;
}

.small-info {
  font-size: 75%;
}

.bottom-padding {
  margin-bottom: 17.5px;
}

.mat-padding {
  margin-bottom: 17.5px;
}

.mat-left-padding {
  margin-left: 17.5px;
}

ul.ingredients-list {
  -webkit-padding-start: 1em;
          padding-inline-start: 1em;
  margin-bottom: 0;
}

.normalweight-footer {
  font-weight: normal;
}

.removeDestroyed-icon {
  vertical-align: middle;
}

.mat-mdc-form-field.amount-input-ff1.narrow-date-field {
  max-width: 104px;
  padding-right: 0;
}

.shifted-add-button {
  position: absolute;
  top: 54px;
  left: 55%;
}

.company-name {
  width: 50%;
  margin-right: 10px;
}

.company-number {
  width: 150px;
}

.close-icon {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  margin-left: 5px;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.3;
}

.close-icon:hover {
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=60);
  opacity: 0.6;
}

.hint-left {
  text-align: left;
  padding-left: 19px;
  display: block;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
