import { NGXLogger } from 'ngx-logger';
import { Component, OnInit, Inject, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Coffee } from 'src/app/models/Coffee';
import { StandardService } from 'src/app/util/services/standard.service';
import { Utils } from 'src/app/util/utils';
import { Enumerations } from 'src/app/models/Enumerations';
import { Location } from 'src/app/models/Location';
import { UserService } from 'src/app/modules/frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Correction } from 'src/app/models/Correction';
import { StockType } from 'src/app/modules/stock/store-stock.component';
import { StockService } from 'src/app/modules/stock/stock.service';
import { environment } from 'src/environments/environment';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { AlertService } from 'src/app/util/alert/alert.service';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { TransactionDialogComponent } from './transaction-dialog.component';
import { StockChange } from 'src/app/models/StockChange';
import { DateTime } from 'luxon';

export class CorrectDialogResultType {
    constructor (openDialog: string, trans: StockChange) {
        this.openDialog = openDialog;
        this.trans = trans;
    }
    openDialog: string;
    trans: StockChange;
}

@Component({
    selector: 'app-correct-dialog',
    templateUrl: './correct-dialog.component.html',
    styleUrls: ['./correct-dialog.component.scss']
})
export class CorrectDialogComponent extends TransactionDialogComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(
        private standardService: StandardService,
        // used in template
        public tr: TranslatorService,
        // used by "that"
        public dialogRef: MatDialogRef<CorrectDialogComponent>,
        // used by "that"
        public alertService: AlertService,
        protected userService: UserService,
        private logger: NGXLogger,
        public utils: Utils,
        private stockService: StockService,
        protected router: Router,
        @Inject(MAT_DIALOG_DATA) public data: {
            stores: Location[], stocks: StockType[][], coffees: Coffee[], preSelected: number, date: DateTime, transaction: Correction,
        }
    ) {
        super(dialogRef, utils, userService, router);
    }

    correction: Correction;

    reference: string;

    showDeltaAmounts = false;
    damount = 0;
    dunitamount = 0;
    // the target warehouse
    store: Location;
    stocks: StockType[][];
    reason: string;

    storeIdx = 0;
    stockIdx = -1;

    @ViewChild('coffeeSelect', { static: true }) coffeeSelect: MatSelect;


    ngOnInit(): void {
        super.ngOnInit();

        this.stores = this.data.stores;
        if (this.stores?.length) {
            this.store = this.stores[0];
        }
        this.stocks = this.data.stocks;

        if (this.data.date) {
            this.date = this.data.date;
            // this.date.set({hour: 12, minute: 0, second: 0, millisecond: 0});
        }

        if (this.data.coffees?.length) {
            this.coffees = this.data.coffees;
            this.filteredCoffees = this.coffees;
            if (!this.data.transaction && this.coffees?.length) {
                this.coffee = this.coffees[this.data.preSelected ? this.data.preSelected : 0];
                this.updateAmount(true);
            }
        } else {
            this.getAllCoffees();
        }

        if (!this.stores) {
            this.getAllStores();
        }

        if (this.data.transaction) {
            // have a transaction that needs to be edited i.e. loaded
            this.coffee = this.data.transaction.coffee;
            if (this.coffee && typeof this.coffee !== 'string' && !this.coffee.yearLabel) {
                this.coffee.yearLabel = this.utils.createBeansYearLabel(this.coffee);
            }
            if (!this.coffees) {
                this.coffees = [this.coffee];
                this.filteredCoffees = this.coffees;
            }
            this.date = this.data.transaction.date;
            this.reason = this.data.transaction.reason;
            this.reference = this.data.transaction.reference;
            this.store = this.data.transaction.location;
            this.stores = this.stores || [this.store];
            this.unitamount = this.data.transaction.amount;
            this.calcAmount(false, false);
            this.reconciled = this.data.transaction.reconciled;
        }

        if (!this.stocks) {
            this.stocks = [];
        }
        if (!this.stocks[0]) {
            this.stocks[0] = [];
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    ngAfterViewInit(): void {
        if (this.coffeeSelect) {
            this.coffeeSelect.compareWith = this.utils.compareByLabelFn;
        }
    }

    protected getAllCoffees(): void {
        this.standardService.getAll<Coffee>('coffees')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.coffees = response.result;
                        if (this.coffee?.hidden) {
                            // need to add this current coffee since hidden coffees are not retrieved by getAll
                            this.coffees.unshift(this.coffee);
                        }
                        for (let c = 0; c < this.coffees.length; c++) {
                            const cof = this.coffees[c];
                            if (cof) {
                                cof.yearLabel = cof.yearLabel || this.utils.createBeansYearLabel(cof);
                                if (!cof.default_unit?.name) {
                                    cof.default_unit = { name: Enumerations.CoffeeUnits._NONE, size: 1 }
                                }
                            }
                        }
                        this.filteredCoffees = this.coffees;
                        if (!this.coffee && this.coffees?.length) {
                            this.coffee = this.coffees[this.data.preSelected ? this.data.preSelected : 0];
                            this.updateAmount(true);
                        }
                    } else {
                        this.utils.handleError('error retrieving all beans', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all beans', error);
                }
        });
    }

    protected getAllStores(): void {
        this.standardService.getAll<Location>('stores')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.stores = response.result;
                        this.getAllStocks();
                    } else {
                        this.utils.handleError('error retrieving all stores', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all stores', error);
                }
        });
    }

    protected getAllStocks(): void {
        if (!this.stores) {
            return;
        }
        const storeIds = this.stores.map(s => s._id);
        this.stockService.getCoffeeStocks(storeIds)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.stocks = [];
                        for (let sIdx = 0; sIdx < response.result.length; sIdx++) {
                            this.stocks[sIdx] = response.result[sIdx].filter(s => s.amount !== 0);
                        }
                    } else {
                        this.utils.handleError('error retrieving all store stocks', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all store stocks', error);
                }
        });
    }

    protected updateAmount(coffeeChanged: boolean): void {
        if (this.data.transaction || !this.coffee) {
            // don't change the amount if an existing Correction is currently edited
            // or no coffee has been selected yet
            return;
        }
        if (coffeeChanged) {
            for (let c = 0; this.stocks && c < this.stocks[this.storeIdx]?.length; c++) {
                if (this.stocks[this.storeIdx][c].coffeeId === (this.coffee._id || this.coffee).toString()) {
                    this.stockIdx = c;
                    this.unitamount = this.stocks[this.storeIdx][this.stockIdx].amount;
                    this.calcAmount(false, false);
                    return;
                }
            }
            // could not find beans in current store => amount is 0
            this.unitamount = 0;
            this.amount = 0;
        } else {
            for (let s = 0; this.stocks && s < this.stocks.length; s++) {
                for (let c = 0; c < this.stocks[s].length; c++) {
                    if (!(this.stocks[s][c].location_id === (this.store._id || this.store).toString())) {
                        // not the correct store
                        break;
                    }
                    this.storeIdx = s;
                    if (this.stocks[s][c].coffeeId === (this.coffee._id || this.coffee).toString()) {
                        this.stockIdx = c;
                        this.unitamount = this.stocks[this.storeIdx][this.stockIdx].amount;
                        this.calcAmount(false, false);
                        return;
                    }
                }
            }
            // could not find beans in current store => amount is 0
            this.unitamount = 0;
            this.amount = 0;
        }
    }

    protected calcAmount(toUnit: boolean, deltaChanged: boolean): void {
        if (deltaChanged) {
            // delta value changed; update dunitamount or damount
            if (this.coffee?.default_unit?.size) {
                if (toUnit) {
                    // amount to unitamount
                    this.dunitamount = this.damount * this.coffee.default_unit.size;
                } else {
                    // unitamount to amount
                    this.damount = this.dunitamount / this.coffee.default_unit.size;
                }
            }

            // update both absolute amounts
            this.unitamount = this.calcAbsolute();
            if (this.coffee?.default_unit?.size) {
                this.amount = this.unitamount / this.coffee.default_unit.size;
            }

        } else {
            // absolute value changed; update unitamount or amount
            if (this.coffee?.default_unit?.size) {
                if (toUnit) {
                    // amount to unitamount
                    this.unitamount = this.amount * this.coffee.default_unit.size;
                } else {
                    // unitamount to amount
                    this.amount = this.unitamount / this.coffee.default_unit.size;
                }
            }

            // update both delta amounts
            this.dunitamount = this.calcDelta();
            if (this.coffee?.default_unit?.size) {
                this.damount = this.dunitamount / this.coffee.default_unit.size;
            }
        }
    }

    protected calcDelta(): number {
        if (this.data.transaction) {
            // don't change the amount if an existing Correction is currently edited
            return this.unitamount;
        }
        if (!this.coffee || !this.stocks) {
            return 0;
        }
        let oldunitamount = 0;
        if (this.stockIdx >= 0 && this.stocks[this.storeIdx][this.stockIdx]) {
            oldunitamount = this.stocks[this.storeIdx][this.stockIdx].amount;
        } else {
            for (let c = 0; c < this.stocks[this.storeIdx]?.length; c++) {
                if (this.stocks[this.storeIdx][c].coffeeId === (this.coffee._id || this.coffee).toString()) {
                    this.stockIdx = c;
                    oldunitamount = this.stocks[this.storeIdx][this.stockIdx].amount;
                    break;
                }
            }
        }
        const delta = this.unitamount - oldunitamount;
        if (this.utils.isWithinRounding(delta, 0, 3)) {
            return 0;
        }
        return delta;
    }

    protected calcAbsolute(): number {
        if (this.data.transaction) {
            // right now, should not be possible if existing transaction is edited
            this.logger.warn('should not be possible to edit delta values when editing existing correction');
            return this.unitamount;
        }
        if (!this.coffee || !this.stocks) {
            return 0;
        }
        let oldunitamount = 0;
        if (this.stockIdx >= 0 && this.stocks[this.storeIdx][this.stockIdx]) {
            oldunitamount = this.stocks[this.storeIdx][this.stockIdx].amount;
        } else {
            for (let c = 0; c < this.stocks[this.storeIdx]?.length; c++) {
                if (this.stocks[this.storeIdx][c].coffeeId === (this.coffee._id || this.coffee).toString()) {
                    this.stockIdx = c;
                    oldunitamount = this.stocks[this.storeIdx][this.stockIdx].amount;
                    break;
                }
            }
        }
        const abs = oldunitamount + this.dunitamount;
        if (this.utils.isWithinRounding(abs, oldunitamount, 3)) {
            return oldunitamount;
        }
        return abs;
    }

    // getDeltaAmount(): {pre: string, value: number, post: string} {
    //     return this.utils.formatAmountForPipe(this.calcDelta(), undefined, this.currentUser?.unit_system);
    // }

    // this is called via utils.checkChanges as callback with parameters
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected doAdd(that?: CorrectDialogComponent, _changedPrice?: string): void {
        if (!that) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            that = this;
        }
        if (!that.submitPressed) {
            return;
        }
        that.submitPressed = false;

        const old_amount = that.data && that.data.transaction && that.data.transaction.amount ? that.data.transaction.amount : 0;
        if (that.utils.isWithinRounding(that.unitamount, old_amount, 3) && !that.hasChanged()) {
            that.alertService.success(that.tr.anslate('Nothing to change'));
            // that.dialogRef.close();
            that.finishDialog(undefined);
            return;
        }

        that.correction = new Correction();
        delete that.correction.reconciled;
        // use absolute amount!
        that.correction.amount = that.unitamount;
        that.correction.coffee = that.coffee?._id as unknown as Coffee || that.coffee;
        that.correction.date = that.date;
        that.correction.location = that.store._id as unknown as Location || that.store;
        that.correction.reason = that.reason;
        that.correction.reference = that.reference;
        that.correction.type = 'Correction';
        that.correction.translated_type = that.tr.anslate('Correction');

        // that.dialogRef.close({ openDialog: 'correction', trans: that.correction });
        const res = new CorrectDialogResultType('correction', that.correction);
        that.finishDialog(res);
    }

    // checks if anything (not including the amount) has changed
    protected hasChanged(): boolean {
        const t = this.data.transaction;
        if (t) {
            if (this.reason !== t.reason) { return true; }
            if (this.reference !== t.reference) { return true; }
            if (!this.utils.compareObjectsFn(this.coffee, t.coffee)) { return true; }
            if (!this.utils.compareObjectsFn(this.store, t.location)) { return true; }
            if (this.reconciled !== t.reconciled) { return true; }
            // if (JSON.stringify(this.tags) !== JSON.stringify(t.tags)) return true;
            if (!this.date.hasSame(t.date, 'minute')) { return true; }
        } else {
            // only depends on amount which is checked outside this function
            return true;
        }
        return false;
    }

    protected openDialog(dtype: string): void {
        // this.dialogRef.close({ openDialog: dtype });
        this.finishDialog(new CorrectDialogResultType(dtype, undefined));
    }
}
