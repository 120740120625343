import { Component, OnInit, Inject, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Coffee } from 'src/app/models/Coffee';
import { StandardService } from 'src/app/util/services/standard.service';
import { NGXLogger } from 'ngx-logger';
import { Utils } from 'src/app/util/utils';
import { Enumerations } from 'src/app/models/Enumerations';
import { Location } from 'src/app/models/Location';
import { UserService } from 'src/app/modules/frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Sale } from 'src/app/models/Sale';
import { Customer } from 'src/app/models/Customer';
import { StockType } from 'src/app/modules/stock/store-stock.component';
import { environment } from 'src/environments/environment';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { AlertService } from 'src/app/util/alert/alert.service';
import { StockService } from 'src/app/modules/stock/stock.service';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { TransactionDialogComponent } from './transaction-dialog.component';

@Component({
    selector: 'app-sell-dialog',
    templateUrl: './sell-dialog.component.html',
    styleUrls: ['./sell-dialog.component.scss']
})
export class SellDialogComponent extends TransactionDialogComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(
        private standardService: StandardService,
        // used in template
        public tr: TranslatorService,
        private logger: NGXLogger,
        protected userService: UserService,
        public utils: Utils,
        private stockService: StockService,
        // used by "that"
        public dialogRef: MatDialogRef<SellDialogComponent>,
        // used by "that"
        public alertService: AlertService,
        protected router: Router,
        @Inject(MAT_DIALOG_DATA) public data: {
            stores: Location[],
            stocks: StockType[][],
            transaction: Sale,
            selectedStore: Location,
            selectedCoffee: Coffee,
        }
    ) {
        super(dialogRef, utils, userService, router);
    }

    sale: Sale;

    store: Location;
    filteredStores: Location[];
    stocks: StockType[][];
    customer: Customer;
    customers: Customer[];
    // TODO:
    externalLocation: Location;

    secondPage = false;
    sales_number: string;
    price = 0;
    pricePerUnit = 0;
    tracking_number: string;
    product_id: string;
    reference: string;

    doneLoadingStocks = false;
    notLoaded = 4;

    parseFloat = parseFloat;
    round = Math.round;

    addNewCustomerMode = false;
    editNewCustomerMode = false;
    editingCustomer: Customer;

    @ViewChild('coffeeSelect', { static: true }) coffeeSelect: MatSelect;

    // Sale: sell amount of coffee to customer on date.
    // The customer receives it to its externalLocation from the location (a warehouse of the user)

    ngOnInit(): void {
        super.ngOnInit();

        this.stores = this.data.stores;
        if (this.stores?.length) {
            this.store = this.stores[0];
        }

        this.filteredStores = this.stores;
        this.stocks = this.data.stocks;
        if (!this.stores || !this.stocks) {
            this.getAllStoresStocksAndCoffees();
        } else {
            this.doneLoadingStocks = true;
            this.getAllCoffees();
        }

        this.getAllCustomers();

        if (this.data.transaction) {
            // have a transaction that needs to be edited i.e. loaded
            this.coffee = this.data.transaction.coffee;
            if (this.coffee && typeof this.coffee !== 'string' && !this.coffee.yearLabel) {
                this.coffee.yearLabel = this.utils.createBeansYearLabel(this.coffee);
            }
            this.date = this.data.transaction.date;
            this.externalLocation = this.data.transaction.externalLocation;
            this.sales_number = this.data.transaction.sales_number;
            this.price = this.data.transaction.price;
            this.product_id = this.data.transaction.product_id;
            this.reference = this.data.transaction.reference;
            this.store = this.data.transaction.location;
            this.customer = this.data.transaction.customer;
            this.tracking_number = this.data.transaction.tracking_number;
            this.unitamount = this.data.transaction.amount;
            // update price per unit
            this.priceChanged(false);
            this.calcAmount(false);
            this.reconciled = this.data.transaction.reconciled;
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    ngAfterViewInit(): void {
        if (this.coffeeSelect) {
            this.coffeeSelect.compareWith = this.utils.compareByLabelFn;
        }
    }

    protected dateChanged(): void {
        if (this.date) {
            this.date.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
        }
    }

    protected priceChanged(ppuChanged: boolean): void {
        if (ppuChanged) {
            // pricePerUnit changed
            this.price = this.pricePerUnit * this.utils.convertToUserUnit(this.unitamount, this.unit_system);
        } else {
            // price changed
            this.pricePerUnit = this.unitamount ? this.price / this.utils.convertToUserUnit(this.unitamount, this.unit_system) : 0;
        }
    }

    protected getAllCoffees(): void {
        this.standardService.getAll<Coffee>('coffees')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.coffees = response.result;
                        if (this.coffee?.hidden) {
                            // need to add this current coffee since hidden coffees are not retrieved by getAll
                            this.coffees.unshift(this.coffee);
                        }
                        for (let c = 0; c < this.coffees.length; c++) {
                            const cof = this.coffees[c];
                            if (cof) {
                                if (!cof.default_unit?.name) {
                                    cof.default_unit = { name: Enumerations.CoffeeUnits._NONE, size: 1 }
                                }
                                cof.yearLabel = cof.yearLabel || this.utils.createBeansYearLabel(cof);
                            }
                        }
                        this.notLoaded -= 1;

                        if (!this.data.transaction && this.stocks?.length) {
                            // use only those that have a stock in one or more of the user's stores
                            // but only if this is not an edit of an existing transaction
                            let availableCoffeeIds = [];
                            for (const stocks of this.stocks) {
                                if (stocks) {
                                    availableCoffeeIds = availableCoffeeIds.concat(stocks.map(s => s.coffeeId));
                                }
                            }
                            this.coffees = this.coffees.filter(c => availableCoffeeIds.indexOf(c._id) >= 0);

                            if (!this.coffee && this.coffees?.length) {
                                this.coffee = this.coffees[0];
                                this.coffeeChanged();
                            }
                        }
                        if (!this.data.transaction && this.data.selectedCoffee) {
                            for (let c = 0; c < this.coffees.length; c++) {
                                const coff = this.coffees[c];
                                if ((coff._id || coff).toString() === (this.data.selectedCoffee._id || this.data.selectedCoffee).toString()) {
                                    this.coffee = coff;
                                    break;
                                }
                            }
                        }
                        if (typeof this.coffee === 'string') {
                            // lookup coffee ID
                            for (let c = 0; c < this.coffees.length; c++) {
                                const coff = this.coffees[c];
                                if ((coff._id || coff).toString() === this.coffee.toString()) {
                                    this.coffee = coff;
                                    this.calcAmount(false);
                                    // TODO this creates a strange compiler error?!
                                    // break;
                                }
                            }
                        }
                        if (!this.data.transaction && !this.coffee && this.coffees?.length) {
                            this.coffee = this.coffees[0];
                        }
                        this.filteredCoffees = this.coffees;
                    } else {
                        this.utils.handleError('error retrieving all beans', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all beans', error);
                }
        });
    }

    protected getAllCustomers(): void {
        this.standardService.getAll<Customer>('customers')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.customers = response.result;
                        this.notLoaded -= 1;
                        if (!this.customer && this.customers && this.customers.length > 0) {
                            this.customer = this.customers[0];
                        }
                    } else {
                        this.utils.handleError('error retrieving all customers', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all customers', error);
                }
        });
    }

    protected getAllStoresStocksAndCoffees(): void {
        this.standardService.getAll<Location>('stores')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.stores = response.result || [];
                        this.filterStores();
                        this.notLoaded -= 1;
                        if (!this.data.transaction && this.data.selectedStore) {
                            this.store = this.data.selectedStore;
                        }
                        if (!this.data.transaction && !this.store && this.stores && this.stores.length > 0) {
                            this.store = this.stores[0];
                        }
                        this.getAllStocksAndCoffees();
                    } else {
                        this.utils.handleError('error retrieving all stores', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all stores', error);
                }
        });
    }

    protected getAllStocksAndCoffees(): void {
        if (!this.stores) {
            return;
        }
        const storeIds = this.stores.map(s => s._id);
        this.stockService.getCoffeeStocks(storeIds)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.stocks = [];
                        this.notLoaded -= 1;
                        for (let sIdx = 0; sIdx < response.result.length; sIdx++) {
                            this.stocks[sIdx] = response.result[sIdx].filter(s => s.amount !== 0);
                        }
                        this.doneLoadingStocks = true;

                        this.getAllCoffees();

                        if (!this.data.transaction && this.stocks?.length) {
                            // remove all stores that have no coffees
                            this.stores = this.stores.filter((_store, sIdx) => this.stocks[sIdx].length > 0);
                            if (!this.store && this.stores?.length) {
                                this.store = this.stores[0];
                            }
                        }
                        this.filterStores();

                    } else {
                        this.utils.handleError('error retrieving all store stocks', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all store stocks', error);
                }
        });
    }

    protected calcAmount(toUnit: boolean): void {
        if (this.coffee?.default_unit?.size) {
            if (toUnit) {
                // amount to unitamount
                this.unitamount = this.amount * this.coffee.default_unit.size;
            } else {
                // unitamount to amount
                this.amount = this.unitamount / this.coffee.default_unit.size;
            }
        }
        this.priceChanged(true);
    }

    protected next(): void {
        this.secondPage = true;
    }

    protected prev(): void {
        this.secondPage = false;
    }

    protected doAdd(that?: SellDialogComponent, changedPrice?: string): void {
        if (!that) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            that = this;
        }
        that.priceChanged(changedPrice === 'pricePerUnit');
        if (!that.submitPressed) {
            return;
        }
        that.submitPressed = false;

        const old_amount = that.data && that.data.transaction && that.data.transaction.amount ? that.data.transaction.amount : 0;
        if (that.utils.isWithinRounding(that.unitamount, old_amount, 3) && !that.hasChanged()) {
            that.alertService.success(that.tr.anslate('Nothing to change'));
            // that.dialogRef.close();
            that.finishDialog(undefined);
            return;
        }

        that.sale = new Sale();
        delete that.sale.reconciled;
        that.sale.amount = that.unitamount;
        that.sale.coffee = that.coffee?._id as unknown as Coffee || that.coffee;
        that.sale.date = that.date;
        if (that.editingCustomer) {
            that.sale.customer = that.editingCustomer;
        } else {
            that.sale.customer = that.customer?._id as unknown as Customer || that.customer;
        }
        that.sale.location = that.store._id as unknown as Location || that.store;
        that.sale.sales_number = that.sales_number;
        that.sale.price = that.price;
        that.sale.tracking_number = that.tracking_number;
        that.sale.product_id = that.product_id;
        that.sale.reference = that.reference;
        that.sale.type = 'Sale';
        that.sale.translated_type = that.tr.anslate('Sale');

        // that.dialogRef.close(that.sale);
        that.finishDialog(that.sale);
    }

    // checks if anything (not including the amount) has changed
    protected hasChanged(): boolean {
        const t = this.data.transaction;
        if (t) {
            if (Math.round(this.price * 100) !== Math.round(t.price * 100)) { return true; }
            if (this.sales_number !== t.sales_number) { return true; }
            if (this.tracking_number !== t.tracking_number) { return true; }
            if (this.product_id !== t.product_id) { return true; }
            if (this.reference !== t.reference) { return true; }
            if (!this.utils.compareObjectsFn(this.customer, t.customer)) { return true; }
            if (!this.utils.compareObjectsFn(this.externalLocation, t.externalLocation)) { return true; }
            if (!this.utils.compareObjectsFn(this.coffee, t.coffee)) { return true; }
            if (!this.utils.compareObjectsFn(this.store, t.location)) { return true; }
            if (this.reconciled !== t.reconciled) { return true; }
            // if (JSON.stringify(this.tags) !== JSON.stringify(t.tags)) return true;
            if (!this.date.hasSame(t.date, 'minute')) { return true; }
        } else {
            // only depends on amount which is checked outside this function
            return true;
        }
        return false;
    }

    protected customerChanged(): void {
        if (this.customer?.toString() === '######') {
            this.addNewCustomer();
        } else {
            this.editingCustomer = undefined;
        }
    }

    protected addNewCustomer(): void {
        this.addNewCustomerMode = true;
        this.editingCustomer = new Customer();
    }

    protected editCustomer(): void {
        this.editNewCustomerMode = true;
    }
    protected closeCustomer(): void {
        const emptyCustomer = new Customer();
        if (this.addNewCustomerMode && JSON.stringify(this.editingCustomer) === JSON.stringify(emptyCustomer)) {
            this.editingCustomer = null;
            if (this.customers?.length > 0) {
                this.customer = this.customers[0];
            } else {
                this.customer = null;
            }
        }

        this.addNewCustomerMode = false;
        this.editNewCustomerMode = false;
    }

    // pick only stores that have this.coffee stored
    // but only if this is not an edit of an existing transaction
    protected filterStores(): void {
        if (!this.coffee || !this.stocks || this.data.transaction) {
            this.filteredStores = this.stores || [];
        } else {
            this.filteredStores = (this.stores || []).filter((_store, index) => {
                const stocks = this.stocks[index];
                for (let s = 0; stocks && s < stocks.length; s++) {
                    const stock = stocks[s];
                    if (stock?.coffee_internal_hr_id === this.coffee.internal_hr_id) {
                        return true;
                    }
                }
                return false;
            });
        }
    }

    protected coffeeChanged(): void {
        this.filterStores();
        if ((!this.store && this.filteredStores.length > 0) || this.filteredStores.length === 1) {
            this.store = this.filteredStores[0];
        } else if (this.store && this.filteredStores.length && this.filteredStores.findIndex(loc => loc.internal_hr_id === this.store.internal_hr_id) < 0) {
            // current store not in filteredStores, pick one that has the selected beans in stock
            this.store = this.filteredStores[0]
        }
        this.calcAmount(false);
    }

    protected storeChanged(): void {
        let storeIdx = -1;
        for (let s = 0; s < this.stores?.length; s++) {
            if (this.stores[s]._id === this.store._id) {
                storeIdx = s;
                break;
            }
        }
        if (storeIdx < 0) {
            this.logger.warn('store selected that is not in the this.stores list!');
            this.filteredCoffees = this.coffees;
            return;
        }

        const stocks = this.stocks[storeIdx];
        if (!this.data.transaction) {
            this.filteredCoffees = (this.coffees || []).filter(c => stocks.map(s => s.coffeeId).indexOf(c._id) >= 0);
            if (!this.coffee && this.filteredCoffees.length > 0 || this.filteredCoffees.length === 1) {
                this.coffee = this.filteredCoffees[0];
            }
        }
    }

    protected getAmountStr(): { pre: string, value: number, post: string } {
        if (this.data.transaction) {
            // editing an existing transaction
            return undefined;
        }

        // new transaction
        if (!this.stocks) {
            return { pre: '', value: 0, post: '' };
        }
        for (let s = 0; s < this.stocks.length; s++) {
            for (let c = 0; c < this.stocks[s].length; c++) {
                if (!(this.stocks[s][c].location_internal_hr_id === this.store?.internal_hr_id)) {
                    // not the correct store
                    break;
                }
                if (this.stocks[s][c].coffee_internal_hr_id === this.coffee.internal_hr_id) {
                    return this.utils.formatAmountForPipe(this.stocks[s][c].amount, undefined, this.currentUser.unit_system);
                }
            }
        }
        // could not find beans in current store => amount is 0
        return { pre: '', value: 0, post: '' };
    }
}
